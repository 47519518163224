import { useCartTotalAmount, useCartValue } from '@ecomm/data-cart'
import { useLocale } from '@ecomm/data-hooks'
import type { CartValue } from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { getPricesBeforeDiscount } from '@simplisafe/eis-commercetools-carts'
import { type Locale, voidFn } from '@simplisafe/ewok'
import { useCallback } from 'react'
import { match } from 'ts-pattern'
import { type BrazeLineItem, brazeTrackCartDetails } from '..'

const useTrackBrazeDetails = () => {
  const cartId = getCartId()
  const locale = useLocale()
  const cart = useCartValue()
  const priceAfterDiscount = useCartTotalAmount()

  return useCallback(() => {
    match(cart)
      .with({ _tag: 'with_items' }, cart => {
        const currencySymbol = locale === 'en-US' ? '$' : '£'
        const cartValue = cart.val
        cartId &&
          brazeTrackCartDetails({
            cartId: cartId,
            cartTotal: `${currencySymbol}${getPricesBeforeDiscount(cartValue.lineItems, locale) / 100}`,
            coupon: cart.val.discountCode || '',
            discountedCartTotal: `${currencySymbol}${cartValue.totalPrice / 100}`,
            products: getBrazeCartProducts(cartValue, locale)
          })
      })
      .otherwise(() => voidFn)
  }, [cartId, cart, locale, priceAfterDiscount])
}

const getBrazeCartProducts = (
  cart: CartValue,
  locale: Locale
): readonly BrazeLineItem[] => {
  const products = cart.lineItems.map(item => {
    const isPackage =
      item.productType === 'package_parent' && item.packageItems.length > 0
    return isPackage
      ? {
          name: item.name[locale] || '',
          sku: item.sku || '',
          quantity: item.quantity,
          packageComponents: item.packageItems.map(child => {
            return {
              name: child.name[locale] || '',
              quantity: child.quantity.toString()
            }
          })
        }
      : {
          name: item.name[locale] || '',
          sku: item.sku || '',
          quantity: item.quantity
        }
  })

  return products
}

export { useTrackBrazeDetails }
