import { useLocale } from '@ecomm/data-hooks'
import { logError } from '@ecomm/error-handling'
import type {
  FlushResult,
  Identify,
  OnProfileChange,
  Page,
  ProfileState,
  Track
} from '@ninetailed/experience.js'
import { useNinetailed as _useNinetailed } from '@ninetailed/experience.js-gatsby'
import { voidFn } from '@simplisafe/ewok'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useCallback, useMemo } from 'react'

type Properties = Parameters<Track>[1]
type EventFunctionOptions = Parameters<Track>[2]
type Traits = Parameters<Identify>[1]
type PageviewProperties = Parameters<Page>[0]

/**
 * This is an abstraction over Ninetailed's `useNinetailed` hook.
 * It behaves the same way, but it won't throw an error if the hook is used without a provider.
 *
 * https://docs.ninetailed.io/for-developers/experience-sdk/react-hooks#useninetailed
 */
export const useNinetailed = (): {
  identify: (
    uid: string,
    traits?: Traits,
    options?: EventFunctionOptions
  ) => E.Either<void, Promise<FlushResult>>
  track: (
    event: string,
    properties?: Properties,
    options?: EventFunctionOptions
  ) => E.Either<void, Promise<FlushResult>>
  page: (
    data?: PageviewProperties,
    options?: EventFunctionOptions
  ) => E.Either<void, Promise<FlushResult>>
  profileState: O.Option<ProfileState>
  onProfileChange: OnProfileChange | null
} => {
  const locale = useLocale()

  // this function is always executed to ensure that the Ninetailed SDK is responsible for deciding when to update the references for identify, track, page, profileState, and onProfileChange.
  const ninetailed = (() => {
    try {
      return _useNinetailed()
    } catch (err) {
      // we want to log an error if the locale is en-US since we expect to have a ninetailed provider
      locale === 'en-US' &&
        logError(err instanceof Error ? err : Error(`${JSON.stringify(err)}`))

      return {
        identify: null,
        track: null,
        page: null,
        profileState: null,
        onProfileChange: null
      }
    }
  })()

  const identifyCallback = useCallback(
    (uid: string, traits?: Traits, options?: EventFunctionOptions) =>
      pipe(
        ninetailed.identify,
        O.fromNullable,
        O.fold(
          () => E.left(voidFn(uid, traits, options)),
          identify => E.right(identify(uid, traits, options))
        )
      ),
    // since this can either be a stable reference (returned by Ninetailed SDK) or a primitive value (null)
    // we can trust that this reference will not change, unless the Ninetailed SDK is reinitialized or for some reason returns a different reference
    // the same applies to ninetailed.track and ninetailed.page callbacks and for profileState and onProfileChange
    [ninetailed.identify]
  )

  const trackCallback = useCallback(
    (event: string, properties?: Properties, options?: EventFunctionOptions) =>
      pipe(
        ninetailed.track,
        O.fromNullable,
        O.fold(
          () => E.left(voidFn(event, properties, options)),
          track => E.right(track(event, properties, options))
        )
      ),
    [ninetailed.track]
  )

  const pageCallback = useCallback(
    (data?: PageviewProperties, options?: EventFunctionOptions) =>
      pipe(
        ninetailed.page,
        O.fromNullable,
        O.fold(
          () => E.left(voidFn(data, options)),
          page => E.right(page(data, options))
        )
      ),
    [ninetailed.page]
  )

  const profileState = useMemo(
    () => O.fromNullable(ninetailed.profileState),
    [ninetailed.profileState]
  )
  const onProfileChange = useMemo(
    () => ninetailed.onProfileChange,
    [ninetailed.onProfileChange]
  )

  return {
    identify: identifyCallback,
    track: trackCallback,
    page: pageCallback,
    profileState,
    onProfileChange
  }
}
