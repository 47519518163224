import {
  partnerPromoAtom,
  partnerPromoLoadingAtom
} from '@ecomm/data-promotions'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/lib/Option'
import { useAtom } from 'jotai'
import { useMemo } from 'react'
import { useCurrentPromo } from '../useCurrentPromo'

export function usePromoBannerState() {
  const [partnerPromo] = useAtom(partnerPromoAtom)
  const [isPartnerBannerLoading] = useAtom(partnerPromoLoadingAtom)
  const hasPartnerBanner = pipe(
    partnerPromo,
    O.fold(
      () => false,
      () => true
    )
  )

  const { isTopBannerVisible } = useCurrentPromo()

  // note: consider implementing similar atom for current promo loading
  const isLoading = isPartnerBannerLoading

  return useMemo(
    () => ({
      hasBanner: !isLoading && isTopBannerVisible,
      isPartnerBanner: !isLoading && hasPartnerBanner,
      isSitewideBanner: !isLoading && !hasPartnerBanner
    }),
    [isLoading, isPartnerBannerLoading, isTopBannerVisible, hasPartnerBanner]
  )
}
