import { type TypeOf, z } from '@simplisafe/ewok'

const activeCodesSchema = z.object({
  dynamic: z.array(z.string()).optional(),
  lastModified: z.string(),
  refurb: z.string(),
  validFrom: z.string(),
  validTo: z.string(),
  withMonitoring: z.string(),
  withoutMonitoring: z.string()
})

const bannerSchema = z.object({
  backgroundColor: z.string(),
  buttonBackgroundColor: z.string(),
  buttonTextColor: z.string(),
  disclaimerText: z.string(),
  discountSecondaryText: z.string(),
  displayEndTimeCalloutOverride: z.boolean(),
  endTimeCalloutOverride: z.string(),
  giftlineItemText: z.string(),
  hasCountdown: z.boolean(),
  hasEmailInput: z.boolean(),
  hasEndDateCallout: z.boolean(),
  hasFreeGiftItem: z.boolean(),
  hasSaleName: z.boolean(),
  primaryTextColor: z.string(),
  saleName: z.string(),
  saleNameLabel: z.string(),
  secondaryTextColor: z.string(),
  experimentId: z.string(),
  // optional partner banner params
  partnerName: z.string().optional(),
  partnerGroup: z.string().optional(),
  partnerPromoCodes: z.array(z.string()).optional(),
  linkPath: z.string().optional(),
  logoDescription: z.string().optional(),
  logoUrl: z.string().optional()
})

const overrideDiscountTextSchema = z.object({
  chooseMonitoringPage: z.string().optional(),
  discountSummary: z.string().optional(),
  endsTextLabel: z.string().optional(),
  isHolidayBanner: z.boolean().optional(),
  listingFlag: z.string().optional(),
  productFlag: z.string().optional(),
  promoBannerDiscountText: z.string().optional(),
  toggleBox: z.string().optional()
})

const imageSchema = z.object({
  title: z.string(),
  description: z.string(),
  file: z.object({
    url: z.string(),
    details: z.object({
      size: z.number(),
      image: z.object({
        width: z.number(),
        height: z.number()
      })
    }),
    fileName: z.string(),
    contentType: z.string()
  })
})

const ninetailedExperienceSchema = z.array(
  z.object({
    metadata: z.object({
      tags: z.array(z.unknown()),
      concepts: z.array(z.unknown())
    }),
    sys: z.object({
      space: z.object({
        sys: z.object({
          type: z.literal('Link'),
          linkType: z.literal('Space'),
          id: z.string()
        })
      }),
      id: z.string(),
      type: z.literal('Entry'),
      createdAt: z.string(),
      updatedAt: z.string(),
      environment: z.object({
        sys: z.object({
          id: z.string(),
          type: z.literal('Link'),
          linkType: z.literal('Environment')
        })
      }),
      revision: z.number(),
      contentType: z.object({
        sys: z.object({
          type: z.literal('Link'),
          linkType: z.literal('ContentType'),
          id: z.string()
        })
      }),
      locale: z.string()
    }),
    fields: z.object({
      nt_name: z.string(),
      nt_description: z.string(),
      nt_type: z.string(),
      nt_config: z.object({
        distribution: z.array(z.number()),
        traffic: z.number(),
        components: z.array(
          z.object({
            baseline: z.object({
              id: z.string()
            }),
            variants: z.array(
              z.object({
                id: z.string(),
                hidden: z.boolean()
              })
            )
          })
        ),
        primaryMetric: z.string(),
        distributionType: z.string()
      }),
      nt_audience: z.object({
        sys: z.object({
          type: z.literal('Link'),
          linkType: z.literal('Entry'),
          id: z.string()
        })
      }),
      nt_variants: z.array(
        z.object({
          metadata: z.object({
            tags: z.array(z.unknown()),
            concepts: z.array(z.unknown())
          }),
          sys: z.object({
            space: z.object({
              sys: z.object({
                type: z.literal('Link'),
                linkType: z.literal('Space'),
                id: z.string()
              })
            }),
            id: z.string(),
            type: z.literal('Entry'),
            createdAt: z.string(),
            updatedAt: z.string(),
            environment: z.object({
              sys: z.object({
                id: z.string(),
                type: z.literal('Link'),
                linkType: z.literal('Environment')
              })
            }),
            revision: z.number(),
            contentType: z.object({
              sys: z.object({
                type: z.literal('Link'),
                linkType: z.literal('ContentType'),
                id: z.string()
              })
            }),
            locale: z.string()
          }),
          fields: z.object({
            entryTitle: z.string(),
            promoCode: z.string(),
            promoCodeWithMonitoring: z.string(),
            promoCodeRefurb: z.string(),
            startTime: z.string(),
            endTime: z.string(),
            useEndTimeCalloutOverride: z.boolean(),
            site: z.string(),
            evergreen: z.boolean(),
            backgroundColor: z.string(),
            primaryTextColor: z.string(),
            secondaryTextColor: z.string(),
            buttonBackgroundColor: z.string(),
            buttonTextColor: z.string(),
            promoFlagBackgroundColor: z.string(),
            promoFlagTextColor: z.string(),
            saleName: z.string(),
            hasCountdown: z.boolean(),
            hasEmailInput: z.boolean(),
            hasFreeGiftItem: z.boolean(),
            hasEndDateCallout: z.boolean(),
            hasSaleName: z.boolean(),
            displayMonitoringDiscount: z.boolean(),
            isVariation: z.boolean()
          })
        })
      ),
      nt_experience_id: z.string(),
      nt_metadata: z.object({
        type: z.string()
      })
    })
  })
)

const contentSchema = z.object({
  entryTitle: z.string(),
  promoCode: z.string(),
  promoCodeWithMonitoring: z.string(),
  promoCodeRefurb: z.string(),
  startTime: z.string(),
  endTime: z.string(),
  endTimeCalloutOverride: z.string(),
  useEndTimeCalloutOverride: z.boolean(),
  site: z.string(),
  evergreen: z.boolean(),
  backgroundColor: z.string(),
  primaryTextColor: z.string(),
  secondaryTextColor: z.string(),
  buttonBackgroundColor: z.string(),
  buttonTextColor: z.string(),
  nt_experiences: ninetailedExperienceSchema.optional(),
  promoFlagBackgroundColor: z.string(),
  promoFlagTextColor: z.string(),
  saleName: z.string(),
  saleNameLabel: z.string(),
  discountSecondaryText: z.string(),
  giftlineItemText: z.string(),
  disclaimerText: z.string(),
  hasCountdown: z.boolean(),
  hasEmailInput: z.boolean(),
  hasFreeGiftItem: z.boolean(),
  hasEndDateCallout: z.boolean(),
  hasSaleName: z.boolean(),
  displayMonitoringDiscount: z.boolean(),
  isVariation: z.boolean(),
  conditionalContentExperimentId: z.string()
})

export const centPrecisionSchema = z.object({
  centAmount: z.number(),
  currencyCode: z.string(),
  fractionDigits: z.number(),
  type: z.literal('centPrecision')
})

export const cartDiscountAbsoluteSchema = z.object({
  key: z.string(),
  value: z.object({
    money: z.array(centPrecisionSchema),
    type: z.literal('absolute')
  })
})

export const cartDiscountRelativeSchema = z.object({
  key: z.string(),
  value: z.object({
    permyriad: z.number(),
    type: z.literal('relative')
  })
})

export const cartDiscountGiftItemSchema = z.object({
  key: z.string(),
  value: z.object({
    product: z.object({
      id: z.string(),
      typeId: z.string()
    }),
    type: z.literal('giftLineItem'),
    variantId: z.number()
  })
})

const cartDiscountSchema = z.union([
  cartDiscountAbsoluteSchema,
  cartDiscountRelativeSchema,
  cartDiscountGiftItemSchema
])

const discountCodeSchema = z.object({
  cartDiscounts: z.array(cartDiscountSchema),
  code: z.string()
})

export const promotionsResponseSchema = z.object({
  activeCodes: activeCodesSchema,
  banner: bannerSchema,
  displayMonitoringDiscount: z.boolean(),
  discountCodes: z.array(discountCodeSchema),
  flag: z.object({
    backgroundColor: z.string(),
    textColor: z.string()
  }),
  id: z.string(),
  mobileBannerImage: imageSchema.optional(),
  heroStickerImage: imageSchema.optional(),
  experimentId: z.string().optional(),
  experienceId: z.string().optional(),
  overrideDiscountText: overrideDiscountTextSchema.optional(),
  variationId: z.string(),
  content: contentSchema
})

export type PromotionsApiResponse = TypeOf<typeof promotionsResponseSchema>
export type CartDiscount = TypeOf<typeof cartDiscountSchema>
export type DiscountCode = TypeOf<typeof discountCodeSchema>
export type CentPrecision = TypeOf<typeof centPrecisionSchema>
