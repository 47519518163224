import {
  type ImmutablePackages,
  type Package,
  initializePackages
} from '@simplisafe/ss-ecomm-data/packages'
import {
  type ImmutableProducts,
  type Product,
  initializeProducts
} from '@simplisafe/ss-ecomm-data/products'

import type { ACTION } from '@simplisafe/ss-ecomm-data/redux/actions'
import type { ImmutableState } from '@simplisafe/ss-ecomm-data/redux/state'
import { Map, isImmutable } from 'immutable'
import once from 'ramda/src/once'
import { type FC, type ReactElement, useRef } from 'react'
import { useDispatch } from 'react-redux'
import type { ThunkDispatch } from 'redux-thunk'

type InjectContextProps = {
  readonly children: ReactElement
  readonly products: ImmutableProducts | { readonly [key: string]: Product }
  readonly packages: ImmutablePackages | { readonly [key: string]: Package }
}

// TODO: this type should be exported out of ss-ecomm-data
type EcommDispatch = ThunkDispatch<ImmutableState, void, ACTION>

/**
 * Data like products and packages need to be added to Redux ASAP (before
 * `useEffect` hooks run) and need to only run once on initial mount.
 * `useLayoutEffect` doesn't play nicely with SSR for things that can affect
 * how a page renders, so this is pulled out into a separate function wrapped
 * in `once` that gets called directly in the component.
 */
const useHydrateReduxOnce = () => {
  const hydrateReduxOnce = useRef(
    once(
      (
        dispatch: EcommDispatch,
        products: InjectContextProps['products'],
        packages: InjectContextProps['packages']
      ) => {
        // Products and packages seemingly get turned into JS objects at build time.
        // This turns them back into an Immutable Map before adding them to redux.
        dispatch(
          initializeProducts(isImmutable(products) ? products : Map(products))
        )
        dispatch(
          initializePackages(isImmutable(packages) ? packages : Map(packages))
        )
      }
    )
  )

  return hydrateReduxOnce.current
}

const InjectContext: FC<InjectContextProps> = ({
  children,
  products,
  packages
}: InjectContextProps) => {
  const dispatch = useDispatch()
  const hydrateReduxOnce = useHydrateReduxOnce()

  hydrateReduxOnce(dispatch, products, packages)

  return <div>{children}</div>
}

export default InjectContext
