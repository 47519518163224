import { jsonSchema } from '@ecomm/micro-copy'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import type { ZodSchema } from 'zod'

export function parseJSONDataFromContentful(
  data: Record<string, unknown>,
  key: string
): unknown {
  return pipe(
    jsonSchema.parse(data[key]),
    parsedData => parsedData.content.internal.content,
    JSON.parse
  )
}

export function safeParseJSONDataFromContentful(
  data: Record<string, unknown>,
  key: string
): O.Option<unknown> {
  return pipe(jsonSchema.safeParse(data[key]), parsedData =>
    parsedData.success
      ? O.of(JSON.parse(parsedData.data.content.internal.content))
      : O.none
  )
}

/**
 * Parses data against CTFL json data type then JSON.parses the internal content, validating
 * against a final schema.
 *
 * @param data Record<string, unknown> CTFL content from JSON data type
 * @param schema ZodSchema             The final Zod schema to validate the JSON against
 *
 * @return SafeParseReturnType
 */
export const safeParseContentfulJsonWithSchema = ({
  data,
  schema
}: {
  readonly data?: Record<string, unknown> | null
  readonly schema: ZodSchema
}) => {
  // Parse against CTFL json content model schema
  const parsedContentfulJsonModel = jsonSchema.safeParse(data)
  const jsonString = parsedContentfulJsonModel.success
    ? parsedContentfulJsonModel.data.content.internal.content
    : ''

  // Try/Catch JSON.parse() then validate against final schema
  return pipe(
    O.tryCatch(() => JSON.parse(jsonString)),
    O.match(() => schema.safeParse(null), schema.safeParse)
  )
}
