import {
  type CartValue,
  cartAddAnnotatedDiscountCode
} from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { voidFn } from '@simplisafe/ewok'
import { selectCurrentPromoVariationId } from '@simplisafe/ss-ecomm-data/redux/select'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useSetCart } from '../atoms/useCart'

/**
 * Adds discount codes to cart.
 * @returns A function that adds discount codes to cart.
 */
export const useCartAddAnnotatedDiscount = () => {
  const cartId = getCartId()
  const setCart = useSetCart()
  const variationId = useSelector(selectCurrentPromoVariationId).orUndefined()

  return useCallback(
    (
      annotations: readonly string[],
      discountCode: string,
      onFailure?: () => void,
      onSuccess?: () => void
    ) => {
      const fn = async (id: string) => {
        const cart = await cartAddAnnotatedDiscountCode(
          id,
          annotations,
          discountCode,
          variationId
        )()
        pipe(
          cart,
          E.fold(
            () => {
              onFailure && onFailure()
            },
            (t: CartValue) => {
              setCart(['update_cart', t])
              onSuccess && onSuccess()
            }
          )
        )
      }
      pipe(
        O.fromNullable(cartId),
        O.fold(() => voidFn(), fn)
      )
    },
    [cartId, setCart, variationId]
  )
}
