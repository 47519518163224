import { fetchApi } from '@ecomm/data-simplisafe-api'
import { logError } from '@ecomm/error-handling'
import {
  expandCustomerGroup,
  expandDiscountCode,
  expandProductType
} from '@simplisafe/ss-ecomm-data/secrets/commercetools'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

import { isCartResponse } from './isCartResponse'

function getResponseStatus(res: unknown): string {
  return res && typeof res === 'object' && 'status' in res
    ? String(res.status)
    : 'unknown'
}

/**
 * Calls /carts/v2/carts/${cartId}:applyDiscountCodes route
 * Applies discountCodes to the cart, supports bulk discount codes
 * @param cartId id of cart to update
 * @param discountCodes discount codes to apply to cart
 * @returns
 */
export function applyDiscountCodesRequest(
  cartId: string,
  discountCodes: ReadonlyArray<string>
) {
  return pipe(
    TE.Do,
    TE.chain(() => {
      return fetchApi({
        endpoint: `/carts/v2/carts/${cartId}:applyDiscountCodes?${expandProductType}&${expandDiscountCode}&${expandCustomerGroup}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ discountCodes })
      })
    }),
    TE.chain(res => (isCartResponse(res) ? TE.right(res) : TE.left(res))),
    TE.mapLeft((res: unknown) =>
      pipe(
        `Error applying discount codes: ${discountCodes.join(', ')} to cart id: ${cartId}. Status code: ${getResponseStatus(res)}`,
        Error,
        e =>
          logError(e, {
            errorCategory: 'DiscountCode',
            priority: 'info',
            codes: discountCodes.join(', ')
          })
      )
    )
  )
}
