import {
  type Options,
  documentToReactComponents
} from '@contentful/rich-text-react-renderer'
import { MARKS } from '@contentful/rich-text-types'
import { usePriceContext } from '@ecomm/data-price'
import { useMicroCopy } from '@ecomm/micro-copy'
import {
  useCurrentPromo,
  useDisplayMonitoringDiscount
} from '@ecomm/promotions-hooks'
import classNames from 'classnames'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import React from 'react'

import type { documentSchema } from '@ecomm/contentful-schemas'
import type { TypeOf } from '@simplisafe/ewok'

export type OfferTagProps = {
  readonly sku: string
  readonly className?: string
  /**
   * only used for partner packages since they require relative discount information
   */
  readonly showPartnerPackageAbsoluteDiscountAsRelative?: boolean
  /**
   * Render an extra rich text after the discount text
   */
  readonly extraText?: TypeOf<typeof documentSchema> | null
}

export function OfferTag({
  sku,
  className = '',
  showPartnerPackageAbsoluteDiscountAsRelative = false, //only used for partner packages since they require relative discount information
  extraText = null
}: OfferTagProps) {
  const {
    getDiscountTextWithServicePlan,
    getDiscountText,
    getRelativeDiscountTextWithServicePlan
  } = usePriceContext()
  const microCopy = useMicroCopy()
  const { promoFlag, overrideDiscountText } = useCurrentPromo()

  const displayMonitoringDiscount = useDisplayMonitoringDiscount()
  const productFlag = overrideDiscountText?.productFlag
  const hasOverrideFlagText = !!productFlag
  /**
   * getRelativeDiscountTextWithServicePlan only used for partner packages since they require relative discount information
   */
  const getDiscountTextAbsoluteOrRelative =
    showPartnerPackageAbsoluteDiscountAsRelative
      ? getRelativeDiscountTextWithServicePlan
      : getDiscountTextWithServicePlan

  const discountText = displayMonitoringDiscount
    ? getDiscountTextAbsoluteOrRelative(sku)
    : getDiscountText(sku)

  const { backgroundColor: tagBackgroundColor, textColor: tagTextColor } =
    promoFlag

  const style = {
    ...(tagBackgroundColor && { backgroundColor: tagBackgroundColor }),
    ...(tagTextColor && { color: tagTextColor })
  }

  const options: Options = {
    renderMark: {
      [MARKS.SUPERSCRIPT]: text => (
        <sup className="text-sm" style={style}>
          {text}
        </sup>
      )
    }
  }

  return pipe(
    discountText,
    O.fold(
      () => null,
      _discountText => (
        <span
          className={classNames(
            'flex min-h-[48px] items-center justify-center bg-white px-4 py-0 text-center md:min-w-[128px]',
            className
          )}
          style={style}
        >
          {hasOverrideFlagText
            ? productFlag
            : `${_discountText} ${microCopy.off}`}
          {extraText ? (
            <div className="prose-p:ml-1 prose-p:mt-3">
              {documentToReactComponents(extraText, options)}
            </div>
          ) : null}
        </span>
      )
    )
  )
}
