import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

const useTrackingBmsTooltip = (productName: string) => {
  const { trackEvent } = useTracking<TrackingData>()
  return useCallback(() => {
    trackEvent({
      action: 'tooltip',
      category: 'bms',
      event: 'clickTooltip',
      label: productName,
      pageTitle: document.title,
      site: 'fcp'
    })
  }, [trackEvent, productName])
}

export default useTrackingBmsTooltip
