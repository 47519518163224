import { useLocale } from '@ecomm/data-hooks'
import { logError } from '@ecomm/error-handling'
import { useProfile as _useProfile } from '@ninetailed/experience.js-react'

/**
 * Ninetailed util hook used to make profile calls.
 * This hook is designed to mask UK calls instead of errors
 */
export function useProfile(): ReturnType<typeof _useProfile> {
  const locale = useLocale()

  try {
    return _useProfile()
  } catch (_err) {
    // we want to log an error if the locale is en-US since we expect to have a ninetailed provider
    const err = _err instanceof Error ? _err : Error(`${JSON.stringify(_err)}`)
    locale === 'en-US' && logError(err)

    return {
      loading: false,
      from: 'api',
      status: 'error',
      profile: null,
      error: err
    }
  }
}
