import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export const useTrackingOrderSubmissionFailed = () => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (errorMessage: string) => {
      trackEvent({
        event: 'submitOrderFailed',
        label: errorMessage
      })
    },
    [trackEvent]
  )
}
