import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../../types/tracking'
import type { BmsTrackingEvent } from '../type'

export const useTrackingBmsCollapseSection = (currentSection: string) => {
  const { trackEvent } = useTracking<TrackingData<BmsTrackingEvent>>()

  return useCallback(() => {
    trackEvent({
      event: 'bms-collapse-section',
      label: 'bms_collapse_section',
      section: currentSection.replace(/ /g, '-').toLowerCase()
    })
  }, [trackEvent, currentSection])
}
