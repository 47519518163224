import { contentfulImageSchema } from '@ecomm/contentful/components'
import { useContentful } from '@ecomm/shared-apollo'
import { type TypeOf, z } from '@simplisafe/ewok'
import { useMemo } from 'react'

import { EXIT_INTENT_QUERY } from './exitIntentQuery'

const idSchema = z.object({
  id: z.string()
})

const exitIntentSchema = z.object({
  images: z.object({
    items: contentfulImageSchema
      .extend({ contentful_id: idSchema.transform(sysObj => sysObj.id) })
      .array()
  })
})

export const useExitIntentQuery = () => {
  const { data: apolloData } = useContentful(EXIT_INTENT_QUERY)
  const parsedData = useMemo(
    () => exitIntentSchema.parse(apolloData),
    [apolloData]
  )
  return parsedData
}

export type ExitIntentSchema = TypeOf<typeof exitIntentSchema>
