import { match } from 'ts-pattern'

import { useShippingMethodStateValue } from '../atoms/useShippingMethodState'

export const useShippingMethods = () => {
  const shippingMethodState = useShippingMethodStateValue()

  return match(shippingMethodState)
    .with({ _tag: 'with_items' }, state => state.val)
    .with({ _tag: 'updating' }, state => state.val)
    .with({ _tag: 'error' }, _ => [])
    .exhaustive()
}
