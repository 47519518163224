import { useField } from 'formik'
import { useCallback } from 'react'

export type FormCheckboxProps = {
  readonly label: string
  readonly name: string
  readonly className?: string
  readonly onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  readonly checked: boolean
}

export function FormCheckbox({
  label,
  name,
  className = '',
  onChange,
  checked
}: FormCheckboxProps) {
  const [field] = useField({
    name,
    type: 'checkbox'
  })

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      field.onChange(event)
      onChange && onChange(event)
    },
    [field, onChange]
  )

  return (
    <div className="flex items-center" data-testid="form-checkbox">
      <input
        className="h-4 w-4"
        id={name}
        type="checkbox"
        {...field}
        checked={checked || field.value}
        onChange={handleChange}
      />
      <label className={`ml-1.5 font-bold text-lg ${className}`} htmlFor={name}>
        {label}
      </label>
    </div>
  )
}
