import { Helmet } from 'react-helmet'
import { helmetJsonLdProp } from 'react-schemaorg'
import type { Product } from 'schema-dts'

import { getCurrencyFromLocale } from '../locale/currencyCode'
import type { Locale } from '../locale/types'
import { removeLeadingSlash } from '../removeSlash'
import type { ProductSchema, SEOProps } from './types'

export type SEOProductSnippetProps = Pick<
  SEOProps,
  'metaDescription' | 'metaTitle'
> & {
  readonly locale: Locale
  readonly product: ProductSchema
}

/**
 * Formats Schema.org Specification for a Product Offering: https://schema.org/Thing
 * to be used in tandem with React Helmet.
 */
export function SEOProductSnippet(props: SEOProductSnippetProps) {
  const { product, locale, metaTitle, metaDescription } = props

  const currency = getCurrencyFromLocale(locale)
  const tld = locale === 'en-US' ? '.com' : '.co.uk'

  const productSchemaOrg = helmetJsonLdProp<Product>({
    brand: {
      name: 'SimpliSafe',
      '@type': 'Brand'
    },
    '@context': 'https://schema.org',
    '@type': 'Product',
    offers: {
      availability: 'https://schema.org/InStock',
      itemCondition: 'https://schema.org/NewCondition',
      priceCurrency: currency,
      '@type': 'Offer',
      url: `https://simplisafe${tld}/${removeLeadingSlash(product.slug)}`,
      price: product.price
    },
    description: metaDescription,
    // TODO: Move this to CTFL v2's SEO content type
    image:
      'https://images.ctfassets.net/v6awd0kabi65/53Kx63MinKfftuUFhqcQca/1fb525c160fc3d845e25f7c43592c37e/pla.png',
    name: metaTitle,
    sku: product.sku
  })

  return <Helmet script={[productSchemaOrg]} />
}
