import { useCartLineItems } from '@ecomm/data-cart'
import { useLocale } from '@ecomm/data-hooks'
import type { CartValue } from '@ecomm/data-simplisafe-api'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'
import { getCurrencyCode, simplifyLineItems } from './lineItems'
import { populateBrand, useCTProducts } from './populateBrand'
import type { TrackingProduct } from './types'

export function useTrackingEECRemove() {
  const { trackEvent } = useTracking<TrackingData>()

  const locale = useLocale()
  const lineItems = useCartLineItems()
  const ctProducts = useCTProducts(lineItems)

  return useCallback(
    (product: TrackingProduct) => (c: CartValue) => {
      const lineItems = populateBrand(c.lineItems, ctProducts, locale)
      const ecommerce = {
        remove: {
          products: [product]
        },
        checkout: {
          products: simplifyLineItems(lineItems, locale)
        },
        currencyCode: getCurrencyCode(lineItems, locale)
      }

      trackEvent({
        ecommerce,
        event: 'eec.remove',
        eventAction: 'checkout',
        eventCategory: 'eec',
        eventLabel: 'cart'
      })
    },
    [ctProducts, locale, trackEvent]
  )
}
