/**
 * Calculates the payment for a loan based on constant payments and a constant interest rate.
 * This function replicates Excel's PMT formula.
 *
 * @param rate - Interest rate per period (e.g., annual rate / 12 for monthly payments).
 * @param nper - Total number of payment periods (e.g., loan term in months).
 * @param pv - Present value, or the principal amount of the loan.
 * @param fv - Future value, or the remaining balance after the last payment (default is 0).
 * @param type - When payments are due: 0 = end of period (default), 1 = beginning of period.
 * @returns The fixed periodic payment amount.
 *
 * The formula used is:
 * PMT = (rate * (pv * (1 + rate) ^ nper + fv)) / ((1 + rate) ^ nper - 1)
 * If rate is 0, the payment is simply (pv + fv) / nper.
 * Note that the only difference is that this one doesn't return a negative value
 */

export function calculatePMT(
  rate: number,
  nper: number,
  pv: number,
  fv = 0,
  type = 0
): number {
  if (rate === 0) {
    return (pv + fv) / nper
  } else {
    const pvFactor = Math.pow(1 + rate, nper)
    const basePMT = (rate * (pv * pvFactor + fv)) / (pvFactor - 1)
    const adjustedPMT = type === 1 ? basePMT / (1 + rate) : basePMT

    return adjustedPMT
  }
}
