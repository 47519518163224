import {
  DEFAULT_PARTNER_OFFER_TITLE,
  defaultPartnerPromoBanner,
  partnerPromoAtom,
  partnerPromoLoadingAtom
} from '@ecomm/data-promotions'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/lib/Option'
import { useAtom } from 'jotai'
import { useMemo } from 'react'
import { useCurrentPromo } from '../useCurrentPromo'
import type { PartnerPromoAtom } from './types'

export function usePartnerPromo(): PartnerPromoAtom {
  const [partnerBanner] = useAtom(partnerPromoAtom)
  const [isPartnerBannerLoading] = useAtom(partnerPromoLoadingAtom)
  const { monthsOfServiceDisplay, giftLineItemText, offerTitle } =
    useCurrentPromo()

  return useMemo(() => {
    return pipe(
      partnerBanner,
      O.fold(
        () => ({
          ...defaultPartnerPromoBanner,
          monthsOfService: `${monthsOfServiceDisplay} of monitoring`,
          lineItemText: giftLineItemText,
          isLoading: isPartnerBannerLoading,
          discountText: offerTitle || DEFAULT_PARTNER_OFFER_TITLE
        }),
        partnerBanner => ({
          ...partnerBanner,
          monthsOfService: `${monthsOfServiceDisplay} of monitoring`,
          lineItemText: giftLineItemText,
          isLoading: isPartnerBannerLoading,
          discountText: offerTitle || DEFAULT_PARTNER_OFFER_TITLE,
          hasPartnerBanner: true
        })
      )
    )
  }, [
    partnerBanner,
    monthsOfServiceDisplay,
    giftLineItemText,
    offerTitle,
    isPartnerBannerLoading
  ])
}
