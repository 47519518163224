import type { PromotionsApiResponse } from '@ecomm/data-simplisafe-api'
import { addAtomDebugLabel } from '@ecomm/utils'
import { atomWithReset } from 'jotai/utils'

const activeCodes = {
  dynamic: [],
  lastModified: '',
  refurb: '',
  validFrom: '',
  validTo: '',
  withMonitoring: '',
  withoutMonitoring: ''
}

const banner = {
  backgroundColor: '',
  buttonBackgroundColor: '',
  buttonTextColor: '',
  disclaimerText: '',
  discountSecondaryText: '',
  displayEndTimeCalloutOverride: false,
  endTimeCalloutOverride: '',
  giftlineItemText: '',
  hasCountdown: false,
  hasEmailInput: false,
  hasEndDateCallout: false,
  hasFreeGiftItem: false,
  hasSaleName: false,
  primaryTextColor: '',
  saleName: '',
  saleNameLabel: '',
  secondaryTextColor: '',
  experimentId: ''
}

const image = {
  title: '',
  description: '',
  file: {
    url: '',
    details: {
      size: 0,
      image: {
        width: 0,
        height: 0
      }
    },
    fileName: '',
    contentType: ''
  }
}

const overrideDiscountText = {
  chooseMonitoringPage: '',
  discountSummary: '',
  endsTextLabel: '',
  isHolidayBanner: false,
  listingFlag: '',
  productFlag: '',
  promoBannerDiscountText: '',
  toggleBox: ''
}

const content = {
  entryTitle: '',
  promoCode: '',
  promoCodeWithMonitoring: '',
  promoCodeRefurb: '',
  startTime: '',
  endTime: '',
  endTimeCalloutOverride: '',
  useEndTimeCalloutOverride: false,
  site: '',
  evergreen: false,
  backgroundColor: '',
  primaryTextColor: '',
  secondaryTextColor: '',
  buttonBackgroundColor: '',
  buttonTextColor: '',
  promoFlagBackgroundColor: '',
  promoFlagTextColor: '',
  saleName: '',
  saleNameLabel: '',
  discountSecondaryText: '',
  giftlineItemText: '',
  disclaimerText: '',
  hasCountdown: false,
  hasEmailInput: false,
  hasFreeGiftItem: false,
  hasEndDateCallout: false,
  hasSaleName: false,
  displayMonitoringDiscount: false,
  isVariation: false,
  conditionalContentExperimentId: ''
}

export const defaultPromotionsResponse = {
  activeCodes,
  banner,
  displayMonitoringDiscount: false,
  discountCodes: [],
  flag: {
    backgroundColor: '',
    textColor: ''
  },
  id: '',
  mobileBannerImage: image,
  heroStickerImage: image,
  overrideDiscountText,
  variationId: '',
  content
}

export const currentPromoAtom = atomWithReset<PromotionsApiResponse>({
  ...defaultPromotionsResponse
})
addAtomDebugLabel(currentPromoAtom, 'Promotions: Current Promo')
