import { useCurrentPromo } from '../useCurrentPromo'

/**
 * Returns false if current sitewide promo is Evergreen. Otherwise returns true
 * @example
 * const isActivePromotion = useIsActivePromotion() // => boolean
 */

export const useIsActivePromotion = () => {
  const { evergreen: isEvergreenPromotion } = useCurrentPromo()
  return !isEvergreenPromotion
}
