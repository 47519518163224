import { validatePhoneNumber } from '@ecomm/checkout/shipping-schema'
import { z } from 'zod'

export const PHONE_MIN_LENGTH = 10

export const PhoneNumberFormSchema = z.object({
  phone: z
    .string({
      required_error: `Please enter a ${PHONE_MIN_LENGTH}-digit phone number.`
    })
    .min(PHONE_MIN_LENGTH, {
      message: `Please enter a ${PHONE_MIN_LENGTH}-digit phone number.`
    })
    .trim()
    .refine(
      val => validatePhoneNumber(val, 'US'),
      val => ({ message: `${val} is not a valid US phone number.` })
    )
})
