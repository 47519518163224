import {
  useCartCTState,
  useCartDiscountCode,
  useCartError,
  useCartLineItems,
  useCartShippingAddress,
  useCartTotalAmount
} from '@ecomm/data-cart'
import { logError } from '@ecomm/error-handling'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

export function useTrackingPaymentConfirmation(orderId: string) {
  const error = useCartError()
  const discountCode = useCartDiscountCode()
  const totalPrice = useCartTotalAmount()
  const lineItems = useCartLineItems()
  const shippingAddress = useCartShippingAddress()
  const cartCTState = useCartCTState()

  const { trackEvent } = useTracking()

  const track = useCallback(() => {
    const cartHasSystem = lineItems.some(l => l.packageItems.length > 0)

    error && logError(error)
    orderId === '' &&
      logError(Error('Missing orderId on payment confirmation page'))

    cartHasSystem &&
      trackEvent({
        event: 'purchaseSystemConfirmation'
      })

    trackEvent({
      event: 'paymentConfirmation',
      transactionCoupon: discountCode,
      transactionId: orderId,
      transactionTotal: totalPrice,
      userData: {
        email: shippingAddress?.email,
        firstName: shippingAddress?.firstName,
        lastName: shippingAddress?.lastName
      }
    })
  }, [
    discountCode,
    error,
    lineItems,
    orderId,
    shippingAddress?.email,
    shippingAddress?.firstName,
    shippingAddress?.lastName,
    totalPrice,
    trackEvent
  ])

  cartCTState === 'Ordered' && track()
}
