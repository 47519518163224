import { useLocale } from '@ecomm/data-hooks'
import { type LeadGenResponseProps, leadGenCapture } from '@ecomm/data-leads'
import { logError } from '@ecomm/error-handling'
import { useOptimizelyTrack } from '@ecomm/optimizely-hooks'
import {
  COOKIE_LEAD_DATA,
  getLeadData,
  getValueFromLeadData,
  updateLeadData,
  useCookieChange
} from '@ecomm/shared-cookies'
import { useIdentify } from '@ecomm/shared-ninetailed'
import {
  ReferrerUrlContext,
  brazeTrackPromoView,
  handleBrazeTrackingEvent,
  setBrazeAttributeGoogleClientId,
  trackLeadCapture,
  useGoogleAnalytics,
  useLeadGenCaptureV2
} from '@ecomm/tracking'
import type { LeadGenCaptureResponse } from '@simplisafe/ss-ecomm-data/simplisafe'
import * as E from 'fp-ts/lib/Either'
import { useContext, useState } from 'react'
import { useTracking } from 'react-tracking'
import { useBannerInteractionState } from '../useBannerInteractionState'
import { useGetActivePromoCode } from '../useGetActivePromoCode'
import { useGetPromoLeadSource } from '../useGetPromoLeadSource'
import { useLeadCaptureEmailSubmitted } from '../useLeadCaptureEmailSubmitted'

export type LeadCaptureFormPropsOnSubmit = (
  _email: string,
  _onFailure: (_errorText: string) => void
) => void

export type LeadCaptureFormPropsOnPhoneNumberSubmit = (_phone: string) => void

export const useHandleFormSubmission = () => {
  const trackLeadCaptureFailure = useOptimizelyTrack({
    eventKey: 'website_error'
  })
  const { trackEvent } = useTracking({ appSection: 'promoSubmit' })
  const [clientId] = useGoogleAnalytics()
  const locale = useLocale()

  const [defaultEmail, setDefaultEmail] = useState<string>(
    getLeadData()?.['email'] || ''
  )

  const [defaultPhoneNumber, setDefaultPhoneNumber] = useState<string>(
    getLeadData()?.['phone'] || ''
  )

  useCookieChange(COOKIE_LEAD_DATA, data => {
    // @ts-expect-error - TODO: ECP-12322 this type is unknown and the data needs to be parsed
    const email = JSON.parse(data)?.email || ''
    setDefaultEmail(email)

    // @ts-expect-error - TODO: ECP-12322 this type is unknown and the data needs to be parsed
    const phone = JSON.parse(data)?.phone || ''
    setDefaultPhoneNumber(phone)
  })

  const channel = useContext(ReferrerUrlContext).channel

  const promoCode = useGetActivePromoCode()
  const source = useGetPromoLeadSource()

  const leadGenCaptureV2 = useLeadGenCaptureV2()

  const { onCloseBanner } = useBannerInteractionState()
  const { isSubmitted, setIsSubmitted } = useLeadCaptureEmailSubmitted()
  const identify = useIdentify()

  const handleEmailSubmit: LeadCaptureFormPropsOnSubmit = (
    email,
    onFailure
  ) => {
    const handleLeadCaptureFailure = () => {
      trackLeadCaptureFailure()
      onFailure("We've encountered an error. Please try again later.")
    }

    const handleLeadCaptureSuccess = (value: LeadGenResponseProps) => {
      setIsSubmitted(true)
      onCloseBanner()
      brazeTrackPromoView()
      trackLeadCapture(value, trackEvent, channel)
        .finally(() => {
          leadGenCaptureV2({
            source,
            siteMetadata: { sourceType: 'promo', promoOffer: promoCode },
            email
          })
        })
        .catch(logError)
      identify(value)

      // set google client id for UK as custom braze attribute ECP-12023
      locale === 'en-GB' &&
        clientId &&
        setBrazeAttributeGoogleClientId(clientId)

      console.info(
        `In promotions useHandleFormSubmission called trackLeadCapture with ${JSON.stringify(
          { leadId: value.leadId, externalId: value.externalId }
        )}`
      )
    }

    leadGenCapture({
      email,
      promoCode,
      source
    })()
      .then(E.match(handleLeadCaptureFailure, handleLeadCaptureSuccess))
      .catch(handleLeadCaptureFailure)
  }

  // For Phone Number Capture Experiment (ECP-10762)
  const handlePhoneNumberSubmit: LeadCaptureFormPropsOnPhoneNumberSubmit =
    async phone => {
      const leadDataCapture: LeadGenCaptureResponse = {
        externalId: getValueFromLeadData('externalId') || '',
        leadSource: source
      }
      await updateLeadData({ phone: phone })
      await handleBrazeTrackingEvent(leadDataCapture, channel)
      brazeTrackPromoView()
      setIsSubmitted(true)
      onCloseBanner()
    }

  return {
    defaultEmail,
    defaultPhoneNumber,
    isFormSubmitted: isSubmitted,
    handleEmailSubmit,
    handlePhoneNumberSubmit
  }
}
