import { InitializeStore, PXRudderstackObserver } from '@ecomm/gatsby-wrappers'
import { NinetailedPromotionWrapper } from '@ecomm/promotions-components/NinetailedPromotionWrapper'
import { BrazeExperimentDataWrapper } from '@ecomm/shared-ninetailed'
import {
  ReferrerUrlContext,
  useReferrerUrl,
  useSetAwinCookie
} from '@ecomm/tracking'
import { EnvProvider, type Locale } from '@ecomm/utils'
import { useLocation } from '@reach/router'
import { type ReactElement, StrictMode, Suspense } from 'react'

import { ModalProvider } from '@ecomm/modal/lib/ModalContext/ModalProvider'
import {
  SkipToA11yStatementLink,
  SkipToContentLink
} from '@ecomm/shared-components'
import { Prefetch } from './Prefetch'

export type PageContext = {
  readonly locale: Locale
  readonly enableJotai?: boolean
}

type Props = {
  readonly element: ReactElement
  readonly props: {
    readonly pageContext: PageContext
  }
}
function WrapWithContext<P extends Props>({ element, props: p }: P) {
  const { locale, enableJotai } = p.pageContext
  const location = useLocation()
  const referrerUrl = useReferrerUrl(location)

  useSetAwinCookie(locale)

  return (
    // StrictMode is inserted here instead of WrapRootElement because it needs
    // to be below @reach/router's BrowserRouter to avoid hydration issues
    // since Gatsby's using an outdated version of @reach/router.
    // https://stackoverflow.com/questions/71832720/link-tag-inside-browserrouter-changes-only-the-url-but-doesnt-render-the-compo/71833424#71833424
    <StrictMode>
      <InitializeStore enableJotai={!!enableJotai}>
        <ReferrerUrlContext.Provider
          value={{ ...referrerUrl, referrerUrl: referrerUrl.referrerUrl || '' }}
        >
          <EnvProvider config={{ locale }}>
            <ModalProvider>
              <BrazeExperimentDataWrapper>
                <PXRudderstackObserver />
                <NinetailedPromotionWrapper locale={locale} />
                <Prefetch />
                <SkipToContentLink />
                <SkipToA11yStatementLink />
                {/* Suspense is added here as a fallback. */}
                <Suspense>{element}</Suspense>
              </BrazeExperimentDataWrapper>
            </ModalProvider>
          </EnvProvider>
        </ReferrerUrlContext.Provider>
      </InitializeStore>
    </StrictMode>
  )
}

// Wrap the wrapper in another wrapper to allow the use of React hooks
// https://github.com/gatsbyjs/gatsby/issues/22833#issuecomment-609370401
export function WrapPageElement<P extends Props>(props: P) {
  return <WrapWithContext {...props} />
}
