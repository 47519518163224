import { logError } from '@ecomm/error-handling'
import { polyfillHasOwn } from '@ecomm/shared-polyfill'
import type { Either } from 'fp-ts/lib/Either'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'
import { fetchApi } from './lib/fetchApi'
import type { ErrorResponse } from './lib/types'
import type { PromotionsApiResponse } from './promotionsSchema'

const isValidPromoResponse = (res: unknown): res is PromotionsApiResponse => {
  polyfillHasOwn()
  return !!res && typeof res === 'object' && Object.hasOwn(res, 'activeCodes')
}

type PartnerParams = {
  readonly customerGroup: string
  readonly partnerName: string
}

export const fetchCurrentPromotion = (
  locale: 'en-GB' | 'en-US',
  partnerParams: PartnerParams | null,
  experiences?: readonly Record<string, unknown>[]
): Promise<Either<Error | ErrorResponse, PromotionsApiResponse>> => {
  const partnerQuery =
    partnerParams &&
    !!partnerParams.customerGroup &&
    !!partnerParams.partnerName
      ? `?customerGroup=${partnerParams.customerGroup}&partnerName=${partnerParams.partnerName}`
      : ''
  const overrideUrl = process.env['PROMOTIONS_API_OVERRIDE_URL'] ?? ''
  const promotionsPath = !overrideUrl ? '/promotions' : ''
  return pipe(
    fetchApi({
      baseUrlOverride: overrideUrl,
      endpoint: `${promotionsPath}/v2/promotions/current${partnerQuery}`,
      method: 'GET',
      headers: {
        'x-9t-experiences': !!experiences ? JSON.stringify(experiences) : ''
      }
    }),
    TE.chain(res =>
      isValidPromoResponse(res)
        ? TE.right(res)
        : TE.left(
            logError(
              Error(
                `Error fetching current promotion ${partnerParams ? `for ${partnerParams.partnerName}` : ''}`
              ),
              { error: JSON.stringify(res) }
            )
          )
    )
  )()
}
