import type { LocaleCodeType } from '@simplisafe/eis-commercetools-shared'

import type { CartValue } from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { getCurrencyFromLocale, getLocale } from '@ecomm/utils'
import type {
  ComponentLineItem,
  LineItem
} from '@simplisafe/eis-commercetools-carts'
import type { EcommEventWithCartId, ProductPayload } from './types'

type QuantityType = 'multiple' | 'single'

// @TODO: Replace with import from eis-commercetools!
export const getDiscountedPerUnitPrice = (
  lineItem: LineItem,
  quantity: number,
  locale: LocaleCodeType
) => {
  if (!quantity) {
    return 0
  }

  return (
    (lineItem.pricesAfterDiscount[locale]?.centAmount || 0) / (100 * quantity)
  )
}

/**
 * Converts an individual LineItem (can be a package) into a data structure that we can send to
 * Rudderstack.
 * The quantityType prop is used to distinguish single increment/decrement from bulk line item
 * deletion. It will allow us to track the proper quantity and price total.
 */
export const lineItemToRudderstackData = (
  lineItem: LineItem,
  cart: CartValue,
  quantityType: QuantityType = 'single'
): EcommEventWithCartId => {
  const locale = getLocale()
  const currency = getCurrencyFromLocale(locale)
  const quantityDivisor = quantityType === 'single' ? lineItem.quantity || 1 : 1
  const total = getDiscountedPerUnitPrice(lineItem, quantityDivisor, locale)
  const products = lineItemToRudderstackProducts(lineItem, quantityType)
  const eventData = {
    cartId: getCartId() || '',
    coupon: cart.discountCode || '',
    currency,
    products,
    total
  }

  return eventData
}

/**
 * Turns a list of Package LineItem components into a Rudderstack data structure
 * The quantityType prop is used to distinguish single increment/decrement from bulk line item
 * deletion. It will allow us to track the proper quantity and price total.
 */
const packageItemsToRudderstackData = (
  packageItems: readonly ComponentLineItem[],
  packageQuantity: number,
  quantityType: QuantityType = 'single'
): readonly ProductPayload[] => {
  const locale = getLocale()
  const quantityDivisor = quantityType === 'single' ? packageQuantity || 1 : 1

  const items = packageItems.map(packageItem => ({
    category: packageItem.productType,
    item_list_id: '',
    name: packageItem.name[locale],
    price: 0,
    product_id: packageItem.sku,
    quantity: packageItem.quantity / quantityDivisor
  }))
  return items
}

/**
 * Converts an individual LineItem (can be a package) into a data structure to be used in the
 * products array of the Rudderstack data object
 * The quantityType prop is used to distinguish single increment/decrement from bulk line item
 * deletion. It will allow us to track the proper quantity and price total.
 */
const lineItemToRudderstackProducts = (
  lineItem: LineItem,
  quantityType: QuantityType = 'single'
): readonly ProductPayload[] => {
  const locale = getLocale()
  const quantityDivisor = quantityType === 'single' ? lineItem.quantity || 1 : 1
  const quantity = quantityType === 'single' ? 1 : lineItem.quantity
  const price = getDiscountedPerUnitPrice(lineItem, quantityDivisor, locale)

  if (lineItem.productType === 'package_parent') {
    return [
      {
        category: lineItem.productType,
        item_list_id: lineItem.id,
        name: lineItem.name[locale],
        price,
        product_id: lineItem.sku || '',
        quantity
      },
      ...packageItemsToRudderstackData(
        lineItem.packageItems,
        lineItem.quantity,
        quantityType
      )
    ]
  } else {
    return [
      {
        category: lineItem.productType,
        item_list_id: '',
        name: lineItem.name[locale],
        price,
        product_id: lineItem.sku || '',
        quantity
      }
    ]
  }
}
