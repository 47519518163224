import { trackLeadSubmit } from '@ecomm/cdp-tracking'
import type { LeadGenResponseProps } from '@ecomm/data-leads'
import { updateLeadData } from '@ecomm/shared-cookies'

import type { TrackEvent } from '../analytics'
import {
  brazeSetCustomUserAttributes,
  handleBrazeTrackingEvent,
  updateBrazeLeadCustomData
} from '../braze'
import { fbTrackLeadCreated } from '../facebook'
import {
  nonBounceSessionWasNotTracked,
  trackNonBounceVisit
} from '../trackNonBounceVisit'

/**
 *
 * Calls lead capture tracking events (braze, optimizely, GTM, facebook CAPI)
 * and sets a cookie with leads meta data ('leadData')
 */

export async function trackLeadCapture(
  leadGenResponse: LeadGenResponseProps,
  trackEvent: TrackEvent,
  channel = '',
  clientId?: string
) {
  const firstSubmission = leadGenResponse.message?.includes('created')
  updateLeadData(leadGenResponse)
  nonBounceSessionWasNotTracked() === true && trackNonBounceVisit(trackEvent)
  await handleBrazeTrackingEvent(leadGenResponse, channel, firstSubmission)
  updateBrazeLeadCustomData()
  clientId && brazeSetCustomUserAttributes({ 'Google Client ID': clientId }) //UK SegmentStream needs this data
  trackEvent({ event: 'submit' })
  trackEvent({ event: 'submitLead' })
  trackLeadSubmit(leadGenResponse.leadSource || '')
  leadGenResponse.email && (await fbTrackLeadCreated(leadGenResponse.email))
  console.info(
    `In function trackLeadCapture called with ${JSON.stringify({
      leadId: leadGenResponse.leadId,
      externalId: leadGenResponse.externalId
    })}`
  )
  console.info('In function trackLeadCapture called handleBrazeTrackingEvent')
  console.info('In function trackLeadCapture called updateBrazeLeadCustomData')
}
