import { z } from '@simplisafe/ewok'
import type { Locale } from '@simplisafe/ss-ecomm-data/commercetools/locale'
import { window } from 'browser-monads-ts'

/**
 * Calculates sha256 hash using web crypto
 * @source https://jameshfisher.com/2017/10/30/web-cryptography-api-hello-world/
 * @param message
 * @returns
 */
export async function sha256Web(message: string): Promise<string> {
  const buffer = await window.crypto.subtle.digest(
    'SHA-256',
    new TextEncoder().encode(message)
  )
  return Array.prototype.map
    .call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2))
    .join('')
}

export const CLIENT_IP_SESSION_STORAGE_KEY = '_clientIP'

/**
 * Fetches Client IP address, caches value in session storage
 * @returns
 */
export async function getClientIPAddress() {
  const cachedClientIpAddress = window.sessionStorage.getItem(
    CLIENT_IP_SESSION_STORAGE_KEY
  )
  if (cachedClientIpAddress === null) {
    const response = await window.fetch('https://api.ipify.org/?format=json', {
      credentials: 'omit',
      method: 'GET',
      mode: 'cors'
    })

    const parsed = z.object({ ip: z.string() }).safeParse(await response.json())
    const clientIp = parsed.success ? parsed.data.ip : ''

    window.sessionStorage.setItem(CLIENT_IP_SESSION_STORAGE_KEY, clientIp)
    return clientIp
  } else {
    return cachedClientIpAddress
  }
}

/**
 * Normalizes phone number for Facebook Conversions API
 * @see https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters#ph
 * @param phoneNumber
 * @param locale
 * @returns
 */
export function normalizePhone(phoneNumber: string, locale: Locale): string {
  const digits = phoneNumber.trim().replaceAll(/\D/g, '')
  const removeLeadingZeros = digits.replaceAll(/^0+/g, '')
  if (locale === 'en-US') {
    return !removeLeadingZeros.startsWith('1') ? '1' + digits : digits
  } else {
    return !removeLeadingZeros.startsWith('44') ? '44' + digits : digits
  }
}
