import { exists, window } from 'browser-monads-ts'

import { useMemo } from 'react'
import { useCurrentPromo } from '../useCurrentPromo'

export const useShouldShowPromoBanners = () => {
  const { discountText, isTopBannerVisible } = useCurrentPromo()
  // Checking if the window exists makes sure this isn't rendered during gatsby build, but only when the page
  // hydrates when the end user hits the site
  const isBrowser = exists(window)

  return useMemo(() => {
    // todo: remove the null check and replace with a flag that shows if the discount amount request has completed
    // this is part of the required implementation for moving promos out of redux
    return (
      isTopBannerVisible &&
      discountText &&
      !discountText.includes('null') &&
      isBrowser
    )
  }, [discountText, isTopBannerVisible])
}
