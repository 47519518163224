import type { Locale } from '@ecomm/utils'
import type { LineItem } from '@simplisafe/eis-commercetools-carts'

import type { BrazeChildComponents, BrazeLineItem } from '../../braze'

export const lineItemToBrazeLineItem =
  (locale: Locale) =>
  (lineItem: LineItem): BrazeLineItem => {
    const components: BrazeChildComponents = lineItem.packageItems.map(i => ({
      name: i.name[locale] || '',
      quantity: i.quantity.toString()
    }))

    return {
      name: lineItem.name[locale] || '',
      quantity: lineItem.quantity,
      sku: lineItem.sku || '',
      packageComponents: components.length > 0 ? components : undefined
    }
  }
