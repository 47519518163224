import { type TypeOf, z } from 'zod'

export const partnerBannerSchema = z.object({
  partnerName: z.string(),
  partnerGroup: z.string(),
  partnerPromoCodes: z.array(z.string()).readonly(),
  primaryOfferText: z.string(),
  secondaryOfferText: z.string(),
  secondaryOfferTextMobile: z.string(),
  discountSecondaryText: z.string(),
  displayEmailInputField: z.boolean(),
  linkPath: z.string(),
  backgroundColor: z.string(),
  primaryTextColor: z.string(),
  secondaryTextColor: z.string(),
  logoDescription: z.string(),
  logoUrl: z.string(),
  hasPartnerBanner: z.boolean()
})

export type PartnerBanner = TypeOf<typeof partnerBannerSchema>
