import { voidFn } from '@simplisafe/ewok'
import { window } from 'browser-monads-ts'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

import type { RudderAnalytics } from './rudderstack'

/**
 *  *----------------------------*
 * RudderStackSDK is loaded via a html <script> tag in the head of our application.
 * This script tag ensures rudderanalytics is an object available on the window
 * and wll function as a queue for all SDK calls initiated before the SDK is fully
 * initialized.
 *  *----------------------------*
 */

/*
 * A wrapper to utilize Rudderstack functions safely.
 *
 * If `rudderanalytics` exists on the window, invoke the fn with the sdk passed to it.
 * Otherwise, `fn` will be swallowed into the abyss.
 *
 * @param fn callback function to be invoked with the rudderanalytics sdk.
 */
export function getRudderstack(fn: (t: RudderAnalytics) => void) {
  return pipe(O.fromNullable(window.rudderanalytics), O.fold(voidFn, fn))
}
