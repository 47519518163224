import { overloadMaybe } from '@simplisafe/ewok'
import { selectCurrentPromotionBanner } from '@simplisafe/ss-ecomm-data/redux/select'
import { useSelector } from 'react-redux'

/**
 *
 * @returns a PromoBanner object with details about the sitewide promotional banner
 *
 * @deprecated - this function and all redux sources for promotional data are no longer in use
 *
 * please use useCurrentPromo() for all promo data
 */
export const usePromotionBanner = () => {
  const promoBanner = useSelector(selectCurrentPromotionBanner)
  return overloadMaybe(promoBanner)
}
