import { getCartDiscountCode } from '@ecomm/data-cart'
import { getCurrencyFromLocale, getLocale } from '@ecomm/utils'
import { prop } from '@simplisafe/ewok'
import type {
  ImmutableCart,
  LineItem
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import type { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'

import * as F from 'fp-ts/lib/function'
import type {
  EcommEventBase,
  ProductAddedEvent,
  ProductPayload,
  ProductRemovedEvent
} from '../types'
import type {
  PackageProductSchema,
  ProductSchema,
  RudderProductProps
} from './schema'

type RudderstackEventProps = {
  readonly packageParentId?: string
  readonly productSku: string
  readonly includedProducts: PackageProductSchema['products']
  readonly components: readonly ProductSchema[]
  readonly totalPrice: number
}

export const bmsToRudderstackProducts = ({
  packageParentId,
  productSku,
  includedProducts,
  components,
  totalPrice
}: RudderstackEventProps): readonly ProductPayload[] => [
  packageProductToRudderstackProduct({
    packageParentId,
    productId: productSku,
    price: totalPrice,
    brand: 'bms'
  }),
  ...ssProductsToRudderstackProducts(includedProducts, true),
  ...ssProductsToRudderstackProducts(components, true)
]

const packageProductToRudderstackProduct = ({
  packageParentId,
  productId,
  price,
  quantity,
  name,
  brand
}: RudderProductProps): ProductPayload => ({
  item_list_id: packageParentId || '',
  category: packageParentId ? 'package_parent' : '',
  brand,
  product_id: productId,
  name: name ?? 'bms',
  price: Math.round((price + Number.EPSILON) * 100) / 100,
  quantity: quantity ?? 1
})

export const ssProductsToRudderstackProducts = (
  products: PackageProductSchema['products'] | readonly ProductSchema[],
  isBms = false,
  price?: number,
  brand?: string
): readonly ProductPayload[] =>
  products.map(product => {
    const hasQty = 'quantity' in product
    const quantity = hasQty ? product.quantity : 1
    const productPrice = price
      ? Math.round((price + Number.EPSILON) * 100) / 100
      : 0

    return packageProductToRudderstackProduct({
      brand: isBms ? 'bms' : brand,
      productId: product.sku,
      price: productPrice,
      quantity,
      name: product.name
    })
  })

const packageChildrenToRudderstackProducts = (
  children: readonly Partial<LineItem>[],
  isBms: boolean,
  brand: string
): readonly ProductPayload[] =>
  children.map(child => {
    const locale = getLocale()
    const hasQty = 'quantity' in child
    const quantity = hasQty ? child.quantity : 1
    const productName = child.name[locale] ?? child.name['en-US']

    return packageProductToRudderstackProduct({
      brand: isBms ? 'bms' : brand,
      productId: child.sku ?? '',
      price: 0,
      quantity,
      name: productName
    })
  })

export const ctProductToRudderstackProducts = (
  product: Pick<Product, 'masterSku' | 'name' | 'price'>,
  quantity: number,
  locale: string,
  price?: number
): readonly ProductPayload[] => {
  const productPrice = price ?? product.price
  const productName = product.name[locale] ?? product.name['en-US']
  return [
    packageProductToRudderstackProduct({
      productId: product.masterSku,
      price: productPrice,
      quantity,
      name: productName
    })
  ]
}

const buildEcommEventBase = (
  locale: string,
  products: readonly ProductPayload[],
  total: number
): EcommEventBase => ({
  currency: locale === 'en-GB' ? 'GBP' : 'USD',
  products,
  total
})

export function getRudderstackDataFromLineItem(
  lineItem: LineItem
): EcommEventBase {
  const locale = getLocale()
  const price = lineItem.totalPrice
  const discountedPrice = prop('discountedPricePerQuantity', lineItem)
  const productPrice = discountedPrice.getOrElse(price)

  const rudderProducts = lineItemToRudderstackProducts(
    lineItem,
    locale,
    productPrice
  )

  return buildEcommEventBase(locale, rudderProducts, productPrice)
}

export function getRudderstackDataFromProduct(
  product: Product,
  quantity: number
): EcommEventBase {
  const locale = getLocale()
  const price = product.price
  const discountedPrice = prop('discountedPrice', product)
  const productPrice = discountedPrice.getOrElse(price)

  const rudderProducts = ctProductToRudderstackProducts(
    product,
    quantity,
    locale,
    productPrice
  )

  return buildEcommEventBase(locale, rudderProducts, productPrice)
}

export const lineItemToRudderstackProducts = (
  lineItem: LineItem,
  locale: string,
  price?: number
): readonly ProductPayload[] => {
  const trackingProduct: ProductSchema = {
    sku: lineItem.sku,
    name: lineItem.sku,
    quantity: lineItem.quantity
  }

  // iterate over children if this is a package parent
  if (lineItem.productType === 'package_parent') {
    const packageHasChildren = !!lineItem.child?.length

    // unlikely case, just making typescript happy
    if (!lineItem.child || !packageHasChildren) {
      return ssProductsToRudderstackProducts([trackingProduct], true)
    }

    const fallbackName = lineItem.name[locale] ?? lineItem.name['en-US']
    const lineItemDisplayName = lineItem.custom?.fields?.['lineItemDisplayName']
    const packageName = lineItemDisplayName ?? fallbackName
    const isBms =
      !lineItemDisplayName && lineItem.custom?.fields['product_is_bms']

    const brand = isBms ? 'bms' : packageName

    // incrememt a package in cart
    return [
      {
        brand,
        product_id: lineItem.sku,
        name: packageName,
        price: price ?? lineItem.price,
        quantity: lineItem.quantity,
        category: lineItem.productType
      },
      // The products that are included in BMS (decals, etc.) are formatted here.
      // The new dynamic packages are basically BMS with some custom fields that
      // allow us to identify them as prebuilt.
      ...packageChildrenToRudderstackProducts(lineItem.child, isBms, brand)
    ]
  }

  const brand = lineItem.custom?.fields?.['lineItemDisplayName']
  const brandProp = brand ? { brand } : {}

  return [
    {
      ...brandProp,
      product_id: lineItem.sku,
      name: lineItem.name[locale] ?? lineItem.name['en-US'],
      price: price ?? lineItem.price,
      quantity: lineItem.quantity
    }
  ]
}

const getBmsIds = (items: readonly ProductPayload[]) =>
  items
    .filter(
      item =>
        item.brand === 'bms' &&
        item.category === 'package_parent' &&
        !!item.item_list_id
    )
    .map(item => item.item_list_id || '')

/**
 * There are some cases where the item category should be 'accessory' instead of 'package_parent'.
 * This happens when the item is a package parent and the product is a child of that package parent, it can be either "SSWD1" or "SSYS3".
 */
const getItemCategory = (lineItem: LineItem, product: ProductPayload) => {
  const isPackageParent = lineItem.productType === 'package_parent'
  const packageSubItems = lineItem.child || []

  const childSkus = packageSubItems.map(subChild => subChild.sku)
  const shouldOverrideCategory =
    childSkus.includes(product?.product_id) && isPackageParent

  return shouldOverrideCategory ? 'accessory' : lineItem.productType
}

const addTotalToBms = (
  bmsIds: readonly string[],
  items: readonly ProductPayload[]
) => {
  const itemsToUpdate = [...items]
  bmsIds.forEach(bmsId => {
    const currentBmsTotal = items.reduce((total, item) => {
      return item.item_list_id === bmsId ? total + (item?.price || 0) : total
    }, 0)
    const currentBmsIndex = items.findIndex(
      item => item.item_list_id === bmsId && item.category === 'package_parent'
    )
    if (itemsToUpdate[currentBmsIndex]) {
      itemsToUpdate[currentBmsIndex] = {
        ...itemsToUpdate[currentBmsIndex],
        price: Math.round(currentBmsTotal * 100) / 100
      }
    }
  })
  return itemsToUpdate
}

const addBrandToBmsItems = (
  bmsIds: readonly string[],
  items: readonly ProductPayload[]
) => {
  return items.map(item => {
    const isBmsItem =
      item.item_list_id &&
      bmsIds.includes(item.item_list_id) &&
      item.category !== 'package_parent'
    return isBmsItem
      ? {
          ...item,
          brand: 'bms',
          price: 0
        }
      : item
  })
}

export const addAdditionalDataToBmsItems = (
  items: readonly ProductPayload[]
) => {
  const bmsIds = getBmsIds(items)
  const itemsWithTotal = addTotalToBms(bmsIds, items)
  return addBrandToBmsItems(bmsIds, itemsWithTotal)
}

const lineItemsToRudderstackCartProducts = (lineItems: readonly LineItem[]) => {
  return addAdditionalDataToBmsItems(
    lineItems
      .map(item =>
        getRudderstackDataFromLineItem(item).products.map(product => ({
          ...product,
          item_category: getItemCategory(item, product),
          item_list_id: item.custom?.fields?.['package_parent_id']
        }))
      )
      .reduce((acc, current) => {
        return [...acc, ...current]
      }, [])
  )
}

const attachItemListId =
  (item_list_id: string) =>
  ({ products, ...event }: EcommEventBase): EcommEventBase => ({
    ...event,
    products: products.map(product => ({
      ...product,
      item_list_id
    }))
  })

const isIndividualComponent = (
  productsWithItemListId: readonly ProductPayload[]
) => productsWithItemListId.length === 1

const getPackageParentListItem = (
  productsWithItemListId: readonly ProductPayload[]
) =>
  productsWithItemListId.filter(({ category }) => category === 'package_parent')

// - If the selected item is a package, return the main package
// - If it's an individual product, return all relevant data associated with it
const getPackageParentOrIndividualComponent = (
  products: readonly ProductPayload[]
) =>
  isIndividualComponent(products)
    ? products
    : getPackageParentListItem(products)

const attachTrackingProduct = (event: EcommEventBase) => ({
  ...event,
  products: getPackageParentOrIndividualComponent(event.products)
})

const attachCouponAndCartId =
  (coupon: string, cartId: string) =>
  (event: EcommEventBase): ProductAddedEvent | ProductRemovedEvent => ({
    ...event,
    coupon,
    cartId
  })

export const createProductAddedRemovedPayload = (
  lineItem: LineItem,
  coupon: string,
  cartId: string
) => {
  const locale = getLocale()
  const newPrice = Number.parseFloat(
    (lineItem.price / lineItem.quantity).toFixed(2)
  )
  const newLineItem = {
    ...lineItem,
    quantity: 1
  }

  return F.pipe(
    lineItemToRudderstackProducts(newLineItem, locale, newPrice),
    rudderStackProducts =>
      buildEcommEventBase(locale, rudderStackProducts, newPrice),
    attachItemListId(newLineItem.custom?.fields?.['package_parent_id']),
    attachTrackingProduct,
    attachCouponAndCartId(coupon, cartId)
  )
}

export const createCartViewedPayload = (cart: ImmutableCart) => {
  const locale = getLocale()
  return {
    products: lineItemsToRudderstackCartProducts(cart.lineItems),
    currency: getCurrencyFromLocale(locale),
    total: cart.totalPrice,
    cart_id: cart.id,
    coupon: getCartDiscountCode(cart)
  }
}
