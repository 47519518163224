import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export function useTrackOdmonPlanSelection() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (plan: string, placement: 'cards' | 'chart' | 'modal') => {
      trackEvent({
        plan: plan,
        placement: placement,
        event: 'atc-plan-selection'
      })
    },
    [trackEvent]
  )
}
