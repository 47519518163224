import { type TypeOf, z } from '@simplisafe/ewok'

export const contentfulImageSchema = z.object({
  url: z.string(),
  description: z.string(),
  /** Map to an assets `width` to maintain the correct aspect ratio. */
  originalWidth: z.number(),
  /** Map to an assets `height` to maintain the correct aspect ratio. */
  originalHeight: z.number()
})

export type ContentfulImageSchema = TypeOf<typeof contentfulImageSchema>
