import { useMemo } from 'react'

import { useCurrentPromo } from '../useCurrentPromo'

enum LayoutTypes {
  default = 'default', // Tier One: offer text/label, Tier Two: Gift, Tier Three: Disclaimer
  noGift = 'noGift' // Tier One: offer text, Tier Two: offer label, Tier Three: Disclaimer
}

/**
 * Returns the desktop banner middle layout to use for OfferDetails.
 *
 * @returns string
 */
export function useDesktopMiddleLayout() {
  const { giftLineItemText } = useCurrentPromo()

  return useMemo(() => {
    if (!giftLineItemText) {
      return LayoutTypes.noGift
    } else {
      return LayoutTypes.default
    }
  }, [giftLineItemText])
}
