import { voidFn } from '@simplisafe/ewok'
import { match } from 'ts-pattern'

/**
 * Warns something to the console in development mode.
 * @param string The string to log
 * @returns void
 * @example
 * ```ts
 * devWarn('This is a log')
 * ```
 */

export function devWarn(string: string): void {
  match(process.env['NODE_ENV'] || import.meta.env?.MODE)
    .with('development', () => console.warn(string))
    .otherwise(voidFn)
}
