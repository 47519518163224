import { cartSetShippingMethod } from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { voidFn } from '@simplisafe/ewok'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useCallback } from 'react'

import { useSetCart } from '../atoms/useCart'

/**
 * Set the shipping method for a jotai cart
 */
export const useCartSetShippingMethod = () => {
  const cartId = getCartId()
  const setCart = useSetCart()

  return useCallback(
    (methodId: string) => {
      const fn = async (id: string) => {
        setCart(['set_cart_updating'])

        const cart = await cartSetShippingMethod(id, methodId)()

        pipe(
          cart,
          E.fold(
            err => setCart(['set_cart_error', err]),
            t => {
              setCart(['update_cart', t])
            }
          )
        )
      }

      pipe(
        O.fromNullable(cartId),
        O.fold(
          () => voidFn(),
          id => fn(id)
        )
      )
    },
    [cartId, setCart]
  )
}
