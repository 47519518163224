import {
  useCartDiscountCode,
  useCartLineItems,
  useCartShippingAddress,
  useCartShippingPrice,
  useCartTotalAmount
} from '@ecomm/data-cart'
import { useLocale } from '@ecomm/data-hooks'
import { awinRequest } from '@ecomm/data-simplisafe-api'
import { logError } from '@ecomm/error-handling'
import { COOKIE_AWIN, getCookie } from '@ecomm/shared-cookies'
import { set as sessionStorageSet } from '@ecomm/utils'
import { getDiscountedAmount } from '@simplisafe/eis-commercetools-carts'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'
import { brazeTrackPurchaseComplete } from '../braze'
import { getCurrencyCode, simplifyLineItems } from '../cart/lineItems'
import { populateBrand, useCTProducts } from '../cart/populateBrand'
import { getOnTechUrlJotai } from '../onTechProInstall/getOnTechUrlJotai'
import type { TrackingData } from '../types/tracking'

export const useTrackPaymentConfirmation = () => {
  const { trackEvent } = useTracking()

  const locale = useLocale()
  const shippingAddress = useCartShippingAddress()
  const totalAmount = useCartTotalAmount()
  const discountCode = useCartDiscountCode()
  const shippingPrice = useCartShippingPrice()

  const lineItems_ = useCartLineItems()
  const ctProducts = useCTProducts(lineItems_)

  return useCallback(
    (
      selectedPaymentOption: TrackingData['selectedPaymentOption'],
      orderId: string
    ) => {
      const lineItems = populateBrand(lineItems_, ctProducts, locale)

      const systemInOrder = lineItems.some(item => item.packageItems.length > 0)
      const currencyCode = getCurrencyCode(lineItems, locale)

      // using this for mention-me.js file
      shippingAddress &&
        sessionStorageSet(
          'userData',
          JSON.stringify({
            email: shippingAddress.email,
            firstName: shippingAddress.firstName,
            lastName: shippingAddress.lastName,
            transactionTotal: totalAmount
          })
        )

      const trackingLineItems = simplifyLineItems(lineItems, locale)
      const ecommerce = {
        checkout: {
          products: trackingLineItems
        },
        currencyCode: currencyCode
      }

      trackEvent({
        ecommerce: ecommerce,
        event: systemInOrder ? 'purchaseSystem' : 'purchase',
        eventCategory: systemInOrder ? 'Purchase System' : 'Purchase',
        orderTotal: totalAmount / 100,
        selectedPaymentOption: selectedPaymentOption,
        transactionCoupon: discountCode,
        // manually exclude shipping cost until ECP-13311 is completed
        transactionDiscount:
          getDiscountedAmount(lineItems, totalAmount - shippingPrice, locale) /
          100,
        transactionId: orderId,
        transactionTotal: totalAmount / 100,
        userData: shippingAddress
          ? {
              email: shippingAddress.email,
              firstName: shippingAddress.firstName,
              lastName: shippingAddress.lastName,
              phoneNumber: shippingAddress.phone
            }
          : null
      })

      brazeTrackPurchaseComplete({
        currencyCode: currencyCode,
        orderId: orderId,
        phoneNumber: shippingAddress?.phone ?? '',
        products: trackingLineItems,
        systemInOrder: systemInOrder,
        onTechUrl: getOnTechUrlJotai(
          orderId,
          lineItems,
          shippingAddress?.postalCode
        )
      })

      const awinCookie = getCookie<string>(COOKIE_AWIN)
      locale === 'en-GB' &&
        awinCookie &&
        awinRequest(
          {
            amount: totalAmount / 100,
            clickSource: awinCookie,
            orderRef: orderId,
            voucher: discountCode || ''
          },
          err => logError(err)
        )
    },
    [
      ctProducts,
      discountCode,
      lineItems_,
      locale,
      shippingAddress,
      shippingPrice,
      totalAmount,
      trackEvent
    ]
  )
}
