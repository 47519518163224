import type { LineItem } from '@simplisafe/eis-commercetools-carts'
import { systemPieceSkus } from './types'
import { orderSizeToPackageVariant, productsToAddOnVariants } from './utils'

export const getOnTechUrlJotai = (
  orderId: string,
  lineItems: readonly LineItem[],
  zipCode?: string
) => {
  const onTechUrlPath = 'https://www.ontechsmartservices.com/pages/referral'

  const items = lineItems.filter(
    item => item.sku && systemPieceSkus.includes(item.sku)
  )
  const packageItems = lineItems
    .flatMap(item => item.packageItems)
    .filter(item => systemPieceSkus.includes(item.sku))
  const quantity = [...items, ...packageItems].reduce(
    (acc: number, item) => acc + item.quantity,
    0
  )

  const hasProInstall = lineItems.some(item => item.isProInstallation)

  const packageVariant = orderSizeToPackageVariant(quantity)
  const addOnVariants = productsToAddOnVariants([...items, ...packageItems])
  const variants = [packageVariant, ...addOnVariants].filter(
    variant => variant !== undefined
  )

  const params = [
    'clientName=simplisafe',
    `variants=${variants.join(',')}`,
    ...(zipCode ? [`zipCode=${zipCode.slice(0, 5)}`] : []),
    `partnerRefId=${orderId}`
  ]

  return hasProInstall && quantity <= 30
    ? `${onTechUrlPath}?${params.join('&')}`
    : ''
}
