import { match } from 'ts-pattern'

import { useCartValue } from '../atoms/useCart'

/**
 * Returns the cart state from CT as a string.
 *
 * Defaults to null if the cart is not initialized, empty, or has an error.
 */
export const useCartCTState = () => {
  const cart = useCartValue()

  return match(cart)
    .with({ _tag: 'not_initialized' }, _ => null)
    .with({ _tag: 'with_items' }, cart => cart.val.ctState)
    .with({ _tag: 'updating' }, cart => cart.val.ctState)
    .with({ _tag: 'error' }, _ => null)
    .with({ _tag: 'empty' }, _ => null)
    .with({ _tag: 'no_cart_id' }, _ => null)
    .exhaustive()
}
