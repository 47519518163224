import { getPartnerCookie } from '@ecomm/shared-cookies'
import { useCustomerGroupKey } from '@ecomm/shared-hooks'
import { useOptimizelyParams, userAttributes } from '@ecomm/tracking'
import { type Locale, isNotNil, safeProp } from '@ecomm/utils'
import { overloadMaybe } from '@simplisafe/ewok'
import { localizedDisplayPrice } from '@simplisafe/ss-ecomm-data/commercetools/price'
import {
  type DynamicDiscount,
  type GiftItemDTO,
  type Prices,
  requestPrices
} from '@simplisafe/ss-ecomm-data/prices/service'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/ReadonlyArray'
import { lookup } from 'fp-ts/lib/Record'
import { useState } from 'react'

import {
  type NinetailedExperience,
  experienceAtom
} from '@ecomm/data-storage/ninetailed-experiences'
import { useQuery } from '@tanstack/react-query'
import { constant, identity, pipe } from 'fp-ts/lib/function'
import { useAtom } from 'jotai'

import { PriceContext } from './PriceContext'
import { type PriceFormatter, priceDefault, pricePerUnit } from './formatter'
import {
  applyRelativeDiscount,
  calculateAndGetRelativeDiscountText,
  divideByFractionDigits,
  getSS2UpgradeRelativeDiscount,
  halfRoundDownPerLineItem
} from './priceHelpers'
import type { PriceProviderProps, ProductQuantity } from './types'

const excludedSkus = new Set(['SSPSH-ON', 'SSPSH-UK'])

export const formatPrice =
  (formatter: PriceFormatter, locale: Locale, compact = false) =>
  (price: O.Option<number>) =>
    pipe(
      price,
      O.chain((n: number) => (isNaN(n) ? O.none : O.some(n))),
      O.chain(x =>
        overloadMaybe(
          localizedDisplayPrice(locale, {
            notation: compact ? 'compact' : 'standard'
          })(x)
        )
      ),
      O.map(formatter)
    )

const getPriceData = (sku: string, prices: Prices) =>
  pipe(
    prices,
    lookup(sku),
    O.filter(priceData => isNotNil(priceData.price))
  )

export const getRawPrice = (prices: Prices) => (sku: string) =>
  pipe(
    getPriceData(sku, prices),
    O.map(p => divideByFractionDigits(p.price, p.fractionDigits))
  )

export const getPrice =
  (prices: Prices) =>
  (sku: string, months = 1) =>
    pipe(
      getRawPrice(prices)(sku),
      O.map(price => price / months)
    )

export const getFormattedPriceHelper =
  (prices: Prices, formatter: PriceFormatter, locale: Locale) =>
  (sku: string, months = 1) =>
    pipe(
      sku,
      getRawPrice(prices),
      O.map(price => price / months),
      formatPrice(formatter, locale)
    )

export const getRawTotalPrice =
  (prices: Prices) =>
  (list: readonly ProductQuantity[], months = 1) =>
    pipe(
      list,
      A.map(x =>
        pipe(
          getRawPrice(prices)(x.sku),
          O.map(price => price * x.quantity),
          O.map(price => price / months),
          O.getOrElse(() => 0)
        )
      ),
      A.reduce(0, (acum, el) => acum + el)
    )

export const getRawTotalDiscountedPrice =
  (prices: Prices) =>
  (
    sku: string,
    list: readonly ProductQuantity[],
    months = 1,
    isSS2Upgrade = false
  ) =>
    pipe(
      getPriceData(sku, prices),
      O.chain(({ fractionDigits, absoluteDiscount, relativeDiscount }) => {
        const absoluteTotal = pipe(
          O.fromNullable(absoluteDiscount),
          O.map(discount => divideByFractionDigits(discount, fractionDigits)),
          O.map(discount => getRawTotalPrice(prices)(list) - discount),
          O.map(price => price / months)
        )
        const relativeTotal = pipe(
          O.fromNullable(
            isSS2Upgrade ? getSS2UpgradeRelativeDiscount() : relativeDiscount
          ),
          O.map(discount => divideByFractionDigits(discount, fractionDigits)), // If 20%, converts 2000 to 20
          O.map(relativeDiscount =>
            list
              .map(({ sku, quantity }) =>
                pipe(
                  getRawPrice(prices)(sku),
                  O.map(applyRelativeDiscount(relativeDiscount)),
                  O.map(halfRoundDownPerLineItem(quantity)), // apply Commercetools discount-rounding logic
                  O.getOrElse(() => 0)
                )
              )
              .reduce((total, price) => total + price, 0)
          ),
          O.map(price => price / months)
        )

        return pipe(
          absoluteTotal,
          O.alt(() => relativeTotal)
        )
      })
    )

export const getRawTotalDiscountedPriceWithServicePlan =
  (prices: Prices) =>
  (sku: string, list: readonly ProductQuantity[], months = 1) =>
    pipe(
      getPriceData(sku, prices),
      O.chain(
        ({
          fractionDigits,
          absoluteDiscountWithServicePlan,
          relativeDiscountWithServicePlan
        }) => {
          const absoluteTotal = pipe(
            O.fromNullable(absoluteDiscountWithServicePlan),
            O.map(discount => divideByFractionDigits(discount, fractionDigits)),
            O.map(discount => getRawTotalPrice(prices)(list) - discount),
            O.map(price => price / months)
          )
          // We ignore the package relative discount, since product relative discount is a better source of truth.
          const relativeTotal = pipe(
            O.fromNullable(relativeDiscountWithServicePlan),
            O.map(discount => divideByFractionDigits(discount, fractionDigits)), // If 20%, converts 2000 to 20
            O.map(relativeDiscountWithServicePlan =>
              list
                .map(({ sku, quantity }) =>
                  pipe(
                    getRawPrice(prices)(sku),
                    O.map(
                      applyRelativeDiscount(relativeDiscountWithServicePlan)
                    ),
                    O.map(halfRoundDownPerLineItem(quantity)), // apply Commercetools discount-rounding logic
                    O.getOrElse(() => 0)
                  )
                )
                .reduce((total, price) => total + price, 0)
            ),
            O.map(price => price / months)
          )

          return pipe(
            absoluteTotal,
            O.alt(() => relativeTotal)
          )
        }
      )
    )

export const getFreeGiftItemHelper =
  (prices: Prices) =>
  (sku: string): O.Option<readonly GiftItemDTO[]> =>
    pipe(getPriceData(sku, prices), O.chain(safeProp('withoutMonitoringGifts')))

export const getFreeGiftItemWithServicePlanHelper =
  (prices: Prices) =>
  (sku: string): O.Option<readonly GiftItemDTO[]> =>
    pipe(getPriceData(sku, prices), O.chain(safeProp('withMonitoringGifts')))

export const getDiscountTextHelper =
  (prices: Prices, formatter: PriceFormatter, locale: Locale) =>
  (sku: string): O.Option<string> =>
    pipe(
      getPriceData(sku, prices),
      O.chain(safeProp('absoluteDiscount')),
      O.map(p => divideByFractionDigits(p, 2)),
      formatPrice(formatter, locale, true),
      O.alt(() =>
        pipe(
          getPriceData(sku, prices),
          O.chain(safeProp('relativeDiscount')),
          O.map(p => divideByFractionDigits(p, 2)),
          O.map(p => p.toString() + '%')
        )
      )
    )

export const getRelativeDiscountTextWithServicePlanHelper =
  (prices: Prices) =>
  (sku: string): O.Option<string> =>
    pipe(
      getPriceData(sku, prices),
      O.chain(lookup('relativeDiscountWithServicePlan')),
      O.map(p => divideByFractionDigits(Number(p), 2)),
      O.map(p => p.toString() + '%'),
      O.alt(() =>
        pipe(
          getPriceData(sku, prices),
          O.chain(p =>
            calculateAndGetRelativeDiscountText(
              p.price,
              p.absoluteDiscountWithServicePlan
            )
          )
        )
      )
    )

export const getDiscountTextWithServicePlanHelper =
  (prices: Prices, formatter: PriceFormatter, locale: Locale) =>
  (sku: string): O.Option<string> =>
    pipe(
      getPriceData(sku, prices),
      O.chain(safeProp('absoluteDiscountWithServicePlan')),
      O.map(p => divideByFractionDigits(p, 2)),
      formatPrice(formatter, locale, true),
      O.alt(() =>
        pipe(
          getPriceData(sku, prices),
          O.chain(safeProp('relativeDiscountWithServicePlan')),
          O.map(p => divideByFractionDigits(p, 2)),
          O.map(p => p.toString() + '%')
        )
      )
    )

export const getRawDiscountedPrice =
  (prices: Prices) =>
  (sku: string, months = 1): O.Option<number> =>
    pipe(
      getPriceData(sku, prices),
      O.chain(safeProp('discountedPrice')),
      O.map(p => divideByFractionDigits(p, 2)),
      O.map(price => price / months)
    )

export const getDiscountedPriceWithServicePlan =
  (prices: Prices) =>
  (sku: string, months = 1): O.Option<number> =>
    pipe(
      getPriceData(sku, prices),
      O.chain(safeProp('discountedPriceWithServicePlan')),
      O.map(p => divideByFractionDigits(p, 2)),
      O.map(price => price / months)
    )

export const getDynamicDiscountsThresholdsHelper =
  (prices: Prices) =>
  (sku: string): O.Option<readonly DynamicDiscount[]> =>
    pipe(
      getPriceData(sku, prices),
      O.map(({ dynamicDiscounts = [], fractionDigits = 2 }) =>
        dynamicDiscounts.map(d => ({
          ...d,
          threshold: {
            ...d.threshold,
            lowerThreshold: divideByFractionDigits(
              d.threshold.lowerThreshold,
              fractionDigits
            ),
            upperThreshold: divideByFractionDigits(
              d.threshold.upperThreshold,
              fractionDigits
            )
          },
          value: divideByFractionDigits(d.value, fractionDigits)
        }))
      )
    )

export const getMatchingDynamicPrice =
  (totalPrice: number) =>
  (
    dynamicDiscounts: O.Option<readonly DynamicDiscount[]>
  ): O.Option<DynamicDiscount> =>
    pipe(
      dynamicDiscounts,
      O.fold(constant([]), identity),
      A.findFirst(
        ({ threshold: { lowerThreshold, upperThreshold } }) =>
          totalPrice >= lowerThreshold && totalPrice < upperThreshold
      )
    )

export const getDynamicDiscountedPriceHelper =
  (prices: Prices) =>
  (totalPrice: number, sku: string): O.Option<number> =>
    pipe(
      getDynamicDiscountsThresholdsHelper(prices)(sku),
      getMatchingDynamicPrice(totalPrice),
      O.map(({ discountType, value: discount }) => {
        if (discountType === 'absolute') {
          return totalPrice - discount
        } else {
          const roundedTotalPrice = Math.floor(totalPrice * 100) / 100
          const relativeDiscount = roundedTotalPrice * (discount / 100)
          return Math.floor((roundedTotalPrice - relativeDiscount) * 100) / 100
        }
      })
    )

export const getNinetailedExperienceHeader = (
  experiences: O.Option<readonly NinetailedExperience[]>
) =>
  pipe(
    experiences,
    O.fold(
      () => O.toUndefined(O.none),
      exps => ({
        'x-9t-experiences': JSON.stringify(exps)
      })
    )
  )

export function PriceProvider({
  children,
  locale,
  skus,
  partnerName: partnerNameProp,
  partnerGroup: partnerGroupProp
}: PriceProviderProps) {
  const [prices, setPrices] = useState<Prices>({})
  const [isLoading, setLoading] = useState(true)
  const [experiences] = useAtom(experienceAtom)

  const attributes = userAttributes()
  const optimizelyParams = useOptimizelyParams()
  const cookiePartnersData = getPartnerCookie()
  const partnerName = partnerNameProp || cookiePartnersData?.partnerName
  const partnerGroup = partnerGroupProp || cookiePartnersData?.partnerGroup
  const cartCustomerGroup = useCustomerGroupKey()
  const customerGroup = partnerGroup || cartCustomerGroup.orUndefined()

  const includedSkus = skus.filter(sku => !excludedSkus.has(sku))
  const headers = getNinetailedExperienceHeader(experiences)
  const readyToFetchPrices =
    includedSkus.length > 0 && (O.isSome(experiences) || locale === 'en-GB')

  useQuery({
    queryKey: [`prices-${includedSkus.join('-')}`],
    queryFn: () =>
      requestPrices(
        includedSkus,
        attributes,
        customerGroup,
        optimizelyParams,
        partnerName,
        headers
      )(() => setLoading(false))(response => {
        response.forEach(newPrices => setPrices(newPrices))
        setLoading(false)
      }),
    enabled: readyToFetchPrices,
    retry: false
  })

  return (
    <PriceContext.Provider
      value={{
        calculateTotalPrice: getRawTotalPrice(prices),
        calculateTotalDiscountedPrice: getRawTotalDiscountedPrice(prices),
        calculateTotalDiscountedPriceWithServicePlan:
          getRawTotalDiscountedPriceWithServicePlan(prices),
        formatPrice: price => formatPrice(priceDefault, locale)(price),
        getDiscountText: getDiscountTextHelper(prices, priceDefault, locale),
        getDiscountTextWithServicePlan: getDiscountTextWithServicePlanHelper(
          prices,
          priceDefault,
          locale
        ),
        getDiscountedPrice: getRawDiscountedPrice(prices),
        getDiscountedPriceWithServicePlan:
          getDiscountedPriceWithServicePlan(prices),
        getFormattedPrice: getFormattedPriceHelper(
          prices,
          pricePerUnit,
          locale
        ),
        getFreeGiftItems: getFreeGiftItemHelper(prices),
        getFreeGiftItemsWithServicePlan:
          getFreeGiftItemWithServicePlanHelper(prices),
        getPrice: getPrice(prices),
        getDynamicDiscountsThresholds:
          getDynamicDiscountsThresholdsHelper(prices),
        getDynamicDiscountedPrice: getDynamicDiscountedPriceHelper(prices),
        isLoading,
        getRelativeDiscountTextWithServicePlan:
          getRelativeDiscountTextWithServicePlanHelper(prices)
      }}
    >
      {children}
    </PriceContext.Provider>
  )
}
