import type { CookieGetOptions, CookieSetOptions } from 'universal-cookie'

import { initCookies } from './initCookies'

/**
 * Gets a cookie.
 */
export const getCookie = <T = string>(
  name: string,
  options?: CookieGetOptions
): T => initCookies().get(name, options)

/**
 * Sets a cookie.
 */
export const setCookie = <T>(
  name: string,
  value: T,
  options?: CookieSetOptions
) => initCookies().set(name, value, options)

/**
 * Get all cookies
 */
export const getAllCookies = <T = string>(options?: CookieGetOptions): T =>
  initCookies().getAll(options)
