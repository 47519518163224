export const SCOUT_SKU = 'CM006-01DWW'
export const SHIELD_SKU = 'CMOB1'
export const OLYMPUS_SKU = 'CM021-01DWW'
export const OLYMPUS_GIFT_SKU = 'CM021-01DWW-INC'
export const OUTDOOR_CAM_SKUS = [SHIELD_SKU, OLYMPUS_SKU]
export const CABLE_SKU = 'CMCBL1'
export const SIMPLICAM_SKU = 'SSCM2'
export const INTERACTIVE_MONITORING = 'SSEDSM2__4867366'
export const PRO_PREMIUM_MONITORING = 'SSEDSM2_GB__5229044'
export const FAST_PROTECT_THREE_MONTHS_OFFER = 'SSEDSM2__16247908'
export const STANDARD_MONITORING = 'SSSAPP__5475172'
export const ODMON_OVERNIGHT_MONITORING = 'SSODMONON__13798447'
export const ODMON_OVERNIGHT_MONITORING_60 = 'SSODMONON__16723548'
export const ODMON_247_MONITORING = 'SSODMON247__13798553'
export const ODMON_247_MONITORING_60 = 'SSODMON247__16723550'
export const SELF_MONITORING_WITH_CAMERA_RECORDINGS = 'SSSLFCAM__7711192'
export const PRO_PREMIUM_MONITORING_UK = 'SSEDSM2_GB__5229044'
export const PRO_INSTALL = 'SSPSH-ON'
export const BATTERY_SKUS = ['SSCAM-BAT1']
export const ODMON_PREBUILT_PACKAGES = [
  'beacon-en-US',
  'lighthouse-en-US',
  'ss3-11-pieces-outdoors-kit-certified-refurbished-en-US',
  'ss3-16-pieces-outdoors-kit-certified-refurbished-en-US'
]
export const BRAUN_WHITE_SKU = 'CA010-01DUS'
export const BRAUN_BLACK_SKU = 'CA010-02DUS'

export const BRAUN_SKUS = [BRAUN_BLACK_SKU, BRAUN_WHITE_SKU]
export const SKUS_WITH_CART_MAXES = [
  SCOUT_SKU,
  OLYMPUS_SKU,
  SHIELD_SKU,
  SIMPLICAM_SKU,
  BRAUN_WHITE_SKU
]
