import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export function useTrackOdmonSeeDetails() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (question: string) => {
      trackEvent({
        question,
        event: 'ccm-see-details'
      })
    },
    [trackEvent]
  )
}
