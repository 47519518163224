import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { z } from 'zod'

/**
 * For a US phone number
 * * Required
 * * Min length of 10
 * * A trimmed string (no leading or trailing whitespace)
 * * must pass validatePhoneNumber function
 */
export const validatePhoneNumber = (
  value: string | undefined,
  region: 'GB' | 'US'
) => {
  if (!value || value.length < 9) {
    return false
  } else {
    return (
      parsePhoneNumberFromString(value, {
        defaultCountry: region,
        extract: false
      })?.isValid() || false
    )
  }
}

export const usPhoneSchema = z
  .string({ required_error: 'Phone is a required field' })
  .min(10, { message: 'Phone must contain at least 10 characters' })
  .trim()
  .refine(
    val => validatePhoneNumber(val, 'US'),
    val => ({ message: `${val} is not a valid US phone number` })
  )

export const ukPhoneSchema = z
  .string({ required_error: 'Phone is a required field' })
  .min(10, { message: 'Phone must contain at least 10 characters' })
  .trim()
  .refine(
    val => validatePhoneNumber(val, 'GB'),
    val => ({ message: `${val} is not a valid UK phone number` })
  )
