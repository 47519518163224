import { useCartLineItems } from '@ecomm/data-cart'
import { useLocale } from '@ecomm/data-hooks'
import { useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'

import { useJotaiTrackingCartViewed } from '@ecomm/cdp-tracking'
import { useCartValue } from '@ecomm/data-cart'
import { voidFn } from '@simplisafe/ewok'
import { match } from 'ts-pattern'
import type { TrackingData } from '../types/tracking'
import { getCurrencyCode, simplifyLineItems } from './lineItems'
import { populateBrand, useCTProducts } from './populateBrand'

/**
 * EEC event that fires once on page load.
 *
 * This function should not fire if the cart has no items in it, even if the cart is initialized.
 */
export function useTrackingEECCart() {
  const { trackEvent } = useTracking<TrackingData>()

  const [called, setCalled] = useState(false)

  const locale = useLocale()
  const lineItems_ = useCartLineItems()
  const ctProducts = useCTProducts(lineItems_)
  const lineItems = populateBrand(lineItems_, ctProducts, locale)

  const cart = useCartValue()
  const trackCartViewed = useJotaiTrackingCartViewed()

  return useEffect(() => {
    const ecommerce = {
      checkout: {
        products: simplifyLineItems(lineItems, locale)
      },
      currencyCode: getCurrencyCode(lineItems, locale)
    }

    const fn = () => {
      trackEvent({
        ecommerce,
        event: 'eec.cart',
        eventAction: 'checkout',
        eventCategory: 'eec',
        eventLabel: 'cart'
      })

      match(cart)
        .with({ _tag: 'with_items' }, cart => {
          trackCartViewed(cart.val)
        })
        .otherwise(voidFn)

      setCalled(true)
    }

    !called && lineItems.length > 0 && fn()
  }, [trackEvent, locale, lineItems, called])
}
