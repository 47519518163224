import { getBraze } from '@ecomm/shared-window'
import { safeProp } from '@simplisafe/monda'
import type { LeadGenCaptureResponse } from '@simplisafe/ss-ecomm-data/simplisafe'

import { initUser, pollBrazeInitialization, updateBrazeUser } from './lib'
/**
 * Sets the current user and updates user's braze profile with data contained in leads response
 */
export async function updateLeadsData(
  data?: LeadGenCaptureResponse,
  channel?: string,
  firstSubmission?: boolean
) {
  const brazeInitialized = await pollBrazeInitialization()
  console.info(
    `Braze: in updateLeadsData called polled braze initialization state: ${brazeInitialized}`
  )
  if (!brazeInitialized) {
    return false
  }
  return getBraze(async braze => {
    const leadData: Required<LeadGenCaptureResponse> = {
      leadId: safeProp('leadId', data).getOrElse(''),
      email: safeProp('email', data).getOrElse(''),
      externalId: safeProp('externalId', data).getOrElse(''),
      leadSource: safeProp('leadSource', data).getOrElse('')
    }
    await initUser(braze, leadData)
    braze.openSession()
    return await updateBrazeUser(
      braze,
      braze.getUser(),
      leadData,
      channel,
      firstSubmission
    ).finally(() => {
      console.info(
        `Braze: In function updateLeadsData called with ${JSON.stringify({
          leadId: leadData.leadId,
          externalId: leadData.externalId
        })}`
      )
      console.info('Braze: In function updateLeadsData called initUser')

      console.info('Braze: In function updateLeadsData called openSession')
    })
  })
}
