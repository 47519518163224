import type { GatsbyImageSchema } from '@ecomm/shared-components'

const image: GatsbyImageSchema = {
  gatsbyImageData: {
    images: {
      sources: [
        {
          srcSet:
            'https://images.ctfassets.net/v6awd0kabi65/5XmFB9CV8bQuyBxXbUbrBg/098f938e7d8f31e7fbd6a46d0ea4e88c/MonComponent_art.jpg?w=172&h=173&q=50&fm=webp 172w,\nhttps://images.ctfassets.net/v6awd0kabi65/5XmFB9CV8bQuyBxXbUbrBg/098f938e7d8f31e7fbd6a46d0ea4e88c/MonComponent_art.jpg?w=343&h=344&q=50&fm=webp 343w,\nhttps://images.ctfassets.net/v6awd0kabi65/5XmFB9CV8bQuyBxXbUbrBg/098f938e7d8f31e7fbd6a46d0ea4e88c/MonComponent_art.jpg?w=686&h=688&q=50&fm=webp 686w',
          type: 'image/webp'
        }
      ],
      fallback: {
        src: 'https://images.ctfassets.net/v6awd0kabi65/5XmFB9CV8bQuyBxXbUbrBg/098f938e7d8f31e7fbd6a46d0ea4e88c/MonComponent_art.jpg?w=686&h=688&fl=progressive&q=50&fm=jpg'
      }
    },
    layout: 'constrained',
    width: 686,
    height: 688
  },
  title: 'Dead End Image'
}

const headline = 'Monitoring powered by advanced technology'

const body =
  'Discover the high-tech hardware that makes our monitoring possible. Customize a system for your home and budget.'

const button1 = {
  text: 'Take our quiz'
}

const button2 = {
  text: 'Build a system',
  href: '/build-my-system'
}

export const data = { image, headline, body, button1, button2 }
