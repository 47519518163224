import { getRudderstack } from '@ecomm/shared-window'

export type PromotionExperienceProps = {
  /** Variant ID of the promotion the user has been selected for */
  readonly ninetailedComponent: string
  /** Experience ID that contains the A/B test and both variants */
  readonly ninetailedExperience: string
  /** Variant the user is selected for, either control or variant 1 */
  readonly ninetailedVariant: string
  readonly vid: string
}

export function trackPromotionExperience({
  ninetailedComponent,
  ninetailedExperience,
  ninetailedVariant,
  vid
}: PromotionExperienceProps) {
  getRudderstack(r =>
    r.track('nt_experience', {
      ninetailed_component: ninetailedComponent,
      ninetailed_experience: ninetailedExperience,
      ninetailed_variant: ninetailedVariant,
      vid
    })
  )
}
