/**
 * IMPORTANT NOTE: interceptFetchHeaders MUST be imported first, otherwise fetch-intercept
 * can break in unexpected ways and cause us to stop sending the X-Vid-Token header to the backend.
 * Long-term, we should stop using fetch-intercept, and the headers required for SS API requests
 * should just be handled by the actual functions that make the requests (in ss-ecomm-data, etc).
 * - csims
 */
import { interceptFetchHeaders } from '@ecomm/tracking'

// import '@ecomm/ss-react-components/styles.css'
import 'react-loading-skeleton/dist/skeleton.css'
import './src/styles/fonts.css'
import './src/styles/global.css'

import { ApolloProvider } from '@apollo/client/index.js'
import { configureNewRelic } from '@ecomm/error-handling'
import { getNewRelicOptions } from '@ecomm/error-handling/newrelicConfig'
import { client } from '@ecomm/shared-apollo'
import { pushToDataLayer } from '@ecomm/shared-window'
import { localeToLocalStorage } from '@ecomm/utils'
import { BrowserAgent } from '@newrelic/browser-agent'
import type { ReactElement } from 'react'
import { WrapPageElement } from './src/WrapPageElement'
import { WrapRootElement } from './src/WrapRootElement'

type WrapWithProviderProps = {
  readonly element: ReactElement
}

export function wrapRootElement<T extends WrapWithProviderProps>(t: T) {
  return (
    <ApolloProvider client={client}>
      <WrapRootElement {...t} />
    </ApolloProvider>
  )
}
export const wrapPageElement = WrapPageElement
const deployEnv = process.env['DEPLOY_ENV']

function getNewRelicAgent() {
  return new BrowserAgent(getNewRelicOptions(deployEnv))
}

// Registers fetch intercept.
// Called when the Gatsby browser runtime first starts: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#onClientEntry
export const onClientEntry = () => {
  getNewRelicAgent()
  interceptFetchHeaders()
  // initialize GTM dataLayer
  pushToDataLayer({ site: 'fcp' })()
  // configure newrelic with release id, custom attributes, etc
  configureNewRelic()
  // push locale to local storage
  // used when locale is needed by code that is outside of our react runtime
  localeToLocalStorage()
}
