import { useCallback } from 'react'

import { useTracking } from 'react-tracking'
import { useTrackMetricEvent } from '../useTrackMetricEvent'

export type LoginStatus =
  | 'API_FAILURE'
  | 'INVALID_OR_CLOSED'
  | 'NO_DATA'
  | 'PROCESSING_ERROR'
  | 'SUCCESS'
  | 'TIMEOUT'
export type LoginLocation = 'CHECKOUT'

export const useTrackingLoggedIn = () => {
  const trackMetricEvent = useTrackMetricEvent()
  const { trackEvent } = useTracking()

  return useCallback(
    (loginStatus: LoginStatus, loginLocation: LoginLocation) => {
      // track PageAction to NR
      trackMetricEvent('logged_in', {
        status: loginStatus,
        location: loginLocation
      })
      // track to GA via GTM
      trackEvent({
        event: 'logged_in',
        status: loginStatus,
        location: loginLocation
      })
    },
    [trackMetricEvent]
  )
}
