import {
  useCartLineItems,
  useCartShippingAddress,
  useCartTotalAmount
} from '@ecomm/data-cart'
import { useLocale } from '@ecomm/data-hooks'
import { logError } from '@ecomm/error-handling'
import { useCallback } from 'react'
import { generateFacebookEventId, useFacebookTrackProductPurchase } from '.'
import { sha256Web } from './lib'

export const useFacebookTrackProductPurchaseJotai = () => {
  const locale = useLocale()

  const lineItems = useCartLineItems()
  const shippingAddress = useCartShippingAddress()
  const facebookTrackProductPurchase = useFacebookTrackProductPurchase()
  const totalAmount = useCartTotalAmount()

  return useCallback(
    (orderId: string) => {
      const facebookItems = lineItems.map(item => ({
        price: (item.pricePerUnit[locale]?.centAmount ?? 0) / 100,
        productType: item.productType,
        qty: item.quantity,
        sku: item.sku
      }))

      const email = shippingAddress?.email ?? ''
      const phone = shippingAddress?.phone ?? ''

      return sha256Web(email)
        .then(hash =>
          facebookTrackProductPurchase({
            contents: facebookItems,
            email: hash,
            eventId: generateFacebookEventId(),
            orderId: orderId,
            phone,
            value: totalAmount / 100
          })
        )
        .catch(logError)
    },
    [
      facebookTrackProductPurchase,
      lineItems,
      locale,
      shippingAddress?.email,
      shippingAddress?.phone,
      totalAmount
    ]
  )
}
