import type { PartnerCookie } from '@ecomm/shared-cookies'

export const D2DAgentIdCookie = 'D2DAgentId'
export const D2DPartnerGroup = 'doorToDoor'

export const D2DCydcorKitValue: Readonly<PartnerCookie> = {
  partnerGroup: D2DPartnerGroup,
  partnerName: 'cydcorkit',
  partnerUrl: '/cydcorkit'
}

export const D2DCydcorNonKitValue: Readonly<PartnerCookie> = {
  partnerGroup: D2DPartnerGroup,
  partnerName: 'cydcor',
  partnerUrl: '/cydcor'
}

export const D2DFieldForceKitValue: Readonly<PartnerCookie> = {
  partnerGroup: D2DPartnerGroup,
  partnerName: 'fieldforcekit',
  partnerUrl: '/fieldkit'
}

export const D2DFieldForceNonKitValue: Readonly<PartnerCookie> = {
  partnerGroup: D2DPartnerGroup,
  partnerName: 'fieldforce',
  partnerUrl: '/field'
}

export const D2DPartnerNames = [
  D2DCydcorKitValue.partnerName,
  D2DCydcorNonKitValue.partnerName,
  D2DFieldForceKitValue.partnerName,
  D2DFieldForceNonKitValue.partnerName
]
