import { useNumberOfFreeServiceMonths } from '../useNumberOfFreeServiceMonths'

/**
 * Returns a text to display a number of free months of service a customer gets
 * Text is used in the promo banners, Buy Box titles on Package pages and in cart
 *
 * @param serviceDurationOnly
 *  - If true, return only a copy with a number of free months (example: "free month" or "2 free months")
 *  - if false, return months of service copy to display in a sentence (example: "month is" or "months are")
 */

export const useMonthsOfFreeServiceText = (
  serviceDurationOnly: boolean,
  isPromoActive: boolean
) => {
  const numberFreeMonthsOfService = useNumberOfFreeServiceMonths(isPromoActive)

  const oneMonthCopy: string = serviceDurationOnly ? 'free month' : 'month is'
  const multipleMonthsCopy: string = serviceDurationOnly
    ? `${oneMonthCopy}s`
    : 'months are'

  return numberFreeMonthsOfService > 1
    ? `${numberFreeMonthsOfService} ${multipleMonthsCopy}`
    : oneMonthCopy
}
