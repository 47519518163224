import React from 'react'
import { NativeVideo } from '../NativeVideo'

export type VideoSectionProps = {
  readonly heading: string
  readonly subtitle: string
  readonly video: {
    readonly captions: string
    readonly posterImage: string
    readonly mp4Link: string
    readonly webmLink: string
  }
  readonly buttonText: string
  readonly to: string
}

export function VideoSection({
  heading,
  subtitle,
  video,
  buttonText,
  to
}: VideoSectionProps) {
  return (
    <div className="flex flex-col items-center" data-component="VideoSection">
      <h2 className="mb-4 md:mb-6 text-center">{heading}</h2>
      <p className="mx-2 mb-2 text-center md:mx-4 md:mb-4 lg:mb-6 max-w-5xl">
        {subtitle}
      </p>
      <NativeVideo
        autoPlay={false}
        captionsSrcUrl={video.captions}
        className="h-auto"
        containerClassName="mx-auto max-w-5xl mb-4 md:mb-6"
        data-component="monitoring-video"
        muted={false}
        poster={video.posterImage}
        preload="none"
        videoSrcUrlMp4={video.mp4Link}
        videoSrcUrlWebm={video.webmLink}
      />
      <a className="btn btn-outlined btn-outlined-primary" href={to}>
        {buttonText}
      </a>
    </div>
  )
}
