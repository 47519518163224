import classNames from 'classnames'

import { ApolloRichText } from '@ecomm/contentful/components'
import { iconsTranslation } from '.'
import type { ApolloIconAndTextSchema } from './schema'

export function ApolloIconAndText({
  className,
  text,
  icon
}: ApolloIconAndTextSchema) {
  const Icon = iconsTranslation[icon]
  return Icon ? (
    <div
      className={classNames(
        'prose-p:m-0 my-3 inline-flex w-full items-center gap-4',
        className
      )}
      data-component={ApolloIconAndText.name}
      data-testid={ApolloIconAndText.name}
    >
      {Icon ? (
        <Icon className="h-6 w-6 shrink-0" data-component="Icon" />
      ) : null}
      {text?.json ? <ApolloRichText json={text.json} /> : null}
    </div>
  ) : null
}
