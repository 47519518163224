import type { Locale } from '@ecomm/utils'
import { z } from 'zod'

import { countrySchema } from './countrySchema'
import { emailSchema } from './emailSchema'
import { ukPhoneSchema, usPhoneSchema } from './phoneSchema'
import type { usPostalCodeSchema } from './postalCodeSchema'
import {
  ukPostalCodeSchema,
  usPostalCodeSchemaExperiment
} from './postalCodeSchema'
import { ukStateSchema, usStateShema } from './stateSchema'
import { streetSchema } from './streetSchema'

type PostalCodeSchema = typeof ukPostalCodeSchema | typeof usPostalCodeSchema
type PhoneSchema = typeof ukPhoneSchema | typeof usPhoneSchema
type StreetSchema = typeof streetSchema
type StateSchema = typeof ukStateSchema | typeof usStateShema

export type CheckoutFormValuesType = z.infer<typeof CheckoutFormSchemaBase> & {
  readonly postalCode: z.infer<PostalCodeSchema>
  readonly phone: z.infer<PhoneSchema>
  readonly state: z.infer<StateSchema>
  readonly streetName: z.infer<StreetSchema>
}

export const isSiteUS = (locale: Locale): boolean => locale === 'en-US'

const CheckoutFormSchemaBase = z.object({
  email: emailSchema,
  offerAndTip: z.boolean(),
  couponCode: z.string().optional(),
  firstName: z
    .string({ required_error: 'First Name is a required field' })
    .trim(),
  lastName: z
    .string({ required_error: 'Last Name is a required field' })
    .trim(),
  additionalStreetInfo: z.string().trim().optional(),
  city: z.string({ required_error: 'City is a required field' }).trim(),
  country: countrySchema,
  sameAddress: z.boolean(),
  shippingOption: z.string(),
  foundInfoThrough: z.string().optional(),
  additionalFoundInfoThrough: z.string().max(64).optional(),
  epsilonAbacusOptIn: z.boolean()
})

export const getCheckoutFormSchema = (
  locale: Locale
): typeof CheckoutFormSchemaBase =>
  CheckoutFormSchemaBase.extend({
    state: isSiteUS(locale) ? usStateShema : ukStateSchema,
    streetName: streetSchema,
    postalCode: isSiteUS(locale)
      ? usPostalCodeSchemaExperiment
      : ukPostalCodeSchema,
    phone: isSiteUS(locale) ? usPhoneSchema : ukPhoneSchema
  })
