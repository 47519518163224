import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { Link } from '@ecomm/framework'
import { Cart } from '@ecomm/shared-icons'
import classNames from 'classnames'
import React from 'react'
import { useTracking } from 'react-tracking'

/*
We have a copy of this Component in shared/components/src/v2/lib as well which
supports data fetched from apollo instead of gatsby data layer. Functionality and
structure is exactly same in both the components, so if you are making any changes
to this, please make corresponding changes to the v2 version of this component too.
We will make v2/ version the main version going forward when we are completely swapped
over to apollo from gatsby data layer.
*/

export default function CartLink({
  quantity,
  className = ''
}: {
  readonly quantity: number
  readonly className?: string
}) {
  const { trackEvent } = useTracking()

  const ariaLabelText =
    quantity === 1 ? `Cart ${quantity} item` : `Cart ${quantity} items`

  return (
    <Link
      aria-label={ariaLabelText}
      className={classNames(
        'hover:text-primary-100 relative inline-flex',
        className
      )}
      data-testid="header-add-to-cart-link"
      onClick={() => {
        trackEvent({
          label: 'Cart',
          event: 'navigation-link',
          eventAction: 'click',
          eventCategory: 'navigation'
        })
        trackNavigationClick({
          action: 'click',
          navElement: 'Cart'
        })
      }}
      to="/cart"
    >
      <span
        className={classNames(
          'bg-primary-100 absolute -right-2.5 -top-2.5 h-5 w-5 items-center justify-center rounded-full text-sm text-white',
          {
            flex: quantity > 0,
            hidden: quantity <= 0
          }
        )}
      >
        {quantity}
      </span>
      <Cart className="h-6 w-6" />
    </Link>
  )
}
