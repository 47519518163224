import { type CartValue, cartRemoveLineItem } from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { voidFn } from '@simplisafe/ewok'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useCallback } from 'react'

import { useSetCart } from '../atoms/useCart'

/**
 * Removes a line item from the cart.
 */
export const useCartRemoveLineItem = (
  lineItemId: string,
  variationId: string
) => {
  const cartId = getCartId()
  const setCart = useSetCart()

  return useCallback(
    (successFn: (t: CartValue) => void) => {
      const fn = async (id: string) => {
        setCart(['set_cart_updating'])
        const cart = await cartRemoveLineItem(id, lineItemId, variationId)()
        pipe(
          cart,
          E.fold(
            err => setCart(['set_cart_error', err]),
            t => {
              setCart(['update_cart', t])
              successFn(t)
            }
          )
        )
      }
      pipe(
        O.fromNullable(cartId),
        O.fold(() => voidFn(), fn)
      )
    },
    [cartId, setCart, lineItemId, variationId]
  )
}
