import { cartGetByQuoteId } from '@ecomm/data-simplisafe-api'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { useCallback } from 'react'

import { useSetCart } from '../atoms/useCart'

/**
 * Gets a cart by quote id and sets it in the cart atom.
 */
export const useRequestCartByQuoteId = () => {
  const setCart = useSetCart()

  return useCallback(
    (
      quoteId: string,
      emailHash: string,
      onFailure?: () => void,
      onSuccess?: () => void
    ) => {
      const fn = async () => {
        const cart = await cartGetByQuoteId(quoteId, emailHash)()
        pipe(
          cart,
          E.fold(
            () => {
              onFailure && onFailure()
            },
            t => {
              onSuccess && onSuccess()
              setCart(['update_cart', t])
            }
          )
        )
      }

      fn()
    },
    [setCart]
  )
}
