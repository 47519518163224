import { trackAppCarousel } from '@ecomm/cdp-tracking'
import {
  ContentfulImage,
  type ContentfulImageSchema
} from '@ecomm/contentful/components'
import { useSwipeMotion } from '@ecomm/shared-hooks'
import { useState } from 'react'

import AppRating, { type AppRatingProps } from './AppRating'
import { useOnTabClick } from './useOnTabClick'

type AppWidgetProps = {
  readonly data: AppWidgetData
}
export type AppWidgetData = {
  readonly tabs: ReadonlyArray<AppWidgetTab>
  readonly title?: JSX.Element | string
  readonly appRatingImages?: AppRatingProps
}

export type AppWidgetTab = {
  readonly tab: string
  readonly title: string
  readonly description?: string
  readonly list: ReadonlyArray<string>
  readonly image?: ContentfulImageSchema
  readonly disclaimer?: ReadonlyArray<string>
}

export function AppWidget({ data }: AppWidgetProps) {
  const onSwipeLeft = () => handleTabClick((activeTab + 1) % tabs.length)
  const onSwipeRight = () =>
    handleTabClick((activeTab - 1 + tabs.length) % tabs.length)
  const touchEvents = useSwipeMotion({
    onSwipeLeft,
    onSwipeRight,
    sensitivity: 120
  })

  const { tabs, title, appRatingImages } = data
  const [activeTab, setActiveTab] = useState<number>(0)

  const onTabClick = useOnTabClick()
  const handleTabClick = (index: number) => {
    setActiveTab(index)
    onTabClick(tabs[index]?.tab || '')
    trackAppCarousel(tabs[index]?.tab.toLowerCase() || '')
  }

  const activeTabData = tabs[activeTab]

  return (
    <div
      className="prose md:prose-md lg:prose-lg relative w-full"
      data-component="AppWidget"
      data-testid="AppWidget"
    >
      <div
        className="absolute h-full w-full"
        data-component="TouchControls"
        {...touchEvents}
      ></div>
      {title ? (
        <h2 className="mb-6 px-4 text-center md:mb-8 md:px-0">{title}</h2>
      ) : null}
      <div className="flex flex-col lg:flex-row-reverse">
        <div className="bg-neutral-light-50 pointer-events-none rounded-t-2xl pt-2 lg:w-5/12 lg:flex-1 lg:rounded-l-none lg:rounded-r-2xl">
          <div className="space-end flex h-full flex-col-reverse">
            {!!activeTabData?.image ? (
              <ContentfulImage
                className="max-h-96 lg:max-h-[34.5rem] object-contain"
                {...activeTabData.image}
                style={{ objectFit: 'contain' }}
              />
            ) : null}
          </div>
        </div>
        <div className="bg-neutral-black flex min-h-[25rem] flex-col rounded-b-2xl p-6 md:h-auto md:min-h-[28.75rem] md:p-8 lg:min-h-[40.5rem] lg:w-7/12 lg:rounded-l-2xl lg:rounded-r-none lg:p-12">
          <div className="mb-4 hidden w-full justify-around gap-6 md:flex md:px-4 lg:mb-6">
            {tabs.map((tab, index) => (
              <button
                className={`cursor-pointer border-0 bg-inherit px-3 pb-2 text-lg text-white ${
                  activeTab === index &&
                  ' border-primary-100 border-x-0 border-b-4 border-t-0 border-solid font-bold '
                }`}
                key={tab.tab}
                onClick={() => handleTabClick(index)}
              >
                <span
                  className={activeTab === index ? 'opacity-100' : 'opacity-95'}
                >
                  {tab.tab}
                </span>
              </button>
            ))}
          </div>
          <div className="pointer-events-none grow">
            <div data-component="AppWidget-headline">
              <h3 className="mb-0 mt-0 text-white">{tabs[activeTab]?.title}</h3>
            </div>
            <div className="text-sm opacity-90 md:text-xl">
              {tabs[activeTab]?.description ? (
                <p className="hidden text-white md:block">
                  {tabs[activeTab]?.description}
                </p>
              ) : null}
              <ul className="my-4 list-disc pl-6 md:pl-8">
                {tabs[activeTab]?.list.map((text, index) => (
                  <li
                    className="text-body-size py-2 font-normal text-white md:py-3"
                    key={index}
                  >
                    {text}
                  </li>
                ))}
              </ul>
              <ul className="m-0 flex list-none flex-col gap-3 p-0 text-xs text-white">
                {activeTabData?.disclaimer?.map((disclaimer, index) => (
                  <li
                    className="list-none text-xs font-normal text-white"
                    data-component={`AppWidget-disclaimer-${index}`}
                    key={index}
                  >
                    {disclaimer}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {appRatingImages ? (
            <div className="relative bottom-0 hidden lg:block">
              <AppRating {...appRatingImages} />
            </div>
          ) : null}
        </div>
        <div className="z-10 my-4 flex flex-row justify-center gap-4 md:hidden">
          {tabs.map((tab, index) => (
            <button
              aria-label={`Slide ${index + 1} of ${tabs.length}`}
              className={`h-2 w-2 cursor-pointer rounded-full border-0 p-0 md:h-3 md:w-3 ${
                activeTab === index ? 'bg-neutral-black' : 'bg-[#D9D9D9]'
              }`}
              key={tab.tab}
              onClick={() => setActiveTab(index)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
