import { useFetchNinetailedPromo } from '@ecomm/promotions-hooks'
import type { Locale } from '@ecomm/utils'

/**
 * Wrapper component for the useFetchNinetailedPromo hook
 *
 * Used within WrapPageElement to fetch the ninetailed promo within the context of other providers
 *
 * Will not be necessary once off of Gatsby
 */
export function NinetailedPromotionWrapper({
  locale
}: {
  readonly locale: Locale
}) {
  useFetchNinetailedPromo(locale, 'promo')
  return null
}
