import { cartUpdatePartnerNumber } from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { voidFn } from '@simplisafe/ewok'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useCallback } from 'react'

import { useSetCart } from '../atoms/useCart'

/**
 * Update the partner number on the cart
 */
export const useCartUpdatePartnerNumber = () => {
  const cartId = getCartId()
  const setCart = useSetCart()

  return useCallback(
    (
      partnerMemberNumber: string,
      onFailure: () => void,
      onSuccess: () => void
    ) => {
      const fn = async (id: string) => {
        const cart = await cartUpdatePartnerNumber(id, partnerMemberNumber)()
        pipe(
          cart,
          E.fold(
            () => onFailure(),
            t => {
              setCart(['update_cart', t])
              onSuccess()
            }
          )
        )
      }
      pipe(
        O.fromNullable(cartId),
        O.fold(() => voidFn(), fn)
      )
    },
    [cartId, setCart]
  )
}
