import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export function useTrackingCouponApplyClick() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (promoCode: string, isSuccess: boolean) => {
      trackEvent({
        action: 'click',
        event: 'coupon_apply_button_click',
        response: isSuccess ? 'Valid' : 'Invalid',
        promoCode
      })
    },
    [trackEvent]
  )
}
