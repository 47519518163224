import { useMediaQuery } from '@ecomm/shared-hooks'
import type { ReactNode } from 'react'

import Carousel from './Carousel'
import Review from './Review'

export type UserReview = {
  readonly name: string
  readonly text: string
  readonly showStars?: boolean
}

export type UserReviewsProps = {
  readonly reviews: readonly UserReview[]
  readonly title?: ReactNode
  readonly showBanner?: boolean
}

const defaultTitle = (
  <>
    Over 4 million people trust SimpliSafe
    <sup className="text-base md:text-lg lg:text-xl">®</sup> with their security
  </>
)

export function UserReviews({
  reviews,
  title = defaultTitle,
  showBanner = true
}: UserReviewsProps) {
  const isMobile = !useMediaQuery('TabletAndUp')

  const firstReviewIndex = showBanner ? 1 : 0

  return (
    <div
      className="flex flex-col"
      data-component="UserReviews"
      data-testid="UserReviews"
    >
      {isMobile ? (
        <>
          <h2 className="mb-6 text-center">{title}</h2>
          <div className="block">
            <Carousel items={reviews} />
          </div>
        </>
      ) : (
        <>
          {showBanner ? (
            <>
              <h2 className="text-center mb-8 lg:hidden">{title}</h2>
              <div className="block">
                <div className="bg-complementary-green-100 flex w-full flex-row items-center gap-9 rounded-2xl px-16 py-10 lg:py-11">
                  <div className="hidden w-2/5 lg:block">
                    <h2 className="m-0 text-white">{title}</h2>
                  </div>
                  <div className="m-0 w-full rounded-2xl lg:w-3/5">
                    {reviews[0] ? <Review type="big" {...reviews[0]} /> : null}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <h2 className="text-center mb-8">{title}</h2>
          )}

          <div className="flex py-6">
            {reviews.slice(firstReviewIndex).map((review, index) => (
              <div
                className="border-neutral-light-100 flex w-1/3 flex-col border-0 border-r border-solid px-10 text-center last:border-0"
                key={index}
              >
                <Review type="small" {...review} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}
