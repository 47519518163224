import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export function useTrackOdmonStandardMonitoringLinkClick() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(() => {
    trackEvent({
      event: 'standard-monitoring-link-click'
    })
  }, [trackEvent])
}
