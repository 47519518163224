import { type CartValue, cartAssociateD2DVid } from '@ecomm/data-simplisafe-api'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { useCallback } from 'react'
import { useSetCart } from '../atoms/useCart'

export function useCartAssociateD2DVid() {
  const setCart = useSetCart()

  return useCallback(
    (
      cartId: string,
      agentId: string,
      onFailure?: () => void,
      onSuccess?: (cart: CartValue) => void
    ) => {
      const fn = async () => {
        const cart = await cartAssociateD2DVid(cartId, agentId)()
        pipe(
          cart,
          E.fold(
            () => {
              onFailure && onFailure()
            },
            t => {
              onSuccess && onSuccess(t)
              setCart(['update_cart', t])
            }
          )
        )
      }
      fn()
    },
    [setCart]
  )
}
