import type { Locale } from '@ecomm/utils'
import type { LineItem } from '@simplisafe/eis-commercetools-carts'
import type { Product as CTProduct } from '@simplisafe/ss-ecomm-data/products'
import { selectProducts } from '@simplisafe/ss-ecomm-data/redux/select'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useSelector } from 'react-redux'
import type { LineItemWithBrand } from './types'

export function filterNil<T>(t: T | undefined): t is T {
  return t !== undefined
}

export function useCTProducts(
  lineItems: readonly LineItem[]
): readonly CTProduct[] {
  return useSelector(
    selectProducts(lineItems.map(item => item.sku).filter(filterNil))
  ).cata(
    () => [],
    products => products
  )
}

/**
 * Temporary logic to populate the brand field using Redux product data.
 * We need the brand field for analytics purposes, but this is meant to be temporary
 * until we revisit the data pipeline to get rid of the field completely.
 *
 * src: https://github.com/simplisafe/ss-ecomm-frontend/blob/812ebffc0aa1d902107c9115e3008e7c3efdf033/apps/legacy/src/util/analytics/index.ts#L643
 */
export function populateBrand(
  lineItems: readonly LineItem[],
  ctProducts: readonly CTProduct[],
  locale: Locale
): readonly LineItemWithBrand[] {
  return lineItems.map(item => {
    const isCustomSystem = item.isCustomSystem
    const isPackage = item.packageItems.length > 0

    const brand = pipe(
      O.fromNullable(ctProducts.find(p => p.sku === item.sku)),
      O.map(p => p.name),
      O.chain<CTProduct['name'], string>(name => O.fromNullable(name[locale])),
      O.toUndefined
    )

    return {
      brand: isPackage ? (isCustomSystem ? 'bms' : brand) : undefined,
      ...item
    }
  })
}
