import { iconTypes } from '@ecomm/shared-icons'
import { type TypeOf, z } from '@simplisafe/ewok'

const InfoIconSchema = z.object({
  description: z.union([z.string(), z.array(z.string())]),
  modalLocation: z.union([
    z.literal('battery-backup'),
    z.literal('fast-protect'),
    z.literal('help-stop-crime'),
    z.literal('home-integration'),
    z.literal('home-protection'),
    z.literal('police-response'),
    z.literal('prevent-crime'),
    z.literal('professional-monitoring')
  ]),
  title: z.string()
})

const BaseComparisonTableRow = z.object({
  feature: z.string(),
  infoIcon: InfoIconSchema.optional()
})

const ComparisonTableRowWithValues = z.intersection(
  BaseComparisonTableRow,
  z.object({
    values: z.array(z.union([z.boolean(), z.string()])),
    ratings: z.never().optional()
  })
)

const ComparisonTableRowWithRating = z.intersection(
  BaseComparisonTableRow,
  z.object({
    ratings: z.array(z.number()),
    values: z.never().optional()
  })
)

const ComparisonTableRow = z.union([
  ComparisonTableRowWithRating,
  ComparisonTableRowWithValues
])

export const comparisonTableSchema = z.object({
  comparisonTable: z.object({
    content: z.object({
      header: z.object({
        icons: z.array(z.enum(iconTypes)),
        titles: z.array(z.string()),
        competitors: z.array(z.string())
      }),
      footer: z.array(z.string()),
      body: z.array(ComparisonTableRow)
    })
  })
})

export type ComparisonTableType = TypeOf<typeof comparisonTableSchema>
