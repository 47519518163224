import type { AccoladesProps } from '.'

const createMockRichHeadingText = (text: string, headingValue: number) => {
  return `{"nodeType":"document","data":{},"content":[{"nodeType":"heading-${headingValue}","data":{},"content":[{"nodeType":"text","value":"${text}","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"","marks":[],"data":{}}]}]}`
}

export const title = createMockRichHeadingText(
  'Trusted by millions every day',
  2
)

export const monitoringAccoladesData: readonly AccoladesProps[] = [
  {
    title: 'Lifetime warranty on all system hardware.',
    disclaimerText: 'With select professional monitoring plans.'
  },
  {
    title: 'A commitment to the security of your privacy and data.',
    disclaimerText: 'Read our privacy promise',
    disclaimerLink: '/privacy'
  },
  {
    title: 'Securing homes and businesses nationwide since 2006.'
  }
]
