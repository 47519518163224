import { useCurrentPromo } from '../useCurrentPromo'

const regexMonitoringDiscount = /(?:{{monitoring_discount}})/g

/**
 * Will replace any {{monitoring_discount}} occurrences in text with the usePromoWithMonitoringDiscountText
 * return value if displayMonitoringDiscount is true. Otherwise it will use usePromoDiscountText return value
 * and if that resolves to None, replace them with defaultsTo
 *
 * @param text        string
 * @param defaultsTo  string
 * @returns           string
 */
export const usePlaceholderForMonitoringDiscount = (
  text: string,
  defaultsTo = ''
) => {
  const { discountText: placeholderText } = useCurrentPromo()

  return text.replace(regexMonitoringDiscount, placeholderText ?? defaultsTo)
}
