/**
 * Tracking data object which will be passed through react-tracking
 *
 * Note: TrackingEvent is deprecated; migrate entries in it closer to their origin. Any new entries
 * should also be stored closer to their origin.
 *
 * The goal is for a global TrackingEvent to go away and instead this is
 * TrackingData<T extends string = never> and callers of useTracking<TrackingData>() can instead
 * call useTracking<TrackingData<MySpecificEvents>>(), or just call useTracking<TrackingData>()
 * for when there is no event param.
 */
export type TrackingData<T extends string = TrackingEvent> = {
  // Event type to be tracked
  readonly event?: T
  readonly errorMessage?: Error
  // TODO These event fields are used by the frontend, but could use more info about what they're for.
  readonly eventCategory?: string
  readonly eventLabel?: string
  readonly eventAction?: string

  // Currently used gtag attributes
  readonly action?: string
  readonly category?: string
  readonly label?: string
  readonly site?: string
  readonly package?: string

  readonly environment?: 'development' | 'prd' | 'qa' | 'stg' // deploy env for the site

  // Page-level tracking data
  readonly pagePath?: string
  readonly pageTitle?: string
  readonly queryString?: string
  readonly referrer?: string
  readonly userId?: number | string
  readonly visitorId?: number | string
  readonly firstVisitedUrl?: string | null

  // Component-level tracking data
  readonly appSection?: AppSection
  readonly carouselTab?: number
  readonly faqPosition?: number
  readonly isFirstWizardStep?: boolean
  readonly selectedPaymentOption?:
    | 'affirm'
    | 'new card'
    | 'saved card'
    | 'paypal'
  readonly errorID?: number | string // used to track payment form error codes
  readonly sensor?: string
  readonly carouselName?: string
  readonly modalLocation?:
    | 'battery-backup'
    | 'fast-protect'
    | 'help-stop-crime'
    | 'home-integration'
    | 'home-protection'
    | 'police-response'
    | 'prevent-crime'
    | 'professional-monitoring'
  readonly scoutImagePosition?: number
  readonly video_percent?: string
  readonly video_provider?: string
  readonly video_status?: string
  readonly video_title?: string

  // Ecommerce tracking data
  readonly ecommerce?: Ecommerce
  readonly transactionId?: string
  readonly transactionTotal?: number // cart total
  readonly userData?: UserData
  readonly orderTotal?: number // cart subtotal
  readonly transactionCoupon?: string
  readonly transactionDiscount?: string
  readonly facebookEventId?: string

  readonly cartId?: string

  // Braze-specific tracking data
  // TODO The types in this library are supposed to be agnostic of the platform being tracked to, but adding these in the interest of time for ECP-1662
  readonly brazePopupWizardResponseData?: ReadonlyArray<BrazeQuoteWizardResponse>
  readonly brazeWizardWithRecsResponseData?: ReadonlyArray<BrazeQuoteWizardResponse>

  readonly addRemove?: QuantityPickerAction

  // Monitoring plan selected plan tracking
  readonly monitoringSelected?: QuantityPickerAction

  readonly scorePropertyCrime?: number
  readonly scoreViolentCrime?: number
  readonly attribution?: 'reject' | 'submit'
  readonly checkboxLead?: 'checked' | 'unchecked'
  readonly checkboxShipping?: 'checked' | 'unchecked'
  readonly shipping?: string
  readonly placement?:
    | 'nav'
    | 'atf'
    | 'cards'
    | 'chart'
    | 'modal'
    | 'bms'
    | 'pdp'
    | ODMONPlacement
  readonly question?: string | number
  readonly link?: string

  // ODMON Quiz
  readonly selection?: number | string
  readonly page?: ODMONQuizScreens
  readonly shields?: number
  readonly kits?: number
  readonly plan?: string | ODMONPlan
  readonly platform?: string
  readonly kit?: number
  readonly response?: string
  readonly promoCode?: string

  // Partner data
  readonly partnerName?: string
  readonly partnerGroup?: string
  readonly partnerUrl?: string

  // BMS tracking data
  readonly section?: string

  // Outdoor camera warning modal
  readonly location?: string

  // Paypal
  readonly status?: 'failure' | 'success'
}

/**
 * Event types for TrackingData
 *
 * @deprecated
 *
 * Stop putting use-specific strings in this common file; instead create a local type
 * and supply that for the generic.
 *
 * Example:
 *
 * type OdmonTrackingEvent = 'odmon-event-1' | 'odmon-event-2'
 *
 * const { trackEvent } = useTracking<TrackingData<OdmonTrackingEvent>>()
 *
 * trackEvent({ event: 'odmon-event-1' })
 * trackEvent({ event: 'odmon-event-2' })
 */
export type TrackingEvent =
  | 'addRemoveEvent'
  | 'addToCart'
  | 'affirm_informational_click'
  | 'affirmCheckoutClick'
  | 'agentEvent'
  | 'alarm-sensor-generation-click'
  | 'bms_decrease'
  | 'bms_increase'
  | 'bms_shield_camera_accesories_expand'
  | 'bms_tooltip'
  | 'button-click-agents-play-video'
  | 'button-click-build-a-system-guarantee-component'
  | 'button-click-call-expert-component'
  | 'button-click-chat-expert-component'
  | 'button-click-how-products-work'
  | 'button-click-stop-crime-monitoring'
  | 'button-click-take-our-quiz-guarantee-component'
  | 'buttonClick'
  | 'carousel-click-app-component'
  | 'cart-change-plan'
  | 'cart-continue-shopping'
  | 'coupon_apply_button_click'
  | 'coupon_change_add_code_click'
  | 'checkout_logo_click'
  | 'checkout_progress_steps'
  | 'click-monitoring-alarm-carousel'
  | 'clickTooltip'
  | 'closePopover'
  | 'closeWidgetPostEngagement'
  | 'closeWidgetWithoutEngaging'
  | 'continue-to-checkout-button'
  | 'crime_score'
  | 'eec.add'
  | 'eec.cart'
  | 'eec.details'
  | 'eec.remove'
  | 'eec.payment'
  | 'eec.shipping'
  | 'eec.transaction'
  | 'faq-expand'
  | 'html5_video'
  | 'impression'
  | 'link-click-stop-crime-camera'
  | 'linkClick'
  | 'liveChatEnabled'
  | 'meet-the-system'
  | 'modal-click-comparison-chart'
  | 'monitoring-compare-plans'
  | 'monitoring-cta'
  | 'monitoring-show-other-plans'
  | 'monitoringToggleEvent'
  | 'navigation-link'
  | 'non_bounce'
  | 'onChange'
  | 'openEvent'
  | 'package-detail-tooltip'
  | 'package-sensor-info'
  | 'pageLoad'
  | 'paymentConfirmation'
  | 'paymentFormCreError'
  | 'paymentFormError'
  | 'paymentInfo'
  | 'payment-option-toggle'
  | 'productDetails'
  | 'proSetupCheckbox'
  | 'purchase'
  | 'purchaseSystem'
  | 'quote-wizard-open'
  | 'quoteWizardQuestion1'
  | 'quoteWizardQuestion2'
  | 'quoteWizardQuestion3'
  | 'quoteWizardQuestion4'
  | 'removeFromCart'
  | 'resetObjects'
  | 'scout-image-pagination'
  | 'scout_video_play'
  | 'shippingInfo'
  | 'shop-now'
  | 'shop-refurb-see-details'
  | 'shop-see-details'
  | 'shopNow'
  | 'submit'
  | 'submitLead'
  | 'system-click'
  | 'userInAutoActivation'
  | 'logged_in'
  | 'submitOrderFailed'
  | 'navigation-link'
  | 'link-click-privacy-policy-deep-dive-component'
  | 'submitOrderFailed'
  | 'epsilonAbacusToggle'
  | 'shipping_address_modal_validation_opened'
  | 'confirm_shipping_address_validation'
  | 'button-click-get-recommendation-secured-systems-component'
  | 'button-click-bms-secured-systems-component'
  | 'link-click-privacy-footer'
  | 'continue-payment-click'
  | 'summary-details'
  | 'checkout-click-to-login'
  | 'submit-order-click'
  | 'edit-shipping'
  | 'terms-conditions'
  | 'continue-to-payment-click-failure'
  | 'purchaseSystemConfirmation'
  | 'get-early-access-click'
  | 'odmon-faq-link-click'
  | 'odmon-faq-click'
  | 'choice-flow-back-click'
  | 'choice-flow-next-click'
  | 'choice-flow-close-modal'
  | 'odmon-add-to-cart'
  | 'odmon-app-download-click'
  | 'odmon-order-confirmation'
  | 'odmon-beta-pre-auth-visit'
  | 'compare-plan-click'
  | 'return-to-cart'
  | 'skip-this-step'
  | 'skip-anyway'
  | 'ccm-see-details'
  | 'standard-monitoring-link-click'
  | 'self-monitoring-link-click'
  | 'terms-of-service-click'
  | 'atc-plan-selection'
  | 'monitoring-selection-click'
  | 'compare-plans-click'
  | 'atc-plan-selection'
  | 'add-monitoring'
  | 'remove-monitoring'
  | 'plan-warning-modal-close'
  | 'plan-warning-modal-view'
  | 'plan-warning-modal-continue'
  | 'plan-warning-modal-return-to-cart'
  | 'plan-warning-banner-close'
  | 'see-details-plan-selection'
  | 'shop-now-empty-cart'
  | 'plan-choice-dropdown-click'

/**
 * Different parts of the webapp for TrackingData
 */
export type AppSection =
  | 'CrimeInTheUS'
  | 'GuidedSystemBuilderRecommendation'
  | 'header'
  | 'liveChat'
  | 'newsletterSubmit'
  | 'paymentForm'
  | 'promoSubmit'
  | 'quoteWizard'
  | 'wizardEmailSubmit'
  | 'wizardFinal'
  | 'wizardMultipleChoice'

// add/remove are used for quantity pickers, and check/unchecked are used for checkboxes
export type QuantityPickerAction = 'add' | 'checked' | 'remove' | 'unchecked'
export type MonitoringSelectAction = 'add-plan' | 'no-plan'

// Ecommerce type for TrackingData
export type Ecommerce = {
  readonly add?: Products
  readonly click?: Products
  readonly remove?: Products
  readonly detail?: Products
  readonly checkout?: Products
  readonly purchase?: Products
  readonly currencyCode?: string
}

// User Data type for tracking user information
export type UserData = {
  readonly email?: string
  readonly firstName?: string
  readonly lastName?: string
}

// Products type for TrackingData
export type Products = {
  readonly products?: ReadonlyArray<Product>
}

// product detail type for TrackingData
export type Product = {
  readonly id?: string
  readonly name?: string
  readonly price?: number
  readonly quantity?: number
  readonly variant?: string
  readonly category?: string
  readonly list?: string
  readonly brand?: string
}

export type PackageItem = {
  readonly product: {
    readonly sku: string
    readonly name: string
  }
  readonly quantity: number
}

export type BrazeQuoteWizardResponse = {
  readonly answer?: string | null
  readonly question?: string
}

export enum ODMONQuizScreens {
  INTRO = 'intro',
  EXISTING_OUTDOOR_CAMERAS = 'current cams',
  PURCHASE_CAMERA = 'purchase cams',
  PURCHASE_KITS = 'purchase kits',
  PURCHASE_PLAN = 'plan selection',
  REVIEW = 'review order'
}

export type ODMONPlan = 'essential' | 'pro' | 'complete' | 'standard' | 'self'
export type ODMONPlacement = 'page' | 'standard-modal' | 'self-modal'
