import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export function useTrackingCouponAddClick() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (hasCodeInCart: boolean) => {
      trackEvent({
        action: 'click',
        event: 'coupon_change_add_code_click',
        label: hasCodeInCart ? 'Change' : 'Add'
      })
    },
    [trackEvent]
  )
}
