import jwtDecode from 'jwt-decode'

function readCookie(cookieKey) {
  const documentCookieMap = document.cookie
    .split(';')
    .map(v => v.split('='))
    .reduce((acc, v) => {
      acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim())
      return acc
    }, {})

  return documentCookieMap[cookieKey]
}

export function getVisitorId() {
  const atat = readCookie('atat_v2')
  const decoded = atat ? jwtDecode(atat) : {}
  return decoded['sub'] || ''
}
