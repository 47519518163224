import { experimentTemplateSchema } from '@ecomm/shared-ninetailed'
import { z } from 'zod'

const guidedSystemBuilderSchemaDraft = z.object({
  __typename: z.literal('ContentfulQuoteWizard').optional(),
  id: z.string(),
  contentful_id: z.string().optional(),
  jebbitUrl: z.string().optional(),
  type: z.enum([
    'embedded',
    'floating',
    'guarantee',
    'hero',
    'monitoring_component',
    'popup',
    'nav'
  ]),
  buttonText: z.string().nullish()
})

const experimentSchema = experimentTemplateSchema.extend({
  id: z.string(),
  variants: z.array(guidedSystemBuilderSchemaDraft).default([]),
  audience: z
    .object({
      id: z.string(),
      name: z.string()
    })
    .nullish()
})

// For ecp-12406, the duplex section, we should probably just copy this if it's used in other places
export const guidedSystemBuilderSchema = guidedSystemBuilderSchemaDraft.extend({
  nt_experiences: z.preprocess(
    // Convert null to undefined so .default([]) will work for null values as well
    value => (value === null ? undefined : value),
    z.array(experimentSchema).default([])
  )
})

export type GuidedSystemBuilderSchema = z.TypeOf<
  typeof guidedSystemBuilderSchema
>
