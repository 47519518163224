import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { contentfulRichTextSchema } from '@ecomm/shared-components'
import { type TypeOf, z } from '@simplisafe/ewok'

import { Locale } from '../../hooks/useLocalizedData'

const iconTextSchema = z.enum([
  'professionalMonitoring',
  'shield',
  'shippingTruck'
])

const scoutProductPageProductHeroSchema = z.object({
  description: contentfulRichTextSchema,
  features: z.array(iconTextSchema),
  productName: z.string(),
  productImages: z.array(gatsbyImageSchema),
  buttonText: z.string(),
  buttonLink: z.string(),
  showNewFlag: z.boolean(),
  productSku: z.string(),
  className: z.string()
})

export const schema = {
  [Locale.EnGB]: scoutProductPageProductHeroSchema,
  [Locale.EnUS]: scoutProductPageProductHeroSchema
}

export type IconTextSchema = TypeOf<typeof iconTextSchema>
