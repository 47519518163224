/** @see https://github.com/ninetailed-inc/ninetailed-examples/blob/demo/rudderstack/marketing-contentful-next/plugins/rudderstack.ts */

const {
  NinetailedAnalyticsPlugin
} = require('@ninetailed/experience.js-plugin-analytics')
const { template } = require('@ninetailed/experience.js-shared')
const { getVisitorId } = require('./utils/getVisitorId')

const TEMPLATE_OPTIONS = {
  interpolate: /{{([\s\S]+?)}}/g
}

const isRudderstackInitialized = () => {
  return (
    typeof window === 'object' &&
    'rudderanalytics' in window &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    typeof window.rudderanalytics !== 'undefined'
  )
}

class NinetailedRudderstackPlugin extends NinetailedAnalyticsPlugin {
  name = 'ninetailed:rudderstack'

  constructor(options = {}) {
    super({
      ...options.template,
      event: 'nt_experience',
      ninetailed_variant: '{{selectedVariantSelector}}',
      ninetailed_experience: '{{experience.id}}',
      ninetailed_experience_name: '{{experience.name}}',
      ninetailed_audience: '{{audience.id}}',
      ninetailed_component: '{{selectedVariant.id}}'
    })
  }

  // eslint-disable-next-line
  async onTrackExperience(properties, hasSeenExperienceEventPayload) {
    if (!isRudderstackInitialized()) {
      return
    }

    const { event, ...trackEventProperties } = hasSeenExperienceEventPayload

    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    window.rudderanalytics.track(event, {
      ...trackEventProperties,
      vid: getVisitorId()
    })
  }

  // eslint-disable-next-line
  async onTrackComponent(properties) {
    if (!isRudderstackInitialized()) {
      return
    }

    const { variant, audience, isPersonalized } = properties
    const event = template(
      this.options.eventNameTemplate ||
        'Has Seen Component - Audience:{{ audience.id }}',
      { variant, audience, isPersonalized },
      TEMPLATE_OPTIONS.interpolate
    )
    const categoryProperty = template(
      this.options.categoryPropertyTemplate || 'Ninetailed',
      {
        variant,
        component: variant,
        audience,
        isPersonalized
      },
      TEMPLATE_OPTIONS.interpolate
    )
    const componentProperty = template(
      this.options.componentPropertyTemplate || '{{ component.id }}',
      {
        variant,
        component: variant,
        audience,
        isPersonalized
      },
      TEMPLATE_OPTIONS.interpolate
    )
    const audienceProperty = template(
      this.options.audiencePropertyTemplate || '{{ audience.id }}',
      {
        variant,
        component: variant,
        audience,
        isPersonalized
      },
      TEMPLATE_OPTIONS.interpolate
    )

    // eslint-disable-next-line
    // @ts-ignore
    // eslint-disable-next-line
    window.rudderanalytics.track(event, {
      category: categoryProperty,
      component: componentProperty,
      audience: audienceProperty,
      isPersonalized,
      vid: getVisitorId()
    })
  }
}

exports.default = NinetailedRudderstackPlugin
