import { cartAddUserDiscountCode } from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { voidFn } from '@simplisafe/ewok'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useCallback } from 'react'

import { useSetCart } from '../atoms/useCart'

/**
 * Adds a discount code to cart, removing other existing discount codes.
 * An invalid or nonexistent discount code will cause the onFailure callback to be called.
 */
export const useCartAddUserDiscount = (variationId: string) => {
  const cartId = getCartId()
  const setCart = useSetCart()

  return useCallback(
    (discountCode: string, onFailure: () => void, onSuccess: () => void) => {
      const fn = async (id: string) => {
        const cart = await cartAddUserDiscountCode(
          id,
          discountCode,
          variationId
        )()
        pipe(
          cart,
          E.fold(
            () => onFailure(),
            t => {
              setCart(['update_cart', t])
              onSuccess()
            }
          )
        )
      }
      pipe(
        O.fromNullable(cartId),
        O.fold(() => voidFn(), fn)
      )
    },
    [cartId, setCart]
  )
}
