import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

type ButtonLabel = 'Change address' | 'Confirm verified address'

export const useTrackingConfirmAddressShippingModalValidation = () => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (buttonLabel: ButtonLabel) => {
      trackEvent({
        action: 'confirm_shipping_address',
        category: 'checkout',
        event: 'confirm_shipping_address_validation',
        label: buttonLabel
      })
    },
    [trackEvent]
  )
}
