import { useLocale } from '@ecomm/data-hooks'
import { voidFn } from '@simplisafe/ewok'
import { atom, useAtom } from 'jotai'
import { useEffect } from 'react'

/**
 * This is stored in an atom so that it only impacts the first page load.
 * If we stored this in a regular state, it would reset on every page change and elements would fade back in.
 */
const visibleAtom = atom<ReadonlySet<string>>(new Set<string>())

/**
 * Returns some CSS to set the opacity of an element to 0 until 100ms after React runs `useEffect` hooks.
 * Uses  css transitions to make it not pop in.
 *
 * By using opacity we can have the component mount and fetch any data it might need,
 * but by hiding it we can limit visual swaps of content for things locked behind an A/B test or other `useEffect` hooks.
 *
 * This is intended only for client side data. This won't be needed for data coming from loaders.
 */
export const useHideUntilMounted = (name: string) => {
  const [visible, setVisible] = useAtom(visibleAtom)

  const locale = useLocale()

  useEffect(() => {
    if (!visible.has(name)) {
      const timeout = setTimeout(() => {
        setVisible(prev => new Set([...prev, name]))
      }, 500)
      return () => clearTimeout(timeout)
    } else {
      return voidFn
    }
  }, [name, setVisible, visible])

  // we're assuming that the UK doesn't do any experiments
  // this will have to change if they add personalization or run experiments.
  return locale === 'en-US'
    ? `transition duration-400 ${visible.has(name) ? 'opacity-100' : 'opacity-0'}`
    : ''
}
