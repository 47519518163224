import type { DocumentNode } from '@apollo/client/index.js'
import { devThrowError } from '@ecomm/error-handling'
import { voidFn } from '@simplisafe/ewok'

/**
 * Looks at a graphql query and validates that it has the required variables for `locale` and `preview`.
 */
export const validateQuery = (query: DocumentNode) => {
  const operation = query?.definitions.filter(t => {
    return t.kind === 'OperationDefinition'
  })[0]

  const name =
    operation?.kind === 'OperationDefinition' ? operation.name : 'unknown query'

  const variables =
    operation?.kind === 'OperationDefinition'
      ? operation.variableDefinitions?.map(t => {
          return t.variable.name.value
        })
      : []

  if (operation && variables) {
    return ['preview', 'locale'].forEach(t => {
      if (variables?.includes(t)) {
        return voidFn()
      } else {
        return devThrowError(
          Error(`Query is missing a required variable: ${t}`)
        )
      }
    })
  } else if (operation) {
    // this else branch isn't really ever possible to hit, but handling the error just in case.
    return devThrowError(
      Error(`Query ${name} is missing required variables: locale and preview`)
    )
  } else {
    // This is when the query is just a fragment
    return voidFn()
  }
}
