import type {
  ApolloClient,
  NormalizedCacheObject
} from '@apollo/client/index.js'
import * as Json from 'fp-ts/lib/Json'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

export const domId = 'apollo-state'
export const domAttribute = 'data-apollostate'

/**
 * Read a cache that has been written to the DOM during SSG/SSR.
 */
export const readCacheFromDom = (): NormalizedCacheObject =>
  globalThis.document
    ? pipe(
        `${globalThis.document.querySelector(`#${domId}`)?.getAttribute(domAttribute)}`,
        Json.parse, // returns an Either<Error, Json>
        O.fromEither,
        O.chain(O.fromNullable),
        O.getOrElse(() => ({}))
      )
    : {}

/**
 * Create a dom node that contains the cache for Apollo.
 */
export const createDomCache = (client: ApolloClient<NormalizedCacheObject>) => {
  const state = JSON.stringify(client.extract()).replaceAll(
    '<',
    String.raw`\u003C`
  )
  return <div data-apollostate={state} id={domId} />
}
