import { gql } from '@apollo/client/index.js'
import { useContentful } from '@ecomm/shared-apollo'
import { usePhoneAndMonitoringInNavExperience } from '@ecomm/shared-ninetailed-phone'
import { footerSchema } from './footerSchema'
import { FOOTER_FRAGMENT } from './footer_fragment'

export const FOOTER_QUERY = gql`
  ${FOOTER_FRAGMENT}
  query FOOTER_QUERY($preview: Boolean!, $locale: String!, $id: String!) {
    footer(id: $id, preview: $preview, locale: $locale) {
      ...footer
    }
  }
`

/**
 * ONLY FOR GATSBY TEMPLATE FILES!
 */
export const useFooterQuery = (id: string) => {
  const phoneInNavExperienceFooterId = '5AcrNqDwFWOpPA9TfbslCP'

  const isPhoneInNavVariant = usePhoneAndMonitoringInNavExperience().isVariant

  const { data: defaultFooterData } = useContentful(FOOTER_QUERY, { id })

  const { data: phoneInNavExperienceFooterData } = useContentful(FOOTER_QUERY, {
    id: phoneInNavExperienceFooterId
  })

  const footerData = isPhoneInNavVariant
    ? phoneInNavExperienceFooterData
    : defaultFooterData

  return footerSchema.parse(footerData['footer'], {
    path: ['useFooterQuery', 'footer']
  })
}
