import type { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { useCartDiscountCode } from '@ecomm/data-cart'
import { useLocale } from '@ecomm/data-hooks'
import type { LeadGenResponseProps } from '@ecomm/data-leads'
import { logError } from '@ecomm/error-handling'
import {
  COOKIE_LEAD_DATA,
  setCookie,
  useYodaValue
} from '@ecomm/shared-cookies'
import { type MaybeT, voidFn } from '@simplisafe/ewok'
import { leadGenUpdate } from '@simplisafe/ss-ecomm-data/leads/quoteWizard'
import {
  type LeadGenCaptureParams,
  cookiesOption,
  leadGenCapture,
  leadGenUnsub
} from '@simplisafe/ss-ecomm-data/simplisafe'
import { useCallback, useContext } from 'react'

import { ReferrerUrlContext } from '../../TrackingProvider/useReferrerUrl'
import { handleBrazeTrackingEvent } from '../../braze'
import { useTrackBrazeDetails } from '../../braze/lib/useTrackBrazeDetails'
import { fbTrackLeadCreated } from '../../facebook'
import { useLeadGenCaptureV2 } from '../../useLeadGenCaptureV2'
import { useTrackingSubmitLead } from '../../useTrackingSubmitLead'
import { useSendCartToBraze } from './useSendCartToBraze'

/**
 * A port of https://github.com/simplisafe/ss-ecomm-frontend/blob/main/libs/checkout/utils/src/shipping/captureLead.ts
 * with some light refactoring and relies on Jotai cart instead of Redux cart.
 */
export function useLeadGenCapture() {
  const locale = useLocale()

  const { channel } = useContext(ReferrerUrlContext)

  const trackSubmitLead = useTrackingSubmitLead()

  const discountCode = useCartDiscountCode()

  const sendCartToBraze = useSendCartToBraze()

  const yoda = useYodaValue()
  const loginSuccess = yoda !== null

  const leadGenCaptureV2 = useLeadGenCaptureV2()

  const trackBrazeDetails = useTrackBrazeDetails()

  return useCallback(
    (formValues: CheckoutFormValuesType) => {
      const handleCaptureResponse = async (res: LeadGenResponseProps) => {
        res.email && (await fbTrackLeadCreated(res.email))

        locale === 'en-US' &&
          leadGenUpdate({
            leadId: Number(res.leadId),
            responses: {
              name: `${formValues.firstName} ${formValues.lastName}`,
              phone: formValues.phone
            }
          })(() =>
            logError(
              Error('something went wrong while trying to update lead profile')
            )
          )(voidFn)
      }

      const handleSuccess = (res: MaybeT<LeadGenResponseProps>) => {
        const response = res.orUndefined()

        const isFirstCreation = response?.message?.includes('created')
        // Always cookie lead data and pass data to Braze
        setCookie(COOKIE_LEAD_DATA, response, cookiesOption)
        handleBrazeTrackingEvent(response, channel, isFirstCreation)
          .then(() => {
            leadGenCaptureV2({
              source: 'checkout',
              siteMetadata: {
                sourceType: 'cart',
                promoOffer: discountCode ?? undefined
              },
              sourceDetail: 'exit_intent',
              email: formValues.email
            })
          })
          .catch(logError)
        trackBrazeDetails()

        // we track lead when offerAndTip is true AND user is not logged in
        const shouldTrackLeadEvent = formValues.offerAndTip && !loginSuccess
        shouldTrackLeadEvent && trackSubmitLead()

        // we unsubscribe when offerAndTip is false AND user is not logged in
        const shouldUnsubscribe = !formValues.offerAndTip && !loginSuccess
        shouldUnsubscribe &&
          leadGenUnsub({ email: formValues.email })(voidFn)(voidFn)

        !!response && handleCaptureResponse(response)

        response?.externalId &&
          sendCartToBraze({
            ...formValues,
            externalId: response.externalId,
            leadId: response.leadId
          })
      }

      const leadGenParams: LeadGenCaptureParams = {
        email: formValues.email,
        leadSource: 'exit_intent',
        locale,
        promoCode: discountCode ?? undefined,
        source: 'checkout',
        sourceType: 'cart'
      }
      leadGenCapture(leadGenParams)(voidFn)(handleSuccess)
    },
    [
      channel,
      discountCode,
      leadGenCaptureV2,
      locale,
      loginSuccess,
      trackSubmitLead
    ]
  )
}
