export type JSONObject = Record<string, unknown>

// This function replaces all occurrences of a variable in a JSON object with a value.
// It is required to substitute variables wrapped in {{}} in Apollo GraphQL responses.
export default function substituteVariable(
  json: JSONObject,
  variable: string,
  value: string
): JSONObject {
  const variablePattern = new RegExp(`{{${variable}}}`, 'g')

  function recursiveSubstitute(obj: unknown): unknown {
    if (typeof obj === 'string') {
      return obj.replace(variablePattern, value)
    } else if (Array.isArray(obj)) {
      return obj.map(item => recursiveSubstitute(item))
    } else if (typeof obj === 'object' && obj !== null) {
      return Object.fromEntries(
        Object.entries(<JSONObject>obj).map(([key, val]) => [
          key,
          recursiveSubstitute(val)
        ])
      )
    } else {
      return obj
    }
  }

  return <JSONObject>recursiveSubstitute(json)
}
