import type { Locale } from '@ecomm/utils'
import { type TypeOf, z } from '@simplisafe/ewok'
import type { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'
import type { Either } from 'monet'

import type { ProductPayload } from '../types'

const packageProduct = z.object({
  baseProduct: z.string(),
  otherItems: z.array(
    z.object({
      key: z.string(),
      value: z.string()
    })
  ),
  products: z.array(
    z.object({
      name: z.string(),
      sku: z.string()
    })
  )
})

export type PackageProductSchema = TypeOf<typeof packageProduct>

const product = z.object({
  name: z.string(),
  quantity: z.number(),
  sku: z.string()
})

export type ProductSchema = TypeOf<typeof product>

export type RudderProductProps = {
  readonly packageParentId?: string
  readonly productId: string
  readonly price: number
  readonly quantity?: number
  readonly name?: string
  readonly brand?: string
  readonly category?: string
}

export type ProductAddedBody = {
  readonly cartId?: string
  readonly coupon?: string
  readonly locale: Locale
  readonly total: number
  readonly products: readonly ProductPayload[]
}

export type AccessoryAddedBody = {
  readonly product: Either<Error, Product>
  readonly quantity: number
}
