import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export function useTrackingPaymentForm() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (args: TrackingData) => {
      trackEvent({ appSection: 'paymentForm', ...args })
    },
    [trackEvent]
  )
}
