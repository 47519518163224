import classNames from 'classnames'

type GradientProps = {
  readonly isFullWidth?: boolean
  readonly colors: readonly string[]
  readonly children: JSX.Element | readonly JSX.Element[] | null
}

export const colorTranslations: Record<string, string> = {
  brandPrimary: 'var(--primary-100)',
  complementaryBlue100: 'var(--complementary-blue-100)',
  neutralBlack: 'var(--neutral-black)',
  neutralLight100: 'var(--neutral-light-100)',
  neutralLight200: 'var(--neutral-light-200)',
  neutralWhite: 'var(--white)',
  complementaryGray100: '#d5d4cf'
}

export default function GradientWrapper({
  colors,
  children,
  isFullWidth = false
}: GradientProps) {
  const backgroundStyle =
    colors.length === 0 || colors.length === 1
      ? {
          backgroundColor: `${colorTranslations[colors[0] || ''] || 'transparent'}`
        }
      : {
          background: `linear-gradient(90deg, ${colors
            .map(c => `${colorTranslations[c]} ${100 / colors.length}%`)
            .join(',')})`
        }

  return (
    <section
      className={classNames('relative w-full', {
        'p-4 md:px-8 md:pt-8 lg:pb-0': !isFullWidth
      })}
      data-component={GradientWrapper.name}
      style={isFullWidth ? backgroundStyle : {}}
    >
      {children}
    </section>
  )
}
