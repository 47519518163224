import { useEnv } from '@ecomm/utils'
import type { Locale } from '@simplisafe/ewok'
/**
 * Returns the current locale.
 *
 * @example
 *
 * import { useLocale } from '@ecomm/data-hooks`
 *
 * export function Component() {
 *  const locale = useLocale()
 *
 *  return <p>You are in {locale}</p>
 * }
 */
export const useLocale = (): Locale => {
  const env = useEnv()
  return env.locale
}
