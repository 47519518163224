import { addAtomDebugLabel } from '@ecomm/utils'
import type { Client } from '@optimizely/optimizely-sdk'
import * as O from 'fp-ts/lib/Option'
import { atom } from 'jotai'

/**
 * For browser use only.
 *
 * You should never access this atom directly, only through hooks in `@ecomm/optimizely-hooks`.
 */
export const optimizelyClientAtom = atom<O.Option<Client>>(O.none)

addAtomDebugLabel(optimizelyClientAtom, 'Optimizely client')
