import { match } from 'ts-pattern'

import { useCartValue } from '../atoms/useCart'

/**
 * Returns the error from the cart state if it exists.
 */
export const useCartError = () => {
  const cart = useCartValue()

  return match(cart)
    .with({ _tag: 'not_initialized' }, _ => null)
    .with({ _tag: 'with_items' }, _ => null)
    .with({ _tag: 'updating' }, _ => null)
    .with({ _tag: 'error' }, cart => cart.val)
    .with({ _tag: 'empty' }, _ => null)
    .with({ _tag: 'no_cart_id' }, _ => null)
    .exhaustive()
}
