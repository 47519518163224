import { match } from 'ts-pattern'
import { useCartValue } from '../atoms/useCart'

/**
 * Get the custom field object from a jotai cart
 */
export const useCartCustomFields = (): Record<string, unknown> => {
  const cart = useCartValue()

  return match(cart)
    .with({ _tag: 'not_initialized' }, _ => ({}))
    .with({ _tag: 'error' }, _ => ({}))
    .with({ _tag: 'empty' }, _ => ({}))
    .with({ _tag: 'no_cart_id' }, _ => ({}))
    .with({ _tag: 'updating' }, cart => cart.val.custom)
    .with({ _tag: 'with_items' }, cart => cart.val.custom)
    .exhaustive()
}
