import { useContentful } from '@ecomm/shared-apollo'
import { getLocale } from '@ecomm/utils'
import {
  type ExperienceConfiguration,
  useExperience
} from '@ninetailed/experience.js-react'
import { ExperienceMapper } from '@ninetailed/experience.js-utils'

import { logError } from '@ecomm/error-handling'
import { safeParse } from '@simplisafe/ewok'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import { NT_FEATURE_FLAG_QUERY } from './nt_feature_flag_query'
import {
  type NinetailedFeatureFlagQueryDataSchema,
  ninetailedFeatureFlagQueryDataSchema
} from './schema'

const getData = (data: NinetailedFeatureFlagQueryDataSchema) => {
  return {
    id: data.ntFeatureFlag.sys.id,
    title: data.ntFeatureFlag.title,
    json: data.ntFeatureFlag.json,
    nt_experiences: getMappedExperiences(data)
  }
}

export const getMappedNinetailedFeatureFlag = (
  data: NinetailedFeatureFlagQueryDataSchema
) =>
  (data.ntFeatureFlag?.ntExperiencesCollection?.items || []).map(
    experience => ({
      id: experience.sys.id,
      name: experience.ntName,
      type: experience.ntType,
      config: experience.ntConfig,
      // This syntax accounts for the possibility of an audience not being set on an experiment
      ...(experience.ntAudience
        ? {
            audience: {
              name: experience.ntAudience.ntName,
              id: experience.ntAudience.sys.id
            }
          }
        : {}),
      variants: experience.ntVariantsCollection.items
        .filter(item => item.__typename === 'NtFeatureFlag')
        .map(variant => ({
          id: variant.sys.id, // Required
          title: variant.title,
          json: variant.json
        }))
    })
  )

const getMappedExperiences = (data: NinetailedFeatureFlagQueryDataSchema) =>
  getMappedNinetailedFeatureFlag(data)
    .filter(experience => ExperienceMapper.isExperienceEntry(experience))
    .map(experience => ExperienceMapper.mapExperience(experience))

export type BaselineIdProps = {
  readonly baselineId: string
}

export type ExperienceReturnStatus =
  | 'disabled'
  | 'error'
  | 'loading'
  | 'success'

export type FeatureFlag = {
  data: {
    id: string
    title: string
    json: { id: string; tag: string }
    nt_experiences: ExperienceConfiguration<{
      id: string
      title: string
      json: { id: string; tag: string }
    }>[]
  }
  experiences: ExperienceConfiguration<{
    id: string
    title: string
    json: { id: string; tag: string }
  }>[]
  baseline: { id: string }
  variantIndex: number
  isControl: boolean
  isVariant: boolean
  status: ExperienceReturnStatus
}

/**
 * We return this if something is wrong with the contentful data, which is what happens if the entry isn't published yet.
 */
const emptyFeatureFlag: FeatureFlag = {
  data: {
    id: '',
    title: '',
    json: { id: '', tag: '' },
    nt_experiences: []
  },
  experiences: [],
  baseline: { id: '' },
  variantIndex: 0,
  isControl: true,
  isVariant: false,
  status: 'error'
}

export const useNinetailedFeatureFlag = ({
  baselineId
}: BaselineIdProps): FeatureFlag => {
  const locale = getLocale()
  const response = useContentful(NT_FEATURE_FLAG_QUERY({ baselineId }))

  const data = safeParse(response?.data, ninetailedFeatureFlagQueryDataSchema, {
    path: ['useNinetailedFeatureFlag', 'ninetailedFeatureFlagQueryDataSchema']
  })

  return pipe(
    data,
    E.match(
      (err): FeatureFlag => {
        logError(err)
        return emptyFeatureFlag
      },
      (contentfulData): FeatureFlag => {
        const featureFlagData = contentfulData
        const data = getData(featureFlagData)
        const experiences = getMappedExperiences(featureFlagData)

        const maybeUseExperience =
          locale === 'en-US' &&
          useExperience({
            baseline: { id: baselineId },
            experiences
          })

        const variantIndex =
          (maybeUseExperience && maybeUseExperience.variantIndex) || 0
        const isControl = variantIndex === 0

        return {
          data,
          experiences,
          baseline: { id: baselineId },
          variantIndex,
          isControl,
          isVariant: !isControl,
          status: maybeUseExperience ? maybeUseExperience.status : 'disabled'
        }
      }
    )
  )
}
