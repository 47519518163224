import {
  getPartnerGroup,
  getValueFromPartnerCookie
} from '@ecomm/shared-cookies'
import { match } from 'ts-pattern'

/**
 * Returns the number of free monitoring months a user would get for the currently active promotion.
 */

export const useNumberOfFreeServiceMonths = (isPromoActive: boolean) => {
  const oneMonthPartners = ['lightspeed']
  const partnerGroup = getPartnerGroup() || ''
  const partnerName = getValueFromPartnerCookie('partnerName') || ''
  const partnerOffersTwoMonthsService =
    !oneMonthPartners.includes(partnerName) && !partnerName.includes('cydcor')
  const numberFreeMonthsOfService: number = match(partnerGroup)
    .when(
      (p: string) =>
        p === 'partner-strategic-referral' && partnerOffersTwoMonthsService,
      () => 2
    )
    .when(
      (p: string) => p === 'standard-partner' && isPromoActive === true,
      () => 2
    )
    .otherwise(() => 1)

  return numberFreeMonthsOfService
}
