import { match } from 'ts-pattern'

import { useCartValue } from '../atoms/useCart'

/**
 * Returns the shipping method id of the cart as a string.
 *
 * Defaults to null if the cart is not initialized, empty, or has an error.
 */
export const useCartShippingMethodId = () => {
  const cart = useCartValue()

  return match(cart)
    .with({ _tag: 'not_initialized' }, _ => null)
    .with({ _tag: 'with_items' }, cart => cart.val.shippingMethod.id)
    .with({ _tag: 'updating' }, cart => cart.val.shippingMethod.id)
    .with({ _tag: 'error' }, _ => null)
    .with({ _tag: 'empty' }, _ => null)
    .with({ _tag: 'no_cart_id' }, _ => null)
    .exhaustive()
}
