import { useMicroCopy } from '@ecomm/micro-copy'
import classNames from 'classnames'

import Add from './Add'
import Minus from './Minus'

export type Props = {
  readonly maxQuantity: number
  readonly minQuantity: number
  readonly onDecrease: () => void
  readonly onIncrease: () => void
  readonly quantity: number
  readonly testId: string
}

const buttonClass =
  'bg-transparent border-none p-0 text-btn-primary transition-all duration-100 active:opacity-75 hover:text-btn-dark'

function Stepper({
  maxQuantity,
  minQuantity,
  onDecrease,
  onIncrease,
  quantity: quantityProp,
  testId
}: Props) {
  // Prevents negative numbers.
  const quantity = Math.max(0, quantityProp)

  const handleDecrease = () => {
    onDecrease()
  }

  const handleIncrease = () => {
    onIncrease()
  }

  const isMinExceeded = quantity <= minQuantity
  const isMaxExceeded = quantity >= maxQuantity

  const microCopy = useMicroCopy()

  return (
    <div
      className="flex items-center justify-center gap-2 lg:gap-4"
      data-component="QuantitySelector"
      data-testid="QuantitySelector"
    >
      <button
        aria-label={microCopy['aria-decrease-quantity']}
        className={classNames(buttonClass, {
          'cursor-pointer': !isMinExceeded,
          'disabled:cursor-not-allowed': isMinExceeded,
          'disabled:opacity-30': isMinExceeded
        })}
        data-testid={'draft-quantity-decrease-button'}
        disabled={isMinExceeded}
        onClick={handleDecrease}
      >
        <Minus testId={testId} />
      </button>
      <span
        className="rounded-base flex h-[52px] w-14 items-center justify-center border-2 border-solid border-black/20 text-center"
        data-component="QuantitySelector_Value"
        data-testid="QuantitySelector_Value"
      >
        {quantity}
      </span>
      <button
        aria-label={microCopy['aria-increase-quantity']}
        className={classNames(buttonClass, {
          'cursor-pointer': !isMaxExceeded,
          'disabled:cursor-not-allowed': isMaxExceeded,
          'disabled:opacity-30': isMaxExceeded
        })}
        data-testid={'draft-quantity-increase-button'}
        disabled={isMaxExceeded}
        onClick={handleIncrease}
      >
        <Add testId={testId} />
      </button>
    </div>
  )
}

export default Stepper
