import type { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { useLocale } from '@ecomm/data-hooks'
import { getLeadData, getUserEmail } from '@ecomm/shared-cookies'
import { get as sessionStorageGet } from '@ecomm/utils'
import * as E from 'fp-ts/lib/Either'
import * as J from 'fp-ts/lib/Json'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

import { useYodaAddress } from '../actions/useYodaAddress'
import { useInitialCheckoutCartData } from './useInitialCheckoutCartData'

// Return a boolean value from sessionStorage with fallback logic
const getBooleanFromSessionStorage = (
  key: string,
  fallback = true
): boolean => {
  return pipe(
    sessionStorageGet(key),
    J.parse,
    E.fold(() => fallback, Boolean)
  )
}

// Email priority: cartEmail > emailCookie > leadDataCookie
const getEmail = (cartEmail?: string) => {
  const emailCookie = getUserEmail()
  const leadDataCookie = getLeadData()

  return (
    cartEmail ??
    (emailCookie !== ''
      ? emailCookie
      : leadDataCookie && 'email' in leadDataCookie
        ? leadDataCookie['email']
        : '')
  )
}

const getCustomField = (custom: Record<string, unknown>, key: string): string =>
  pipe(
    O.fromNullable(custom[key]),
    O.chain(s => (typeof s === 'string' ? O.some(s) : O.none)),
    O.getOrElse(() => '')
  )

type TouchedFields = Record<string, boolean>

const initialState: Omit<
  CheckoutFormValuesType,
  'country' | 'offerAndTip' | 'sameAddress'
> = {
  additionalFoundInfoThrough: '',
  additionalStreetInfo: '',
  city: '',
  couponCode: '',
  email: '',
  epsilonAbacusOptIn: false,
  firstName: '',
  foundInfoThrough: '',
  lastName: '',
  phone: '',
  postalCode: '',
  shippingOption: '',
  state: '',
  streetName: ''
}

export function useInitialCheckoutForm(): readonly [
  CheckoutFormValuesType,
  TouchedFields
] {
  const locale = useLocale()
  const { discountCode, shippingAddress, shippingMethodId, customFields } =
    useInitialCheckoutCartData()

  const yodaAddress = useYodaAddress()

  const offerAndTip = getBooleanFromSessionStorage('offerAndTip')
  const sameAddress = getBooleanFromSessionStorage('sameAddress')

  const additionalFoundInfoThrough = customFields
    ? getCustomField(customFields, 'leadOther')
    : ''
  const foundInfoThrough = customFields
    ? getCustomField(customFields, 'leadSource')
    : ''

  const touchedFields: TouchedFields =
    yodaAddress !== null
      ? {
          additionalStreetInfo: true,
          city: true,
          email: true,
          firstName: true,
          lastName: true,
          phone: true,
          postalCode: true,
          state: true,
          streetName: true
        }
      : {}

  return [
    {
      ...initialState,
      ...shippingAddress,
      // email needs to be above yodaAddress otherwise there may be mismatches
      email: getEmail(shippingAddress?.email),
      ...(yodaAddress !== null && yodaAddress),
      country: locale === 'en-US' ? 'US' : 'GB',
      offerAndTip: offerAndTip,
      sameAddress: sameAddress,
      couponCode: discountCode ?? '',
      shippingOption: shippingMethodId ?? '',
      additionalFoundInfoThrough: additionalFoundInfoThrough,
      foundInfoThrough: foundInfoThrough
    },
    touchedFields
  ]
}
