function hasOwn<T extends Record<number | string | symbol, unknown>>(
  t: T,
  k: number | string | symbol
): boolean {
  return k in t
}

/**
 * Polyfil for `Object.hasOwn`.
 *
 * All of the browsers we use support this so you should never use this in production, but some unit tests using Enzyme fail if the source file contains `Object.hasOwn`.
 *
 * @deprecated - all browsers suppert this now and a polyfill is no longer needed
 *
 * @example
 * // foo.test.ts
 * import { polyfillHasOwn } from '@ecomm/shared-polyfill`
 *
 * beforeEach() {
 *  polyfillHasOwn()
 * }
 */
export function polyfillHasOwn() {
  Object.hasOwn = Object.hasOwn || hasOwn
}
