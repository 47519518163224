import { get as sessionStorageGet } from '@ecomm/utils'
import { window } from 'browser-monads-ts'

import type { PaymentCallbackRequest } from '../paymentCallbackSchemas'
import type {
  ResponseFrom,
  ZuoraErrorResponse,
  ZuoraSuccessResponse
} from '../types'
import { getClientType } from './utils'

export const formatCallbackRequest = <T extends Record<string, string>>(
  t: T,
  isVariation = false
): PaymentCallbackRequest => {
  const origin = window.location.origin
  const clientType = getClientType(isVariation, origin)
  return {
    clientType: clientType,
    origin,
    signature: t['signature'] || '',
    hpmClient: {
      callbackPath: '/payment-callback',
      tenantId: t['tenantId'] || '',
      token: t['token'] || '',
      pageId: sessionStorageGet('payment-page-id')
    }
  }
}

export const getSuccessParams = <T extends Record<string, string>>(
  t: T
): ZuoraSuccessResponse => {
  return {
    refId: t['refId'] || '',
    token: t['token'] || ''
  }
}

export const getFailureParams = <T extends Record<string, string>>(
  t: T,
  errorMessage?: string
): ZuoraErrorResponse => {
  return {
    responseFrom: getResponseFrom(t),
    success: getSuccess(t),
    errorCode: t['errorCode'],
    errorMessage
  }
}

export const getResponseFrom = <T extends Record<string, string>>(
  t: T
): ResponseFrom => {
  return <ResponseFrom>t['responseFrom']
}

export const getSuccess = <T extends Record<string, string>>(
  t: T
): boolean | undefined => {
  return t['success'] === 'true'
}
