import { ContentfulRichText } from '@ecomm/shared-components'

import type { Output as ComponentProps } from '../../hooks/useLocalizedData'
import ConfidenceItem from './ConfidenceItem'
import type { schema } from './schema'

export type ConfidenceBarProps = ComponentProps<typeof schema>

export function ConfidenceBar({ data }: { readonly data: ConfidenceBarProps }) {
  return (
    <>
      {data?.headline?.raw ? (
        <div>
          <div className="prose md:prose-md lg:prose-lg whitespace-pre-line text-center text-[1.75rem] font-bold">
            <ContentfulRichText raw={data.headline.raw} />
          </div>
        </div>
      ) : null}
      <div
        className="m-0 flex flex-row flex-wrap gap-4 p-0 lg:flex-nowrap lg:gap-8"
        data-component="ConfidenceBar"
        data-testid="ConfidenceBar"
      >
        {data.listItems.map((item, index) => (
          <ConfidenceItem key={`item-${index}`} {...item} />
        ))}
      </div>
    </>
  )
}
