import { Modal } from '@ecomm/shared-components'
import { stringToKebabCase } from '@ecomm/utils'
import { useCallback } from 'react'
import type { TwoButtonsProps } from '../../lib/ModalContext/types'

export function ModalTwoButtons({
  title,
  isOpen,
  description,
  buttonLabelLeft = 'Cancel',
  buttonLabelRight,
  onRequestClose,
  onClick,
  showToast
}: TwoButtonsProps & {
  readonly isOpen: boolean
  readonly onRequestClose: () => void
}) {
  const handleOnClick = useCallback(() => {
    onClick()
    showToast && showToast()
  }, [onClick, showToast])

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{ content: { maxWidth: '686px' } }}
    >
      <div
        className="flex max-w-screen-lg flex-col-reverse gap-8 p-6 lg:flex-row prose md:prose-md lg:prose-lg lg:items-center"
        data-component={`modal-${stringToKebabCase(buttonLabelRight)}`}
        data-testid={`modal-${stringToKebabCase(buttonLabelRight)}`}
      >
        <div className="my-auto flex-1 justify-items-center py-7 px-10">
          <h3 className="mb-4 font-bold text-center text-3xl">{title}</h3>
          <p className="mb-4 font-normal text-center text-base pb-[14px]">
            {description}
          </p>
          <div className="flex justify-center gap-4">
            <button
              className="btn text-lg font-bold btn-outlined btn-outlined-primary mb-4 w-[200px] h-[58px]"
              onClick={onRequestClose}
            >
              {buttonLabelLeft}
            </button>
            <button
              className="text-white px-5 py-4 cursor-pointer border-none bg-btn-primary text-center text-lg font-bold rounded-[10px] w-[200px] h-[58px]"
              onClick={handleOnClick}
            >
              {buttonLabelRight}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
