import type { Address } from '@simplisafe/eis-commercetools-carts'
import * as R from 'fp-ts/lib/Record'
import { useEffect, useState } from 'react'
import { useCartValue } from '../atoms/useCart'

type PrefillData = {
  readonly discountCode?: string | null
  readonly shippingAddress?: Address | null
  readonly shippingMethodId?: string | null
  readonly customFields?: Record<string, unknown>
}

/**
 * Returns cart data to use when prefilling the checkout/shipping form.
 * Uses data from the initial load of cart; will not update on subsequent cart updates.
 */
export const useInitialCheckoutCartData = (): PrefillData => {
  const cart = useCartValue()
  const [initialCartValues, setInitialCartValues] = useState<PrefillData>({})

  useEffect(() => {
    const shouldSetValues =
      cart._tag === 'with_items' && R.isEmpty(initialCartValues)

    shouldSetValues &&
      setInitialCartValues({
        discountCode: cart.val.discountCode,
        shippingAddress: cart.val.shippingAddress,
        shippingMethodId: cart.val.shippingMethod.id,
        customFields: cart.val.custom
      })
  }, [cart, initialCartValues])

  return initialCartValues
}
