import { overloadMaybe } from '@simplisafe/ewok'
import { selectPromoWithMonitoringDiscountText } from '@simplisafe/ss-ecomm-data/promotions/select'
import { useSelector } from 'react-redux'

/**
 * Returns the discount text of the promo with monitoring.
 * Uses the active promo if available, otherwise falls back to evergreen.
 */
// todo: refactor this as part of effort to pull discount amount from promos
export const usePromoWithMonitoringDiscountText = () => {
  const discountTextWithMonitoring = useSelector(
    selectPromoWithMonitoringDiscountText
  )
  return overloadMaybe(discountTextWithMonitoring)
}
