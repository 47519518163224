import type { ReactNode } from 'react'

type PageWrapperProps = {
  readonly children?: ReactNode
  readonly dataComponent?: string
}

export function PageWrapper({
  children = null,
  dataComponent = PageWrapper.name
}: PageWrapperProps) {
  return (
    <div
      className="min-h-screen overflow-x-hidden bg-white"
      data-component={dataComponent}
    >
      {children}
    </div>
  )
}

export default PageWrapper
