import { gql } from '@apollo/client/index.js'

export const EXIT_INTENT_QUERY = gql`
  query ExitIntentQuery($preview: Boolean!, $locale: String!) {
    images: assetCollection(preview: $preview, locale: $locale,
      where: {
        sys: {
          id_in: [
            "2AB2d5pVj16iZItrZqOx5d"
            "6UMbGUo2vMm65W8HkHT2ni"
          ]
        }
      }
    ) {
      items {
        contentful_id: sys { id }
        title
        fileName
        size
        url
        width
        height
        description
      }
    }
  }
`
