import { gatsbyImageSchema } from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

export const imageWithArtDirectionSchema = z.object({
  desktopImage: gatsbyImageSchema,
  tabletImage: gatsbyImageSchema,
  mobileImage: gatsbyImageSchema
})

export type ImageWithArtDirectionSchema = TypeOf<
  typeof imageWithArtDirectionSchema
>
