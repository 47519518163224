import { currentPromoAtom } from '@ecomm/data-promotions'
import { useAtom } from 'jotai'
import { usePartnerPromo } from '../usePartnerPromo'

/**
 * The responsibility of this hook is to access all the data
 * we need that is stored in redux, create the needed local
 * states and return all this data.
 */
export function useCurrentPromotionsData() {
  const [currentPromo] = useAtom(currentPromoAtom)
  const { withoutMonitoring, withMonitoring, dynamic } =
    currentPromo.activeCodes
  const partnerBanner = usePartnerPromo()

  return {
    currentPromoCode: withoutMonitoring,
    currentPromoCodeWithMonitoring: withMonitoring,
    partnerPromoCodes: partnerBanner.partnerPromoCodes,
    dynamicPromoCodes: dynamic ?? []
  }
}
