import { useCallback, useState } from 'react'

type Props = {
  readonly onSwipeLeft: () => void
  readonly onSwipeRight: () => void
  readonly sensitivity?: number
}

/**
 * Hook to handle swipe motion on touch devices, takes in callbacks for left and right swipes and returns onTouchStart and onTouchEnd event handlers to be attached to the element
 *
 * Executes the appropriate callback function when user swipes left or right
 *
 * @param onSwipeLeft - Callback function to be called when user swipes left
 * @param onSwipeRight - Callback function to be called when user swipes right
 * @param sensitivity - Minimum distance in pixels, between the touchstart and touchend events, where we will consider this a "swipe" event. Defaults to 5
 *
 * @returns Object containing onTouchStart and onTouchEnd event handlers
 */
export const useSwipeMotion = ({
  onSwipeLeft,
  onSwipeRight,
  sensitivity = 5
}: Props) => {
  const [touchPosition, setTouchPosition] = useState<number | null>(null)

  const swipe = useCallback(
    (diff: number) => {
      if (diff > sensitivity) {
        return onSwipeLeft()
      } else if (diff < -sensitivity) {
        return onSwipeRight()
      } else {
        return null
      }
    },
    [onSwipeLeft, onSwipeRight, sensitivity]
  )

  const handleTouchStart = (e: React.TouchEvent) => {
    const touchDown = e.touches[0]?.clientX || 0
    setTouchPosition(touchDown)
  }

  const handleTouchEnd = useCallback(
    (e: React.TouchEvent) => {
      if (touchPosition !== null) {
        const currentTouch = e.changedTouches[0]?.clientX || 0
        const diff = touchPosition - currentTouch

        swipe(diff)

        return setTouchPosition(null)
      } else {
        return null
      }
    },
    [touchPosition, swipe]
  )

  return {
    onTouchStart: handleTouchStart,
    onTouchEnd: handleTouchEnd
  }
}
