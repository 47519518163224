import { locale as localeEnv } from '@ecomm/data-env-variables'
import { type ErrorResponse, fetchApi } from '@ecomm/data-simplisafe-api'
import { logError } from '@ecomm/error-handling'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

export type SourceType =
  | 'cart'
  | 'contact_us'
  | 'door_to_door'
  | 'external'
  | 'footer'
  | 'guided_system_builder'
  | 'phone'
  | 'promo'
  | 'purchase'
  | 'quote_wizard_recs'
  | 'quote_wizard'
  | 'save_system'

export type LeadGenCaptureV2Props = {
  readonly email?: string
  readonly phone?: string
  readonly firstName?: string
  readonly lastName?: string
  readonly source: string
  readonly sourceDetail?: string
  readonly country?: 'GB' | 'US'
  readonly address?: {
    readonly street1: string
    readonly street2?: string
    readonly city: string
    readonly zone?: string
    readonly postalCode: string
  }
  readonly company?: string
  readonly creatorUid?: string
  readonly siteMetadata?: {
    readonly rudderstackId?: string
    readonly partner?: string
    readonly vid?: string
    readonly channel?: string
    readonly urlPath?: string
    readonly deviceType?: string
    readonly sourceType?: SourceType
    readonly promoOffer?: string
    readonly utmSource?: string
    readonly utmMedium?: string
    readonly utmCampaign?: string
    readonly timeZone?: string
  }
}

export type LeadGenV2ResponseProps = {
  readonly email?: string
  readonly leadId: string
  readonly source: string
  readonly externalId?: string | null
}

export const handleLeadCaptureV2Failure = (e: Error | ErrorResponse) => {
  e instanceof Error ? logError(e) : logError(new Error(`${e.url} ${e.body}`))
}

const isLeadGenResponse = (res: unknown): res is LeadGenV2ResponseProps => {
  return (
    !!res &&
    typeof res === 'object' &&
    Object.hasOwn(res, 'source') &&
    Object.hasOwn(res, 'email')
  )
}

/**
 * Captures a lead in eis-leads service
 * @param data lead data to capture
 * @param subspace if true eis-leads will create as subscriber in subspace and return an externalId in the response
 * @returns
 */
export function leadGenCaptureV2(
  data: LeadGenCaptureV2Props,
  subspace = false
) {
  return pipe(
    TE.Do,
    TE.bind('locale', () => localeEnv),
    TE.chain(({ locale }) => {
      return fetchApi({
        method: 'POST',
        endpoint: `/eis-leads/v1/leads?subspace=${subspace.toString()}`,
        headers: { 'X-SimpliSafe-Locale': locale },
        body: JSON.stringify(data)
      })
    }),
    TE.chain(res =>
      isLeadGenResponse(res)
        ? TE.right(res)
        : TE.left(Error('There was an error trying to send the data'))
    )
  )
}
