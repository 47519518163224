export const formatPhoneNumber = (rawValue?: string) => {
  const cleanedValue = rawValue?.replaceAll(/\D/g, '') || ''
  const areaCode = cleanedValue.slice(0, 3)
  const prefix = cleanedValue.slice(3, 6)
  const unique = cleanedValue.slice(6, 10)
  const extra = cleanedValue.slice(10)

  return cleanedValue.length > 0
    ? `(${areaCode}${prefix ? `) ${prefix}` : ''}${unique ? `-${unique}` : ''}${extra ? ` ${extra}` : ''}`
    : ''
}
