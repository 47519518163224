import type { PartnerBanner } from '@ecomm/data-simplisafe-api'
import { addAtomDebugLabel } from '@ecomm/utils'
import * as O from 'fp-ts/lib/Option'
import { atomWithReset } from 'jotai/utils'

export const defaultPartnerPromoBanner: PartnerBanner = {
  backgroundColor: '',
  discountSecondaryText: '',
  displayEmailInputField: false,
  linkPath: '',
  logoDescription: '',
  logoUrl: '',
  partnerGroup: '',
  partnerName: '',
  partnerPromoCodes: [],
  primaryOfferText: '',
  primaryTextColor: '',
  secondaryOfferText: '',
  secondaryOfferTextMobile: '',
  secondaryTextColor: '',
  hasPartnerBanner: false
}

export const DEFAULT_PARTNER_OFFER_TITLE = '15% off'

export const partnerPromoAtom = atomWithReset<O.Option<PartnerBanner>>(O.none)
addAtomDebugLabel(partnerPromoAtom, 'Promotions: Partner Banner')

export const partnerPromoLoadingAtom = atomWithReset<boolean>(false)
addAtomDebugLabel(partnerPromoLoadingAtom, 'Promotions: Partner Banner Loading')
