import { voidFn } from '@simplisafe/ewok'
import { createContext } from 'react'
import type { ModalContextProps } from './types'

export const ModalContext = createContext<ModalContextProps>({
  openModal: () => voidFn,
  closeModal: () => voidFn,
  isOpen: false,
  modalType: null,
  openTwoButtonsModal: () => voidFn,
  openSendLinkToCustomer: () => voidFn
})
