import { getLocale } from '@ecomm/utils'
import type { LineItem } from '@simplisafe/eis-commercetools-carts'
import { useCallback } from 'react'
import { brazeTrackCartEvent } from '../braze'
import { lineItemToBrazeLineItem } from './useTrackingCheckoutSubmit/lineItemToBrazeLineItem'

const getActionDataFromLineItem = (action: 'add_to_cart', item: LineItem) => {
  const locale = getLocale()
  const product = lineItemToBrazeLineItem(locale)(item)

  return {
    [action]: product,
    currencyCode: locale === 'en-US' ? '$' : '£'
  }
}

const useTrackingBrazeAddProduct = () => {
  return useCallback((item: LineItem) => {
    const trackingProduct = getActionDataFromLineItem('add_to_cart', item)

    brazeTrackCartEvent({
      eventType: 'add_to_cart',
      productTrackingData: trackingProduct
    })
  }, [])
}

export { useTrackingBrazeAddProduct }
