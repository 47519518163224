import { baseApiUrl, locale as localeEnv } from '@ecomm/data-env-variables'
import { safeFetch } from '@jvlk/fp-ts-fetch'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

export type LeadGenSubscribeProps = {
  readonly email: string
}

export function leadGenSubscribe(data: LeadGenSubscribeProps) {
  return pipe(
    TE.Do,
    TE.bind('locale', () => localeEnv),
    TE.bind('baseUrl', () => baseApiUrl),
    TE.chain(({ locale, baseUrl }) => {
      const sf: TE.TaskEither<Error | Response, Response> = safeFetch(
        `${baseUrl}/leads/v1/lead/subscribe`,
        {
          method: 'POST',
          headers: {
            'Accept-Language': locale,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            mail: data.email,
            locale: locale
          })
        }
      )
      return sf
    }),
    TE.mapLeft(res => Error(`Error fetching business capture method ${res}`))
  )
}
