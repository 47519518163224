import {
  INTERACTIVE_MONITORING,
  ODMON_247_MONITORING,
  ODMON_247_MONITORING_60,
  ODMON_OVERNIGHT_MONITORING,
  ODMON_OVERNIGHT_MONITORING_60
} from '@ecomm/data-constants'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export const useTrackATCPlanSelection = () => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (plan: string, placement: 'bms' | 'pdp') => {
      type SkuCodeMapping = Record<string, string>

      const plans: SkuCodeMapping = {
        [INTERACTIVE_MONITORING]: 'essential',
        [ODMON_OVERNIGHT_MONITORING]: 'pro',
        [ODMON_OVERNIGHT_MONITORING_60]: 'pro',
        [ODMON_247_MONITORING]: 'pro plus',
        [ODMON_247_MONITORING_60]: 'pro plus'
      }

      trackEvent({
        event: 'atc-plan-selection',
        plan: plans[plan] || plan,
        placement
      })
    },
    [trackEvent]
  )
}
