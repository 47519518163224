import {
  contentfulRichTextSchema,
  gatsbyImageSchema
} from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

const monitoringPageHeroBannerAssetSchema = z.object({
  description: gatsbyImageSchema.shape.description,
  gatsbyImageData: gatsbyImageSchema.shape.gatsbyImageData.optional()
})

export const monitoringPageHeroBanner = z.object({
  backgroundImage: monitoringPageHeroBannerAssetSchema.optional(),
  backgroundImageMobile: monitoringPageHeroBannerAssetSchema.optional(),
  fastProtectLogo: monitoringPageHeroBannerAssetSchema.optional(),
  badge: z.string(),
  title: z.string(),
  description: contentfulRichTextSchema.nullable().optional()
})

export type MonitoringPageHeroBannerData = TypeOf<
  typeof monitoringPageHeroBanner
>
