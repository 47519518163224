import { currentPromoAtom } from '@ecomm/data-promotions'
import {
  type CartDiscount,
  type CentPrecision,
  type DiscountCode,
  type PromotionsApiResponse,
  cartDiscountAbsoluteSchema,
  cartDiscountRelativeSchema
} from '@ecomm/data-simplisafe-api'
import { getPartnerCookie } from '@ecomm/shared-cookies'
import { useEnv } from '@ecomm/utils'
import { useAtom } from 'jotai'
import { useMonthsOfFreeServiceText } from '../useMonthsOfFreeServiceText'
import type { CurrentPromotion } from './types'

import * as O from 'fp-ts/lib/Option'
import * as RA from 'fp-ts/lib/ReadonlyArray'
import { constant, pipe } from 'fp-ts/lib/function'
import { useMemo } from 'react'

const noDiscountText = constant('')
const noDiscountValue = constant(0)

export const createOfferTitle = (
  promo: PromotionsApiResponse,
  locale: string
) => {
  const disc = getDiscountText(promo, locale)
  return `${disc} off`
}

export const getDiscount = (
  discountCodes: ReadonlyArray<DiscountCode>,
  activeCode: string
) => {
  return pipe(
    discountCodes,
    RA.findFirst(discountCode => discountCode.code === activeCode),
    O.map(discountCode => discountCode.cartDiscounts),
    O.chain(
      RA.findFirst(
        cartDiscount =>
          cartDiscount.value.type === 'relative' ||
          cartDiscount.value.type === 'absolute'
      )
    )
  )
}

export const formatAbsoluteDiscount = (
  money: CentPrecision,
  locale: string
) => {
  return new Intl.NumberFormat(locale, {
    currency: money.currencyCode,
    style: 'currency',
    minimumFractionDigits: money.fractionDigits,
    maximumFractionDigits: money.fractionDigits
  }).format(money.centAmount / 10 ** money.fractionDigits)
}

export const createDiscountText =
  (locale: string) => (cartDiscount: CartDiscount) => {
    return pipe(
      O.of(cartDiscount),
      O.chain(discount =>
        discount.value.type === 'absolute'
          ? pipe(
              O.tryCatch(() => cartDiscountAbsoluteSchema.parse(discount)),
              O.chain(absoluteDiscount =>
                RA.head(absoluteDiscount.value.money)
              ),
              O.map(money => formatAbsoluteDiscount(money, locale))
            )
          : pipe(
              O.tryCatch(() => cartDiscountRelativeSchema.parse(discount)),
              O.map(
                relativeDiscount => `${relativeDiscount.value.permyriad / 100}%`
              )
            )
      ),
      O.getOrElse(noDiscountText)
    )
  }

export const getDiscountText = (
  promo: PromotionsApiResponse,
  locale: string
) => {
  const { discountCodes, activeCodes } = promo

  const createText = (activeCode: string) =>
    pipe(
      getDiscount(discountCodes, activeCode),
      O.fold(noDiscountText, createDiscountText(locale))
    )

  const withMonitoringDiscount = createText(activeCodes.withMonitoring)
  const withoutMonitoringDiscount = createText(activeCodes.withoutMonitoring)

  return promo.displayMonitoringDiscount
    ? withMonitoringDiscount
    : withoutMonitoringDiscount
}

export const formatDiscountValue = (value: number, fractionDigits = 2) => {
  return Number(value.toFixed(fractionDigits))
}

export const calculateDiscounts =
  (totalPrice: number) => (cartDiscount: CartDiscount) => {
    return pipe(
      O.of(cartDiscount),
      O.chain(discount =>
        discount.value.type === 'absolute'
          ? pipe(
              O.tryCatch(() => cartDiscountAbsoluteSchema.parse(discount)),
              O.chain(absoluteDiscount =>
                RA.head(absoluteDiscount.value.money)
              ),
              O.map(money => {
                const discountAmount =
                  money.centAmount / 10 ** money.fractionDigits
                const discountedPrice = totalPrice - discountAmount

                return formatDiscountValue(
                  discountedPrice,
                  money.fractionDigits
                )
              })
            )
          : pipe(
              O.tryCatch(() => cartDiscountRelativeSchema.parse(discount)),
              O.map(relativeDiscount => {
                const discountPercentage =
                  relativeDiscount.value.permyriad / 100
                const discountAmount = (totalPrice * discountPercentage) / 100
                const discountedPrice = totalPrice - discountAmount

                return formatDiscountValue(discountedPrice)
              })
            )
      ),
      O.getOrElse(noDiscountValue)
    )
  }

export const getDiscountValues =
  (promo: PromotionsApiResponse) => (totalPrice: number) => {
    const { discountCodes, activeCodes } = promo

    const createDiscount = (activeCode: string) =>
      pipe(
        getDiscount(discountCodes, activeCode),
        O.fold(noDiscountValue, calculateDiscounts(totalPrice))
      )

    const discountedPriceWithMonitoring = createDiscount(
      activeCodes.withMonitoring
    )
    const discountedPriceWithoutMonitoring = createDiscount(
      activeCodes.withoutMonitoring
    )
    const amountSavedWithMonitoring = Math.abs(
      formatDiscountValue(
        discountedPriceWithMonitoring - discountedPriceWithoutMonitoring
      )
    )

    return {
      discountedPriceWithoutMonitoring,
      discountedPriceWithMonitoring,
      amountSavedWithMonitoring
    }
  }

export function useCurrentPromo(): CurrentPromotion {
  const [current] = useAtom(currentPromoAtom)
  const { locale } = useEnv()
  const isPartner = getPartnerCookie()
  const isActivePromo = !current.content.evergreen
  const discountText = getDiscountText(current, locale)
  const offerTitle = createOfferTitle(current, locale)
  const monthsOfServiceDisplay = useMonthsOfFreeServiceText(true, isActivePromo)
  const isTopBannerVisible =
    !!isPartner ||
    isActivePromo ||
    (!isActivePromo && !!current.banner.backgroundColor)

  return useMemo(() => {
    return {
      ...current.content,
      discountText,
      getDiscountValues: getDiscountValues(current),
      offerTitle,
      offerLabel: current.content.discountSecondaryText,
      giftLineItemText: current.content.giftlineItemText,
      displayEndTimeCalloutOverride: current.content.useEndTimeCalloutOverride,
      monthsOfServiceDisplay,
      locale,
      isTopBannerVisible,
      overrideDiscountText: current.overrideDiscountText,
      overrideBannerText: current.overrideDiscountText?.promoBannerDiscountText,
      promoFlag: current.flag,
      variationId: current.variationId
    }
  }, [current, monthsOfServiceDisplay])
}
