import { type TypeOf, z } from '@simplisafe/ewok'

export const monitoringPageVideoWrapperSchema = z.object({
  headline: z.string(),
  body: z.string()
})

export type MonitoringPageVideoWrapperSchema = TypeOf<
  typeof monitoringPageVideoWrapperSchema
>
