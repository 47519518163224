import {
  AFFIRM_QUALIFIED_MONTHLY,
  AFFIRM_TERM_MONTHS_SYSTEM,
  AFFIRM_TERM_MONTHS_SYSTEM_VARIANT
} from '@ecomm/data-constants'
import classNames from 'classnames'
import React from 'react'

import { AFFIRM_TERM_MONTHS_DOOR_TO_DOOR } from '@ecomm/door-to-door-constants/affirm'
import { D2DPartnerGroup } from '@ecomm/door-to-door-constants/cookies'
import { useMicroCopy } from '@ecomm/micro-copy'
import { getPartnerCookie } from '@ecomm/shared-cookies'
import { AffirmInformationLogoIcon } from '../../../AffirmInformationLogoIcon'
import type { AffirmOptionType } from '../../../types/affirm'

type Props = {
  readonly affirmOption: AffirmOptionType
  readonly onClick: () => void
  readonly priceInCents: number
  readonly isPlp?: boolean
  readonly isCheckout?: boolean
  readonly useSystemTermMonths?: boolean
  readonly isAffirmExperience?: boolean
  readonly hasSystem?: boolean
}

export function MonthlyOptionSubText({
  affirmOption,
  onClick,
  priceInCents,
  isPlp = false,
  useSystemTermMonths = true,
  isAffirmExperience = false,
  hasSystem = false,
  isCheckout = false
}: Props) {
  const microCopy = useMicroCopy()
  const partnerCookie = getPartnerCookie()

  const months =
    partnerCookie?.partnerGroup === D2DPartnerGroup
      ? AFFIRM_TERM_MONTHS_DOOR_TO_DOOR
      : isAffirmExperience
        ? AFFIRM_TERM_MONTHS_SYSTEM_VARIANT
        : AFFIRM_TERM_MONTHS_SYSTEM

  const isCheckoutWithoutSystem = isCheckout && !hasSystem

  const affirmUnavailableCopy = isCheckoutWithoutSystem
    ? 'affirm-no-system-subtext'
    : 'affirm-over-150-subtext'

  return affirmOption === AFFIRM_QUALIFIED_MONTHLY ? (
    <p
      className={classNames('my-1 text-xs font-normal leading-4', {
        'w-full lg:w-11/12': isPlp
      })}
    >
      <span>
        {`on equipment for ${months} mos. At checkout, pay over time with`}{' '}
      </span>
      <AffirmInformationLogoIcon
        height="15px"
        onClick={onClick}
        priceInCents={priceInCents}
        useSystemTermMonths={useSystemTermMonths}
        width="38px"
      />
    </p>
  ) : (
    <p className="my-1 text-xs font-normal leading-4">
      {microCopy[affirmUnavailableCopy]}
    </p>
  )
}
