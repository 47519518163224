import { trackPerimeterxBot } from '@ecomm/cdp-tracking'
import { useEffect } from 'react'

export default function PXRudderstackObserver() {
  useEffect(() => {
    const observer = new MutationObserver(_mutations => {
      const captchaDetected = document.querySelector('#px-captcha')
      captchaDetected && trackPerimeterxBot()
      captchaDetected && observer.disconnect()
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [])

  return <></>
}
