import {
  COOKIE_ATAT_TOKEN,
  COOKIE_VID,
  getAtAtToken,
  getVisitorId,
  setCookie
} from '@ecomm/shared-cookies'
import { isNilOrEmpty } from '@simplisafe/ewok'
import { Maybe } from '@simplisafe/ewok'
import { safeProp } from '@simplisafe/monda'
import {
  atatFetch,
  type atatRequest
} from '@simplisafe/ss-ecomm-data/simplisafe/atatClient'
import { exists, window } from 'browser-monads-ts'
import jwtDecode, { type JwtPayload } from 'jwt-decode'

import type { TrackingData } from '../types/tracking'

export const handleAtAtTrackingEvent = (
  data: TrackingData,
  refreshCookieAtom: () => void
) => {
  // https://at-at.us-east-1.qa.commerce.simplisafe.com/api/doc
  const trackPageLoad = () => {
    const atatRequestBody = {
      optimizelyVisitor: {}, // TODO is this still needed?
      qq: data.pagePath?.replace(/^\/+|\/+$/g, ''), // strip leading and trailing slashes
      query: data.queryString,
      referer: data.referrer, // 'referer' in key is intentional
      title: data.pageTitle,
      uid: data.userId,
      vid: data.visitorId
    }

    const atatRequestParams: atatRequest = {
      body: Object.entries(atatRequestBody).reduce(
        (acc, [key, val]) => (isNilOrEmpty(val) ? acc : { ...acc, [key]: val }),
        {}
      ),
      method: 'POST',
      token: getAtAtToken()
    }

    const atatCookieOptions = {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)), // store for 1 year
      path: '/',
      sameSite: <const>'strict',
      secure: process.env['NODE_ENV'] !== 'development' // disable secure for local development only
    }

    // TODO do we need to do anything on failure?
    atatFetch(atatRequestParams)(() => null)(response => {
      safeProp('token', response).forEach(token => {
        !getAtAtToken() &&
          setCookie(COOKIE_ATAT_TOKEN, token, atatCookieOptions)

        const payload = jwtDecode<JwtPayload>(token)
        safeProp('sub', payload).forEach(vid => {
          !getVisitorId() && setCookie(COOKIE_VID, vid, atatCookieOptions)
        })

        // This refreshes the cookies in the jotai cookieAtom after document.cookie is set in the lines above.
        // TODO: longer-term this whole AT-AT flow should be refactored to use the `useCookie` hook directly.
        refreshCookieAtom()
      })
    })
  }

  data.event === 'pageLoad' && trackPageLoad()
}

export const visitorIdAtAt: () => string | undefined = () =>
  Maybe.fromNull(getAtAtToken())
    .map(_token => jwtDecode<JwtPayload>(_token))
    .chain(safeProp('sub'))
    .orUndefined()

const pollForAtAtToken = (resolve: (_value: string) => void) => {
  const clear = (interval: NodeJS.Timeout, value: string) => {
    clearInterval(interval)
    resolve(value)
  }

  const interval = setInterval(() => {
    const atatToken = getAtAtToken()
    !!atatToken && clear(interval, atatToken)
  }, 100)
}

/**
 * Polls for the AT-AT cookie and returns a Promise that resolves with the cookie value.
 */
export const fetchAtAtToken = () =>
  new Promise<string | null>(resolve =>
    Maybe.fromFalsy(exists(window)).cata(
      () => resolve(null),
      () => pollForAtAtToken(resolve)
    )
  )

/**
 * Polls for the AT-AT cookie and returns a Promise that resolves with the cookie's visitor id.
 */
export const fetchAtAtVisitorId = () =>
  fetchAtAtToken().then(() => visitorIdAtAt())
