import type { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

export type NewRelicBrowserApi = InstanceType<typeof BrowserAgent>

declare global {
  var newrelic: NewRelicBrowserApi | undefined
  export interface Window {
    // keep the newrelic type as optional to avoid type errors when accessing it in different places
    // where window is still accessed and until we move away from window
    readonly newrelic?: NewRelicBrowserApi
  }
}

export function getNewRelic(fn: (t: NewRelicBrowserApi) => void): void {
  return pipe(
    O.fromNullable(globalThis.newrelic),
    O.fold(() => undefined, fn)
  )
}
