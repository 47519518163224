import {
  partnerPromoAtom,
  partnerPromoLoadingAtom
} from '@ecomm/data-promotions'
import { fetchCurrentPromotion } from '@ecomm/data-simplisafe-api'
import { logError } from '@ecomm/error-handling'
import {
  type PartnerBanner,
  formatPartnerBanner
} from '@ecomm/promotions-utils'
import { getValueFromPartnerCookie } from '@ecomm/shared-cookies'
import { useEnv } from '@ecomm/utils'
import { voidFn } from '@simplisafe/ewok'
import { useQuery } from '@tanstack/react-query'

import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useAtom, useSetAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'

/**
 * Get partner banner for displaying override in-place of site-wide promo banner.
 * This hook should not be used on partner pages since an up-to-date partner cookie won't
 * be available there. Those pages should use the useSetPartnerLogic hook and pass the partner
 * details directly.
 */
export const useFetchPartnerBanner = (
  _customerGroup?: string,
  _partnerName?: string
): O.Option<PartnerBanner> => {
  const { locale } = useEnv()
  const [shouldRequestPartner, setRequestPartner] = useState(false)
  const [partnerAtom, setPartnerPromoAtom] = useAtom(partnerPromoAtom)
  const setPartnerAtomLoading = useSetAtom(partnerPromoLoadingAtom)
  const partnerName = _partnerName ?? getValueFromPartnerCookie('partnerName')
  const partnerGroup =
    _customerGroup ?? getValueFromPartnerCookie('partnerGroup')

  /**
   * Sets the banner to a loading state while it fetches the banner
   *
   * Indicates that we are in a partner experience with the hasPartnerBanner flag which blocks the sitewide banner
   */
  const setPartnerAtomAndFetchBanner = () => {
    pipe(
      partnerAtom,
      O.fold(
        () => voidFn(),
        atom => !atom.hasPartnerBanner && setPartnerAtomLoading(true)
      )
    )

    // query will be disabled unless partnerGroup and partnerName exist so the ??s are irrelevant
    return fetchCurrentPromotion(locale, {
      customerGroup: partnerGroup ?? '',
      partnerName: partnerName ?? ''
    })
  }

  /**
   * ReactQuery to fetch the partner banner
   * Blocked/Enabled by shouldRequestPartner
   */
  const { data, isSuccess } = useQuery({
    queryKey: ['partnerBanner', partnerName, partnerGroup],
    queryFn: setPartnerAtomAndFetchBanner,
    enabled: shouldRequestPartner,
    retry: false
  })

  /**
   * Sets the partner banner to a non-partner state if the partner details are not present
   */
  useEffect(() => {
    const hasPartnerDetails = !!partnerName && !!partnerGroup
    pipe(
      partnerAtom,
      O.fold(
        // we should request one if we don't have one
        () => hasPartnerDetails && setRequestPartner(true),
        () => {
          setRequestPartner(false)
        }
      )
    )
  }, [partnerGroup, partnerName])

  /**
   * Handles the response of the query
   *
   * Sets and returns the partner banner resposne appropriately
   */
  return useMemo(() => {
    return isSuccess
      ? pipe(
          data,
          E.match(
            error => {
              logError(
                Error(
                  `There was an error fetching the current promotion for the partner ${partnerGroup} / ${partnerName} - ${JSON.stringify(error, null, 4)}`
                )
              )
              return O.none
            },
            response => {
              const formattedBanner = O.of(formatPartnerBanner(response.banner))
              setPartnerAtomLoading(false)
              setPartnerPromoAtom(formattedBanner)
              return formattedBanner
            }
          )
        )
      : O.none
  }, [isSuccess])
}
