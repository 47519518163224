import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export function useTrackingScoutImagePagination() {
  const { trackEvent } = useTracking<TrackingData>()
  return useCallback(
    (index: number) => {
      trackEvent({
        event: 'scout-image-pagination',
        scoutImagePosition: index
      })
    },
    [trackEvent]
  )
}
