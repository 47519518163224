import {
  type CurrencyCode,
  LOCALE_INFO,
  localeInfo
} from '@simplisafe/ss-ecomm-data/commercetools/locale'

import type { Locale } from './types'

// this implementation uses en-GB as a fall back environment variable
const currencyCode: CurrencyCode = LOCALE_INFO.currency

export const getCurrencyFromLocale = (locale: Locale): CurrencyCode =>
  localeInfo[locale].currency

export default currencyCode
