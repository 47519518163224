import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useContext,
  useState
} from 'react'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { WarningIcon } from '@ecomm/checkout-icons'
import type { ErrorResponse } from '@ecomm/data-simplisafe-api'
import { logError } from '@ecomm/error-handling'
import {
  COOKIE_LEAD_DATA,
  getCookie,
  getLeadData,
  getValueFromLeadData,
  setCookie,
  updateLeadData
} from '@ecomm/shared-cookies'
import { CheckSolid } from '@ecomm/shared-icons'
import {
  ReferrerUrlContext,
  handleBrazeTrackingEvent,
  useLeadGenCaptureV2
} from '@ecomm/tracking'
import type { LeadGenV2ResponseProps } from '@ecomm/tracking/useLeadGenCaptureV2/leadGenCaptureV2'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import { PHONE_MIN_LENGTH, PhoneNumberFormSchema } from './phoneSchema'

function useHandlePhoneFormSubmission(
  setIsFormSubmitted: Dispatch<SetStateAction<boolean>>,
  setFormError: Dispatch<SetStateAction<boolean>>,
  setIsSubmitting: Dispatch<SetStateAction<boolean>>
) {
  const channel = useContext(ReferrerUrlContext).channel
  const onFail = (e: Error | ErrorResponse) => {
    e instanceof Error ? logError(e) : logError(new Error(`${e.url} ${e.body}`))
    setFormError(true)
    setIsSubmitting(false)
  }
  const onSuccess = async (response?: LeadGenV2ResponseProps) => {
    if (response?.leadId) {
      !getValueFromLeadData('leadId') &&
        updateLeadData({ leadId: response.leadId })

      const leadData = {
        externalId:
          getValueFromLeadData('externalId') || response?.externalId || '',
        phone: getValueFromLeadData('phone'),
        leadSource: 'Exit Intent',
        leadId: getValueFromLeadData('leadId') || response.leadId
      }

      await handleBrazeTrackingEvent(leadData, channel)
      return setIsFormSubmitted(true)
    } else {
      return setIsFormSubmitted(true)
    }
  }
  const leadGenCaptureV2 = useLeadGenCaptureV2(onFail, onSuccess)

  const handleSubmit = (phone: string) => {
    setIsSubmitting(true)
    setFormError(false)

    const leadData = {
      externalId: getValueFromLeadData('externalId') || '',
      phone,
      leadSource: 'Exit Intent'
    }
    !getCookie(COOKIE_LEAD_DATA)
      ? setCookie(COOKIE_LEAD_DATA, leadData, cookiesOption)
      : updateLeadData({ phone })

    leadGenCaptureV2({
      source: 'Exit Intent',
      phone,
      email: getValueFromLeadData('email') || ''
    })
  }

  return handleSubmit
}

export function PhoneLeadCaptureForm() {
  const defaultPhoneNumber = getLeadData()?.['phone'] || ''
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formError, setFormError] = useState(false)
  const handlePhoneFormSubmit = useHandlePhoneFormSubmission(
    setIsFormSubmitted,
    setFormError,
    setIsSubmitting
  )

  const handleSubmit = useCallback(
    (values: { readonly phone: string }) => {
      handlePhoneFormSubmit(values.phone)
    },
    [handlePhoneFormSubmit]
  )

  return (
    <>
      {!isFormSubmitted ? (
        <Formik
          enableReinitialize={true}
          initialValues={{ phone: defaultPhoneNumber }}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={toFormikValidationSchema(PhoneNumberFormSchema)}
        >
          {({ errors, handleChange, setFieldError, validateForm }) => (
            <Form data-component="PhoneLeadCaptureForm">
              <div className="flex [@media(min-height:578px)]:[@media(min-width:768px)]:items-end gap-4 [@media(min-height:578px)]:[@media(min-width:768px)]:gap-3 flex-col [@media(min-height:578px)]:[@media(min-width:768px)]:flex-row">
                <div className="flex w-full flex-col gap-1 [@media(min-height:578px)]:[@media(min-width:768px)]:w-[190px]">
                  <label
                    className="font-medium text-white text-sm"
                    htmlFor="lead-phoneNumber"
                  >
                    Phone number
                  </label>
                  <Field
                    className={classNames(
                      'border-neutral-medium-100 h-11 [@media(min-height:578px)]:[@media(min-width:768px)]:h-[51px] w-full rounded-xl border border-solid pl-[20px] pr-9 text-base lg:text-sm',
                      {
                        'bg-[#FFF0EF] border-[#881B0F]': errors.phone
                      }
                    )}
                    id="lead-phoneNumber"
                    maxLength="40"
                    name="phone"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFormError(false)
                      handleChange(e)
                      if (
                        e.target.value.length >= PHONE_MIN_LENGTH ||
                        /\D/.test(e.target.value)
                      ) {
                        validateForm({ phone: e.target.value })
                      } else {
                        setFieldError('phone', '')
                      }
                    }}
                    placeholder="(XXX) XXX-XXXX"
                    type="tel"
                  />
                  {errors.phone ? (
                    <WarningIcon className="absolute right-10 top-[52%] -translate-y-1/2 transform [@media(min-height:578px)]:[@media(min-width:768px)]:right-[48%] [@media(min-height:578px)]:[@media(min-width:768px)]:top-[71%]" />
                  ) : null}
                  <p className="text-[#F6C0B9] [@media(min-height:578px)]:[@media(min-width:768px)]:text-[#FFE8E5] m-0 min-h-[20px] p-0 pl-1 pt-0 text-xs truncate w-72 [@media(min-height:578px)]:[@media(min-width:768px)]:w-80">
                    {errors.phone}
                    {formError
                      ? 'An error has occurred. Please try again later.'
                      : null}
                  </p>
                </div>
                <button
                  className={classNames(
                    'cursor-pointer h-11 [@media(min-height:578px)]:[@media(min-width:768px)]:h-auto rounded-xl border-none p-4 text-base leading-4 hover:brightness-90 btn-solid-primary w-4/5 [@media(min-height:578px)]:[@media(min-width:768px)]:w-auto mx-auto self-start [@media(min-height:578px)]:[@media(min-width:768px)]:mt-6 disabled:bg-gray-300 disabled:hover:brightness-100 disabled:hover:cursor-auto',
                    errors.phone || formError ? 'mb-2 -mt-2' : 'mb-7 -mt-7'
                  )}
                  disabled={isSubmitting}
                  type="submit"
                >
                  Request a call
                </button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="border-[#47B8A5] border border-solid bg-[#DEF2ED] text-complementary-green-100 rounded text-[13px] [@media(min-height:578px)]:[@media(min-width:768px)]:text-sm font-bold flex px-1 [@media(min-height:578px)]:[@media(min-width:768px)]:px-3 [@media(min-height:578px)]:[@media(min-width:768px)]:mt-12 [@media(min-height:578px)]:[@media(min-width:768px)]:mb-[57px] mt-9 mb-[102px]">
          <CheckSolid className="[@media(min-height:578px)]:[@media(min-width:768px)]:h-6 [@media(min-height:578px)]:[@media(min-width:768px)]:w-6 w-5 h-5" />{' '}
          <span className="mt-[2px] [@media(min-height:578px)]:[@media(min-width:768px)]:ml-1">
            Success! A specialist will be in touch soon.
          </span>
        </div>
      )}
      <p className="text-white m-0 p-0 text-[8px] [@media(min-height:578px)]:[@media(min-width:768px)]:text-[10px] font-thin text-center opacity-95 -mt-1 [@media(min-height:578px)]:[@media(min-width:768px)]:mt-2">
        By adding your phone number and clicking ‘request a call’ you
        <br />
        agree to receive marketing phone calls or text messages, and
        <br />
        accept the details in our{' '}
        <a className="text-white" href="/legal/privacy-policy" target="blank">
          Privacy Policy
        </a>
        . You can opt-out
        <br />
        any time by responding STOP. Standard message
        <br />
        and data rates may apply.
      </p>
    </>
  )
}
