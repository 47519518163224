import { contentfulRichTextSchema } from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

import { Locale } from '../../hooks/useLocalizedData'

export const productDetailsSchema = z.object({
  columnLeft: contentfulRichTextSchema,
  columnRight: contentfulRichTextSchema
})

export const schema = {
  [Locale.EnGB]: productDetailsSchema,
  [Locale.EnUS]: productDetailsSchema
}

export type ProductDetailsSchema = TypeOf<typeof productDetailsSchema>
