import { useCartAddItem } from '@ecomm/data-cart'
import { IOAddToCart } from '@simplisafe/ss-ecomm-data/cart/actions'
import { window } from 'browser-monads-ts'
import { constNull } from 'fp-ts/lib/function'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useCurrentPromo } from '../useCurrentPromo'

/**
 * The responsibility of this hook is to check the validity of the UTM codes
 * and apply them to cart if necessary.
 *
 * @param utmCode string
 */
export function useCheckAndGetUtmCodes(utmCode: string, useJotaiCart: boolean) {
  const dispatch = useDispatch()
  const [validUtmCodes, setValidUtmCodes] = useState<readonly string[]>([])
  const { variationId } = useCurrentPromo()
  const jotaiCartAddItem = useCartAddItem([], variationId)

  useEffect(() => {
    // if there are multiple codes, split them around their delimiter
    const utmCodes: ReadonlyArray<string> = utmCode
      .trim()
      .split(',')
      .map(code => code.trim())
    const validUTMCodes = utmCodes.filter(x => !!x).map(y => y.toUpperCase())
    // once all codes have been checked, if at least 1 code is valid, create an empty cart for that code to be applied to
    if (!!utmCode && validUTMCodes.length > 0) {
      useJotaiCart
        ? jotaiCartAddItem(constNull)
        : dispatch(IOAddToCart({ products: [] }, constNull, constNull))
      return setValidUtmCodes(validUTMCodes)
    } else {
      // if no valid codes exist in utm string, clear local storage
      return window.localStorage.removeItem('utm_code')
    }
  }, [utmCode])

  return { hasUtmCode: !!utmCode, validUtmCodes }
}
