import type { RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { ContentfulRichText } from '@ecomm/shared-components'
import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import type { Output as ComponentProps } from '../../hooks/useLocalizedData'
import type { schema } from './schema'
import { useOnButtonClick } from './useOnButtonClick'

const descriptionCustomOptions: { readonly renderNode: RenderNode } = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <p className="text-[16px] font-light md:text-[18px] md:leading-[24px]">
        {children}
      </p>
    )
  }
}

export type ScoutProductPageDeadEndIIData = ComponentProps<typeof schema>

export function ScoutProductPageDeadEndII({
  data
}: {
  readonly data: ScoutProductPageDeadEndIIData
}) {
  const onButtonClick = useOnButtonClick()

  const handleOnClick = () => {
    onButtonClick()
    navigate(data.buttonLink)
  }

  return (
    <div
      className="lg:align-stretch flex w-full flex-col lg:flex-row"
      data-component="ScoutPageDeadEnd"
    >
      <div className="rounded-t-base h-2/4 w-full lg:h-auto lg:w-2/5">
        <GatsbyImage
          alt={data.image.description || ''}
          className="rounded-tl-base rounded-tr-base lg:rounded-bl-base lg:rounded-tl-base w-full object-contain object-center lg:h-full lg:rounded-tr-none"
          image={data.image.gatsbyImageData}
        />
      </div>
      <div className="lg:flex-start lg:align-start rounded-b-base bg-neutral-light-50 lg:rounded-e-base flex h-2/4 flex-col p-6 lg:h-auto lg:w-3/5 lg:justify-center lg:rounded-bl-none lg:p-12">
        <h2 className="m-0 text-2xl font-medium lg:text-5xl">
          {data.headline}
        </h2>
        <ContentfulRichText
          optionsCustom={descriptionCustomOptions}
          raw={data.body?.raw}
        />
        <button
          className="mb-4 lg:!w-48 btn btn-solid-primary"
          onClick={handleOnClick}
        >
          {data.buttonText}
        </button>
      </div>
    </div>
  )
}
