import { useMemo } from 'react'

import { useCurrentPromo } from '../useCurrentPromo'

/**
 * Returns the discount amount in 'x off' format unless override data exists.
 *
 * @returns string
 */
export function useSitewidePromoOfferTitle(): string {
  const { offerTitle, overrideBannerText } = useCurrentPromo()

  return useMemo(() => {
    return overrideBannerText || offerTitle
  }, [offerTitle, overrideBannerText])
}
