import type { CommercetoolsProduct } from '@ecomm/data-simplisafe-api'
import { addAtomDebugLabel } from '@ecomm/utils'
import { selectProduct } from '@simplisafe/ss-ecomm-data/redux/select'
import * as O from 'fp-ts/lib/Option'
import { atom, useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import type { ContentfulProductTopic } from './productTopicSchema'
import { useProducts } from './useProducts'
/**
 * Looks up a product in redux state by SKU.
 */
export const useProduct = (sku: string, reportError?: boolean) => {
  const product = useSelector(selectProduct(sku, reportError))
  return product
}

/**
 * @deprecated We didn't go with this pattern
 */
export const useProductDeprecated = (
  sku: string
): O.Option<
  Omit<CommercetoolsProduct, 'name'> & {
    readonly topic: O.Option<ContentfulProductTopic>
  }
> => {
  const [products] = useProducts()
  const productAtom = useMemo(() => {
    const _atom = atom(O.fromNullable(products.get(sku)))
    addAtomDebugLabel(_atom, `product - ${sku}`)
    return _atom
  }, [products])
  const product = useAtomValue(productAtom)
  return product
}
