import {
  cartSetShippingAddress,
  cartSetShippingMethod,
  getShippingMethods
} from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import type { Address } from '@simplisafe/eis-commercetools-carts'
import { voidFn } from '@simplisafe/ewok'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useCallback } from 'react'

import { useSetCart } from '../atoms/useCart'
import { useSetShippingMethodState } from '../atoms/useShippingMethodState'

/**
 * Set the shipping address for a jotai cart
 *
 * This function is a bit more complex than the name suggests, so here's a quick rundown:
 * 1. Persist shipping address changes to the cart, which is reflected in the Jotai state
 * 2. Fetch an updated list of shipping methods based on the new address
 * 3. If the cart does not have a shipping method set, initialize it with the first method in the list
 *
 * Step 3 also accounts for situations when a cart's shipping method is defined but is no longer applicable.
 */
export const useCartSetShippingAddress = (variationId: string) => {
  const cartId = getCartId()
  const setCart = useSetCart()
  const setShippingMethods = useSetShippingMethodState()

  return useCallback(
    (address: Address) => {
      const initShippingMethod = async (cid: string, methodId: string) => {
        setCart(['set_cart_updating'])

        const cart = await cartSetShippingMethod(cid, methodId)()

        pipe(
          cart,
          E.fold(
            err => setCart(['set_cart_error', err]),
            t => {
              setCart(['update_cart', t])
            }
          )
        )
      }

      const fetchShippingMethods = async (
        cid: string,
        rateInput: number,
        cartMethodId: string | null
      ) => {
        setShippingMethods(['set_shipping_methods_updating'])

        const shippingMethods = await getShippingMethods(cid, rateInput)()

        pipe(
          shippingMethods,
          E.fold(
            err => setShippingMethods(['set_shipping_methods_error', err]),
            t => {
              setShippingMethods(['update_shipping_methods', t])

              const firstMethod = t.at(0)
              const shouldSetMethod =
                firstMethod &&
                (cartMethodId === null || t.every(sm => sm.id !== cartMethodId))
              shouldSetMethod && initShippingMethod(cid, firstMethod.id)
            }
          )
        )
      }

      const setShippingAddress = async (id: string) => {
        setCart(['set_cart_updating'])

        const cart = await cartSetShippingAddress(id, address, variationId)()

        pipe(
          cart,
          E.fold(
            err => setCart(['set_cart_error', err]),
            t => {
              setCart(['update_cart', t])
              fetchShippingMethods(id, t.shippingRateInput, t.shippingMethod.id)
            }
          )
        )
      }

      pipe(
        O.fromNullable(cartId),
        O.fold(
          () => voidFn(),
          id => setShippingAddress(id)
        )
      )
    },
    [cartId, setCart, setShippingMethods, variationId]
  )
}
