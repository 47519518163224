import type { PartnerBanner, RawPartnerBanner } from './partnerBannerTypes'

export const formatPartnerBanner = (
  partnerBanner: RawPartnerBanner
): PartnerBanner => ({
  backgroundColor: partnerBanner.backgroundColor ?? '',
  discountSecondaryText: partnerBanner.discountSecondaryText ?? '',
  displayEmailInputField: partnerBanner.displayEmailInputField ?? false,
  linkPath: partnerBanner.linkPath ?? '',
  logoDescription: partnerBanner.logo?.fields?.description ?? '',
  logoUrl: partnerBanner.logo?.fields?.file?.url ?? '',
  partnerGroup: partnerBanner.partnerGroup ?? '',
  partnerName: partnerBanner.partnerName ?? '',
  partnerPromoCodes: partnerBanner.partnerPromoCodes ?? [],
  primaryOfferText: partnerBanner.primaryOfferText ?? '',
  primaryTextColor: partnerBanner.primaryTextColor ?? '',
  secondaryOfferText: partnerBanner.secondaryOfferText ?? '',
  secondaryOfferTextMobile: partnerBanner.secondaryOfferTextMobile ?? '',
  secondaryTextColor: partnerBanner.secondaryTextColor ?? '',
  hasPartnerBanner: !!(partnerBanner.partnerGroup || partnerBanner.partnerName)
})
