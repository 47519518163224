import { type TypeOf, z } from '@simplisafe/ewok'

export enum buttonTypes {
  customPlainText = 'Custom Plain',
  disabled = 'Disabled',
  iconOnly = 'Icon Only',
  link = 'Link',
  primary = 'Primary',
  primaryHollow = 'Primary Hollow',
  primaryOutline = 'Primary Outline',
  promotion = 'Promotion',
  secondary = 'Secondary',
  secondaryOutline = 'Secondary Outline',
  tertiary = 'Tertiary',
  tertiaryOutline = 'Tertiary Outline',
  anchorNav = 'Anchor Nav'
}

export const buttonSchema = z.object({
  __typename: z.literal('ContentfulButton'),
  contentful_id: z.string(),
  url: z.string(),
  // TODO: remove buttonText and update references since it doesn't exist in Content model
  buttonText: z.string(),
  type: z.enum([
    buttonTypes.primary,
    buttonTypes.secondary,
    buttonTypes.tertiary,
    buttonTypes.primaryOutline,
    buttonTypes.secondaryOutline,
    buttonTypes.tertiaryOutline,
    buttonTypes.primaryHollow,
    buttonTypes.link,
    buttonTypes.iconOnly,
    buttonTypes.promotion,
    buttonTypes.anchorNav,
    buttonTypes.customPlainText,
    buttonTypes.disabled
  ])
})

const idSchema = z.object({
  id: z.string()
})

export const apolloButtonSchema = z.object({
  sys: idSchema,
  id: idSchema.transform(({ id }) => id),
  __typename: z.literal('Button'),
  buttonText: z.string().optional(),
  url: z.string(),
  type: z.enum([
    buttonTypes.primary,
    buttonTypes.secondary,
    buttonTypes.tertiary,
    buttonTypes.primaryOutline,
    buttonTypes.secondaryOutline,
    buttonTypes.tertiaryOutline,
    buttonTypes.primaryHollow,
    buttonTypes.link,
    buttonTypes.iconOnly,
    buttonTypes.promotion,
    buttonTypes.anchorNav,
    buttonTypes.customPlainText,
    buttonTypes.disabled
  ])
})

export type ButtonFragment = TypeOf<typeof buttonSchema>
