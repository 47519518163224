import { GatsbyImage, type GatsbyImageSchema } from '@ecomm/shared-components'
import { Star } from '@ecomm/shared-icons'
import React from 'react'

type Props = {
  readonly logo: GatsbyImageSchema
  readonly stars: string
  readonly ratings: string
  readonly url: string
}

function RatingBar({ logo, stars, ratings, url }: Props) {
  return (
    <div className="m-0 align-middle">
      <div className="space-between flex flex-row">
        <a className="rounded-sm" href={url} rel="noreferrer" target="_blank">
          <GatsbyImage
            className="w-23 m-2 md:m-1 md:w-24"
            image={logo}
            imgStyle={{ objectFit: 'contain' }}
          />
        </a>
        <div className="m-auto flex flex-row align-middle md:px-3">
          <Star alt="Star Icon" className="h-4 w-4 text-primary-100" />
          <Star
            alt="Star Icon"
            className="hidden h-4 w-4 object-fill text-primary-100 md:flex"
          />
          <Star
            alt="Star Icon"
            className="hidden h-4 w-4 object-fill text-primary-100 md:flex"
          />
          <Star
            alt="Star Icon"
            className="hidden h-4 w-4 object-fill text-primary-100 md:flex"
          />
          <Star
            alt="Star Icon"
            className="hidden h-4 w-4 object-fill text-primary-100 md:flex"
          />
        </div>
        <p className="m-auto font-bold md:text-sm md:text-white">{stars}</p>
        <p className="m-auto mx-2 text-xl font-bold md:mx-4 md:text-white">•</p>
        <p className="m-auto md:text-sm md:text-white">{ratings}</p>
      </div>
    </div>
  )
}

export default RatingBar
