import type {
  CheckoutEvent,
  EcommEventBase,
  ProductAddedEvent,
  ProductRemovedEvent,
  PurchaseEvent
} from '@ecomm/cdp-tracking-utils'
import { getRudderstack } from '@ecomm/shared-window'

/**
 * Track product added to Cart - GA4 compatible (add_to_cart) ecommerce event.
 */
export function trackProductAdded(args: ProductAddedEvent) {
  getRudderstack(r =>
    r.track('Product Added', {
      cart_id: args.cartId,
      currency: args.currency,
      total: args.total,
      products: args.products,
      coupon: args.coupon,
      email: args.email
    })
  )
}

/**
 * Track product removed from Cart - GA4 compatible (remove_from_cart) ecommerce event.
 */
export function trackProductRemoved(args: ProductRemovedEvent) {
  getRudderstack(r =>
    r.track('Product Removed', {
      cart_id: args.cartId,
      currency: args.currency,
      total: args.total,
      products: args.products,
      coupon: args.coupon
    })
  )
}

/**
 *
 * Track cart viewed - GA4 compatible (view_cart) ecommerce event.
 *
 * NOTE: (5/31/24) - Sending this event with a serialized empty products array will fail to deliver from Rudder
 * to GA4. This is a known issue with Rudderstack.
 */
export function trackCartViewed(args: EcommEventBase) {
  getRudderstack(r => r.track('Cart Viewed', args))
}

/**
 *
 * Track checkout started - GA4 compatible (begin_checkout) ecommerce event.
 *
 * NOTE: (5/31/24) - We currently "over-use" this GA4 event to have multiple semantic meanings in Checkout.
 * In the future, instead of multiplexing the use of this, usage of the Checkout Step Completed Rudderstack
 * Ecommerce events will be used.
 */
export function trackCheckoutStarted(args: CheckoutEvent) {
  getRudderstack(r =>
    r.track('Checkout Started', {
      originalEvent: args.originalEvent,
      cart_id: args.cartId,
      currency: args.currency,
      total: args.total,
      products: args.products,
      coupon: args.coupon
    })
  )
}

/**
 * Track order completed - GA4 compatible (purchase) ecommerce event.
 */
export function trackOrderCompleted(args: PurchaseEvent) {
  getRudderstack(r => r.track('Order Completed', args))
}
