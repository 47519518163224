import { useCurrentPromo } from '../useCurrentPromo'

/**
 *
 * Checks if the top promo banner is currently visible.
 *
 * @example
 * const isPromoTopBanner = useTopBannerVisible() // => boolean
 *
 * @deprecated - this property is now available directly from the useCurrentPromo() hook
 */
export const useTopBannerVisible = () => {
  const { isTopBannerVisible } = useCurrentPromo()
  return isTopBannerVisible
}
