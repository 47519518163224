import type { ContentfulRichTextGatsbyReference } from 'gatsby-source-contentful/rich-text'

import { isObject } from '@simplisafe/ewok'
import { contentfulRichTextSchema } from './contentfulRichTextSchema'

/**
 * @deprecated - Gatsby 4
 * use contentfulRichTextSchema instead
 */
export const jsonSchema = (__typename: string) => contentfulRichTextSchema

export function isContentfulRichTextGatsbyReference(
  t: ContentfulRichTextGatsbyReference | unknown
): t is ContentfulRichTextGatsbyReference {
  if (isObject(t)) {
    return '__typename' in t && 'contentful_id' in t
  } else {
    return false
  }
}
