import type { WizardType } from '@ecomm/cdp-tracking'
import { pushToDataLayer } from '@ecomm/shared-window'

import type { TrackingData } from '../types/tracking'

type AugmentedTrackingData = Omit<TrackingData, 'wizardType'> & {
  readonly wizardType?: WizardType
}

export const handleGTMTrackingEvent = (data: AugmentedTrackingData) => {
  // It's not explained very well in the GTM docs, but when GTM populates its internal representation of the data
  // layer it recursively merges the properties (alluded to in https://developers.google.com/tag-manager/devguide)
  // Basically this means data layer values stick around until overwritten. We've gotta decide what to do about this.
  // We could manually clear the data layer every time we push, or try to determine on a per-property basis whether to
  // set null or undefined values for properties that shouldn't be persisted.

  const augmentedData: AugmentedTrackingData = {
    ...data,
    ...(data.appSection && { appSection: data.appSection }),
    ...(data.wizardType && { wizardType: data.wizardType })
  }

  return pushToDataLayer(augmentedData)()
}
