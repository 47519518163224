import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

import safeProp from '../safe/safeProp'
import type { VariationFragment } from './types'

/**
 * Get's the experiment key from a fragment containing variations.
 *
 * @package internal use only
 */
function getexperimentKey<T>(fragment?: VariationFragment<T>): string {
  return pipe(
    O.fromNullable(fragment),
    O.chain(safeProp('variations')),
    O.chain(safeProp('experimentKey')),
    O.getOrElse(() => '')
  )
}

export default getexperimentKey
