import type { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { fetchUserShippingAddress } from '@ecomm/data-simplisafe-api'
import { useYodaValue } from '@ecomm/shared-cookies'
import { states } from '@ecomm/utils'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import * as A from 'fp-ts/lib/ReadonlyArray'
import { pipe } from 'fp-ts/lib/function'
import { useEffect, useState } from 'react'

const getStateAbbreviation = (stateName: string) => {
  const stateInfo = states.find(
    state => state.name.toLowerCase() === stateName.toLowerCase()
  )
  return stateInfo?.abbreviation ?? stateName
}

type PrefillFields = Pick<
  CheckoutFormValuesType,
  | 'additionalStreetInfo'
  | 'city'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'phone'
  | 'postalCode'
  | 'state'
  | 'streetName'
>

/**
 * Fetches a logged in user's saved shipping address, transformed into
 * the format used to prefill checkout form data.
 */
export function useYodaAddress() {
  const yoda = useYodaValue()
  const [address, setAddress] = useState<PrefillFields | null>(null)

  useEffect(() => {
    const fn = async (email: string) => {
      const teAddresses = await fetchUserShippingAddress()()
      const firstAddress = pipe(
        teAddresses,
        E.map(A.head),
        E.fold(
          () => O.none,
          o => o
        ),
        O.map(addr => ({
          additionalStreetInfo: addr.street2,
          city: addr.city,
          email: email,
          firstName: addr.firstName,
          lastName: addr.lastName,
          phone: addr.phone,
          postalCode: addr.zip,
          state: getStateAbbreviation(addr.state),
          streetName: addr.street1
        })),
        O.getOrElse<PrefillFields | null>(() => null)
      )
      setAddress(firstAddress)
    }
    yoda !== null && fn(yoda.email)
  }, [yoda])

  return address
}
