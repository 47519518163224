import { useFormikContext } from 'formik'
import type React from 'react'
import { useCallback } from 'react'
import { FormCheckbox } from '../FormCheckbox'
import { FormField } from '../FormField'
import { FormTextInput } from '../FormTextInput'
import type { EmailValueType } from '../ModalFormSchema'

export function Email() {
  const {
    values: { emailData },
    setFieldValue
  } = useFormikContext<{ readonly emailData: EmailValueType }>()

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue('emailData.emailCheckbox', event.target.checked)
      !event.target.checked && setFieldValue('emailData.email', '')
    },
    [setFieldValue]
  )

  return (
    <div className="flex w-full flex-col">
      <FormField
        data-testid="email-lead-capture-formfield"
        id="email-formfield"
      >
        <FormCheckbox
          checked={emailData?.emailCheckbox}
          label="Email Address"
          name="emailData.emailCheckbox"
          onChange={handleCheckboxChange}
        />
      </FormField>
      {emailData?.emailCheckbox ? (
        <FormField id="emailData.email">
          <label className="sr-only" htmlFor="email">
            Email Address
          </label>
          <FormTextInput
            className="w-full"
            id="email"
            name="emailData.email"
            placeholder=""
            type="email"
          />
        </FormField>
      ) : null}
    </div>
  )
}
