import {
  AFFIRM_PROMO_ID,
  AFFIRM_PROMO_ID_VARIANT,
  AFFIRM_PROMO_NO_SYSTEM,
  AFFIRM_QUALIFIED_MONTHLY
} from '@ecomm/data-constants'
import { getAffirm } from '@ecomm/shared-window'
import { calculatePMT } from '@ecomm/utils/pmt'
import classNames from 'classnames'
import { useEffect } from 'react'

import {
  AFFIRM_TERM_MONTHS_DOOR_TO_DOOR,
  DOOR_TO_DOOR_APR
} from '@ecomm/door-to-door-constants/affirm'
import { D2DPartnerGroup } from '@ecomm/door-to-door-constants/cookies'
import { getPartnerCookie } from '@ecomm/shared-cookies'
import { formatPrice } from '@simplisafe/eis-commercetools-shared'
import type { AffirmOptionType } from '../../../types/affirm'

const toPriceInCents = (price: number) => Math.round(price * 100)

type Props = {
  readonly discountedPrice?: number
  readonly totalPrice: number
  readonly isPlp?: boolean
  readonly priceInCents?: number
  readonly affirmOption: AffirmOptionType
  readonly useSystemTermMonths?: boolean
  readonly isAffirmExperience?: boolean
}

export function MonthlyOptionPrice({
  discountedPrice = 0,
  totalPrice,
  isPlp = false,
  affirmOption,
  useSystemTermMonths = true,
  isAffirmExperience = false
}: Props) {
  const getMonthlyPromoId = (isMonthlyExperiment: boolean) =>
    isMonthlyExperiment ? AFFIRM_PROMO_ID_VARIANT : AFFIRM_PROMO_ID

  const promoId = useSystemTermMonths
    ? getMonthlyPromoId(isAffirmExperience)
    : AFFIRM_PROMO_NO_SYSTEM

  useEffect(() => {
    getAffirm(affirm => {
      affirm.ui.ready(() => {
        // TODO: affirm + optimizely have a race condition so we need to wait until we know if it is an experiment or not until we run affirm refresh script
        setTimeout(() => {
          affirm.ui.refresh()
        }, 3000)
      })
    })
  }, [isAffirmExperience])

  const hasDiscountedPrice = !!discountedPrice && discountedPrice > 0
  const partnerCookie = getPartnerCookie()

  return partnerCookie?.partnerGroup === D2DPartnerGroup ? (
    <div
      className="pointer-events-none flex flex-row gap-1 font-bold text-lg"
      data-component="DoorToDoorMonthlyOptionPrice"
      data-testid="DoorToDoorMonthlyOptionPrice"
    >
      <span className="text-sale">
        {formatPrice(
          calculatePMT(
            DOOR_TO_DOOR_APR / AFFIRM_TERM_MONTHS_DOOR_TO_DOOR,
            AFFIRM_TERM_MONTHS_DOOR_TO_DOOR,
            toPriceInCents(discountedPrice)
          ),
          'en-US'
        )}
      </span>
      <span
        className={`affirm-as-low-as text-neutral-black ${
          affirmOption === AFFIRM_QUALIFIED_MONTHLY && !!discountedPrice
            ? 'line-through'
            : ''
        }`}
      >
        {formatPrice(
          calculatePMT(
            DOOR_TO_DOOR_APR / AFFIRM_TERM_MONTHS_DOOR_TO_DOOR,
            AFFIRM_TERM_MONTHS_DOOR_TO_DOOR,
            toPriceInCents(totalPrice)
          ),
          'en-US'
        )}
      </span>
    </div>
  ) : (
    <div
      className={classNames(
        'pointer-events-none flex flex-row gap-1 font-bold',
        {
          'text-lg': !isPlp,
          '!-mt-0 text-base': isPlp
        }
      )}
    >
      {hasDiscountedPrice ? (
        <div
          className="affirm-as-low-as monthlyDiscount"
          data-amount={toPriceInCents(discountedPrice)}
          data-component="AffirmMonthlyDiscount"
          data-page-type="marketplace"
          data-promo-id={promoId}
        ></div>
      ) : null}
      <div
        className={`affirm-as-low-as text-neutral-black ${
          affirmOption === AFFIRM_QUALIFIED_MONTHLY &&
          discountedPrice !== 0 &&
          discountedPrice !== null
            ? 'line-through'
            : ''
        }`}
        data-amount={toPriceInCents(totalPrice)}
        data-component="AffirmMonthlyPrice"
        data-page-type="marketplace"
        data-promo-id={promoId}
      ></div>
    </div>
  )
}
