import { useCartLineItems } from '@ecomm/data-cart'
import { useLocale } from '@ecomm/data-hooks'
import { subspaceBrazeRequest } from '@simplisafe/ss-ecomm-data/leads/subspace'
import { useCallback } from 'react'

import { voidFn } from '@simplisafe/ewok'
import {
  brazeLogCustomEvent,
  setFirstName,
  setLastName,
  setPhoneNumber
} from '../../braze'
import { populateBrand, useCTProducts } from '../populateBrand'
import { lineItemToBrazeLineItem } from './lineItemToBrazeLineItem'

type UserData = {
  readonly email: string
  readonly firstName: string
  readonly lastName: string
  readonly leadId?: string
  readonly externalId: string
  readonly phone: string
}

const setCustomBrazeProfileData = ({
  email,
  firstName,
  lastName,
  leadId,
  externalId,
  phone
}: UserData) => {
  brazeLogCustomEvent('cart_abandon', { email, leadId, externalId, phone })
  setFirstName(firstName)
  setLastName(lastName)
  setPhoneNumber(phone)
}

export function useSendCartToBraze() {
  const locale = useLocale()

  const lineItems_ = useCartLineItems()
  const ctProducts = useCTProducts(lineItems_)
  const lineItems = populateBrand(lineItems_, ctProducts, locale)

  return useCallback(
    (user: UserData) => {
      locale === 'en-US' && setCustomBrazeProfileData(user)

      const brazeLineItems = lineItems.map(lineItemToBrazeLineItem(locale))

      subspaceBrazeRequest({
        attributes: {
          last_checkout_cart: brazeLineItems
        },
        events: [],
        locale,
        userData: {
          emailAddress: user.email,
          externalId: user.externalId
        }
      })(() => voidFn)(() => voidFn)
    },
    [lineItems, locale]
  )
}
