import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

export const validatePhoneNumber = (
  value: string | undefined,
  region: 'GB' | 'US'
) => {
  if (!value || value.length < 9) {
    return false
  } else {
    return parsePhoneNumberFromString(value, region)?.isValid() || false
  }
}

export const usPhoneSchema = z
  .string()
  .min(10, { message: 'Phone Number must contain exactly 10 digits' })
  .max(10, { message: 'Phone Number must contain exactly 10 digits' })
  .regex(/^\d+$/, { message: 'Phone Number must contain only numbers' })
  .trim()
  .refine(
    val => validatePhoneNumber(val, 'US'),
    () => ({
      message:
        'Invalid phone number. Please double-check and re-enter the number.'
    })
  )
  .optional()

export const emailSchema = z
  .string()
  .regex(/^[a-zA-Z0-9@._+-]+$/, {
    message: 'Email address contains invalid characters.'
  })
  .regex(/@/, {
    message: 'Email address must contain an "@" symbol.'
  })
  .email('Invalid email address')
  .optional()

export const EmailSchemaBase = z
  .object({
    email: emailSchema,
    emailCheckbox: z.boolean()
  })
  .refine(
    data => !data.emailCheckbox || (data.emailCheckbox && data.email?.trim()),
    {
      message: 'Email Address is required if the checkbox is selected.',
      path: ['email']
    }
  )

const PhoneSchemaBase = z.object({
  phone: usPhoneSchema,
  phoneCheckbox: z.boolean()
})

export const ModalFormSchemaBase = z.object({
  phoneData: z
    .object({
      phoneCheckbox: z.boolean(),
      phone: usPhoneSchema.optional()
    })
    .refine(
      data => !data.phoneCheckbox || (data.phoneCheckbox && data.phone?.trim()),
      {
        message: 'Phone Number is required if the checkbox is selected.',
        path: ['phone']
      }
    ),
  emailData: z
    .object({
      emailCheckbox: z.boolean(),
      email: emailSchema.optional()
    })
    .refine(
      data => !data.emailCheckbox || (data.emailCheckbox && data.email?.trim()),
      {
        message: 'Email Address is required if the checkbox is selected.',
        path: ['email']
      }
    )
})

export const validationSchema = toFormikValidationSchema(ModalFormSchemaBase)
export type EmailValueType = z.infer<typeof EmailSchemaBase>
export type PhoneValueType = z.infer<typeof PhoneSchemaBase>
export type ModalFormValuesType = z.infer<typeof ModalFormSchemaBase>
