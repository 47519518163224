import { useCurrentPromo } from '../useCurrentPromo'

export function useFormStyles() {
  const { buttonBackgroundColor, buttonTextColor, primaryTextColor } =
    useCurrentPromo()

  const buttonTextClass = buttonTextColor
    ? 'text-[--promo-button-text-color]'
    : 'text-white'
  const buttonBgClass = buttonBackgroundColor
    ? 'bg-[--promo-button-bg]'
    : 'bg-[#0f2544]'
  const buttonStyles = `${buttonTextClass} ${buttonBgClass}`

  const textStyles = primaryTextColor
    ? `text-[--promo-primary-text]`
    : 'text-[#0f2544]'

  return { buttonStyles, textStyles }
}
