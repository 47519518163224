import { useVideoTracking } from '@ecomm/cdp-tracking'
import { useTrackingVideo } from '@ecomm/tracking'
import classNames from 'classnames'
import type React from 'react'
import { type ReactHTMLElement, useRef } from 'react'

type HTMLVideoProps = Partial<ReactHTMLElement<HTMLVideoElement>>['props']
type EventProps = React.SyntheticEvent<HTMLVideoElement, Event>

export type VideoProps = HTMLVideoProps & {
  readonly videoSrcUrlWebm: string
  readonly videoSrcUrlMp4: string
  readonly ref?: React.LegacyRef<HTMLVideoElement> | undefined
  readonly key?: React.Key
  readonly className?: string
  readonly containerClassName?: string
  readonly captionsSrcUrl?: string | null
}

export function NativeVideo({
  ref = null,
  videoSrcUrlWebm,
  videoSrcUrlMp4,
  onPlay,
  onPause,
  onEnded,
  onTimeUpdate,
  containerClassName = '',
  key = 'video-element',
  captionsSrcUrl = '',
  className = '',
  autoPlay = false,
  ...rest
}: VideoProps) {
  const customButtonRef = useRef<HTMLVideoElement>(null)
  const {
    handleTrackingVideoEnded,
    handleTrackingVideoPause,
    handleTrackingVideoPlay,
    handleTrackingVideoTimeUpdate
  } = useTrackingVideo()

  const {
    trackVideoPlay,
    trackVideoPause,
    trackVideoComplete,
    trackVideoProgress
  } = useVideoTracking()

  const onPlayVideo = (event: EventProps) => {
    handleTrackingVideoPlay(event)
    trackVideoPlay(event)
    onPlay && onPlay(event)
  }

  const onPauseVideo = (event: EventProps) => {
    handleTrackingVideoPause(event)
    trackVideoPause(event)
    onPause && onPause(event)
  }

  const onVideoEnded = (event: EventProps) => {
    handleTrackingVideoEnded(event)
    trackVideoComplete(event)
    onEnded && onEnded(event)
  }

  const onVideoTimeUpdate = (event: EventProps) => {
    handleTrackingVideoTimeUpdate(event)
    trackVideoProgress(event)
    onTimeUpdate && onTimeUpdate(event)
  }

  return (
    <div
      className={classNames(
        'rounded-base relative overflow-hidden',
        containerClassName
      )}
      key={key}
    >
      <video
        autoPlay={autoPlay}
        className={classNames('block w-full', className)}
        controls
        controlsList="nodownload nopictureinpicture noplaybackrate nospeed"
        crossOrigin="anonymous"
        data-component="native-video"
        disablePictureInPicture
        disableRemotePlayback
        muted
        ref={ref || customButtonRef}
        {...rest}
        onEnded={onVideoEnded}
        onPause={onPauseVideo}
        onPlay={onPlayVideo}
        onTimeUpdate={onVideoTimeUpdate}
      >
        <source src={videoSrcUrlWebm} type="video/webm" />
        <source src={videoSrcUrlMp4} type="video/mp4" />
        {captionsSrcUrl ? (
          <track default kind="captions" src={captionsSrcUrl} srcLang="en" />
        ) : null}
      </video>
    </div>
  )
}
