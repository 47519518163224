import type {
  DocumentNode,
  QueryOptions,
  TypedDocumentNode
} from '@apollo/client/index.js'
import { logError } from '@ecomm/error-handling'
import { safeParse } from '@simplisafe/ewok'
import * as E from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import type { ZodSchema, ZodTypeDef } from 'zod'
import { useContentful } from './useContentful'
/**
 * A hook that wraps `useContentful` and parses the response with a Zod schema and returns an Either.
 * This will not  throw or cause an error boundary, and it should be used when you want to handle the error gracefully.
 *
 * Already handles logging the error for you before returning the Either.
 */
export const useSafeContentful = <T, U>(
  /** A Graphql query */
  QUERY: DocumentNode | TypedDocumentNode,
  /**
   * The key on the response that you want to parse.
   *
   * For example, if you are querying for a `blog` post, the response from Contentful would look like `data.blog`, so you would pass `blog` as the `queryKey`.
   */
  queryKey: string,
  /**
   * A Zod schema to parse the response with.
   */
  schema: ZodSchema<T, ZodTypeDef, U>,
  /** Any variables that might be needed for the query */
  variables?: QueryOptions['variables']
) => {
  const response = useContentful(QUERY, variables)

  return pipe(
    safeParse(response.data[queryKey], schema, { path: [queryKey] }),
    E.mapLeft(err => {
      logError(err)
      return err
    })
  )
}

/**
 * Similar to `useSafeContentful`, but with a fallback value in case of an error.
 * Errors are sent to New Relic.
 *
 * This is useful if you just want to gracefully fallback to a default, but want to track if there was an error.
 */
export const useSafeContentfulWithFallback = <T, U>(
  /** A Graphql query */
  QUERY: DocumentNode | TypedDocumentNode,
  /**
   * The key on the response that you want to parse.
   *
   * For example, if you are querying for a `blog` post, the response from Contentful would look like `data.blog`, so you would pass `blog` as the `queryKey`.
   */
  queryKey: string,
  /**
   * A Zod schema to parse the response with.
   */
  schema: ZodSchema<T, ZodTypeDef, U>,

  fallback: T,
  /** Any variables that might be needed for the query */
  variables?: QueryOptions['variables']
): T => {
  const response = useSafeContentful(QUERY, queryKey, schema, variables)

  return pipe(
    response,
    E.match(
      err => {
        logError(err)
        return fallback
      },
      data => data
    )
  )
}
