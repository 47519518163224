import { type Dispatch, type SetStateAction, useContext } from 'react'

import type { ErrorResponse } from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { D2DAgentIdCookie } from '@ecomm/door-to-door-constants/cookies'
import { logError } from '@ecomm/error-handling'
import {
  COOKIE_LEAD_DATA,
  getCookie,
  getPartnerCookie,
  getValueFromLeadData,
  setCookie,
  updateLeadData
} from '@ecomm/shared-cookies'
import {
  ReferrerUrlContext,
  addUserToSMSSubscriptionGroup,
  brazeLogCustomEvent,
  brazeSetCustomUserAttributes,
  handleBrazeTrackingEvent,
  useLeadGenCaptureV2
} from '@ecomm/tracking'
import type {
  LeadGenV2ResponseProps,
  SourceType
} from '@ecomm/tracking/useLeadGenCaptureV2/leadGenCaptureV2'
import { cookiesOption } from '@simplisafe/ss-ecomm-data/simplisafe/yodaClient'
import type { ModalFormValuesType } from '../ModalFormSchema'

type LeadGenParamsType = {
  readonly source: string
  readonly siteMetadata: {
    readonly sourceType: SourceType
  }
  readonly phone?: string
  readonly email?: string
}

/**
 * Creates the checkout link for the cart, supports both prod and non-prod environments
 * @returns
 */
function getCheckoutLink(cartId: string, agentId: string) {
  if (process.env['DEPLOY_ENV'] !== 'prd') {
    const url = `${globalThis.location.origin}/cart/d2d/${agentId}/${cartId}/checkout`
    return url
  } else {
    return `https://simplisafe.com/cart/d2d/${agentId}/${cartId}/checkout`
  }
}

export function useHandlePhoneEmailFormSubmission(
  setIsFormSubmitted: Dispatch<SetStateAction<boolean>>,
  setFormError: Dispatch<SetStateAction<boolean>>,
  setIsSubmitting: Dispatch<SetStateAction<boolean>>
) {
  const channel = useContext(ReferrerUrlContext).channel
  const onFail = (e: Error | ErrorResponse) => {
    e instanceof Error ? logError(e) : logError(new Error(`${e.url} ${e.body}`))
    setFormError(true)
    setIsSubmitting(false)
  }
  const onLeadCaptureSuccess = async (response?: LeadGenV2ResponseProps) => {
    const cartId = getCartId() ?? ''
    const agentId = getCookie(D2DAgentIdCookie)
    const d2dCheckoutLink = getCheckoutLink(cartId, agentId)

    if (response?.leadId && cartId && agentId) {
      !getValueFromLeadData('leadId') &&
        updateLeadData({ leadId: response.leadId })

      const leadSource = getPartnerCookie()
        ?.partnerName.toLowerCase()
        .includes('cydcor')
        ? 'cydcor'
        : 'field sales'
      const leadData = {
        // Fall back to LeadId if externalId is not returned, handles the case where user submits phone only lead
        externalId: response.externalId ?? `L${response.leadId}`,
        phone: getValueFromLeadData('phone') || '',
        email: response.email || getValueFromLeadData('email') || '',
        leadSource,
        leadId: response.leadId
      }
      await handleBrazeTrackingEvent(leadData, channel)
        .then(() => {
          addUserToSMSSubscriptionGroup()
          // Fire up custom Braze event that include checkout URL
          brazeLogCustomEvent('D2D_Rep_Quoted', { url: d2dCheckoutLink })
          //Set custom attribute with Quote URL
          brazeSetCustomUserAttributes({ D2D_Cart_URL: d2dCheckoutLink })
          return setIsFormSubmitted(true)
        })
        .catch(e => {
          logError(e)
          onFail(e)
        })
    } else {
      return onFail(
        new Error(
          'Unable to submit lead to braze: missing leadId, cartId, or agentId'
        )
      )
    }
  }
  const leadGenCaptureV2 = useLeadGenCaptureV2(onFail, onLeadCaptureSuccess)

  const handleSubmit = ({ phoneData, emailData }: ModalFormValuesType) => {
    const phone = phoneData.phone || ''
    const email = emailData.email || ''

    setIsSubmitting(true)
    setFormError(false)

    const leadData = {
      externalId: getValueFromLeadData('externalId') || '',
      leadSource: 'Door to Door',
      phone,
      email
    }

    !getCookie(COOKIE_LEAD_DATA)
      ? setCookie(COOKIE_LEAD_DATA, leadData, cookiesOption)
      : updateLeadData({ phone, email })

    const leadGenParams: LeadGenParamsType = {
      source: 'Door to Door',
      siteMetadata: { sourceType: 'door_to_door' },
      phone,
      email
    }

    leadGenCaptureV2(leadGenParams, true)
  }

  return handleSubmit
}
