import { getClientType } from '@ecomm/data-zuora'
import { logError } from '@ecomm/error-handling'
import { polyfillHasOwn } from '@ecomm/shared-polyfill'
import type { PaymentMethodResponse } from '@simplisafe/ss-ecomm-data/simplisafe'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'

import { fetchApi } from './lib/fetchApi'

function isPaymentMethodResponse(res: unknown): res is PaymentMethodResponse {
  polyfillHasOwn()
  return (
    !!res &&
    typeof res === 'object' &&
    Object.hasOwn(res, 'success') &&
    Object.values(res).includes(true)
  )
}

export function generateCreditMethod(isVariation: boolean) {
  const origin = window.location.origin
  const clientType = getClientType(isVariation, origin)
  return pipe(
    fetchApi({
      endpoint: `/payment-decorator/v1/credit/methods`,
      method: 'POST',
      headers: {},
      body: JSON.stringify({
        clientType,
        origin
      })
    }),
    TE.chain(res =>
      isPaymentMethodResponse(res) ? TE.right(res) : TE.left(res)
    ),
    TE.mapLeft(res => Error(`Error fetching payment method ${res}`))
  )
}

export async function fetchPaymentMethod(
  handleFailure: () => void,
  handleSuccess: (rsp: O.Option<PaymentMethodResponse>) => void,
  isVariation = false
) {
  pipe(
    await generateCreditMethod(isVariation)(),
    E.match(
      (rsp: Error) => {
        logError(rsp, { errorCategory: 'Zuora' }, 'critical')
        handleFailure && handleFailure()
      },
      rsp => handleSuccess(O.of(rsp))
    )
  )
}
