const newRelicUrl = 'bam.nr-data.net'

// all of this information is publicly visible in the website header and requests to New Relic
export const newRelicConfigs = {
  dev: {
    accountId: '1100868',
    agentID: '848938974',
    applicationID: '848938974',
    beacon: newRelicUrl,
    errorBeacon: newRelicUrl,
    instrumentationType: 'proAndSPA',
    licenseKey: 'd0a9a17c8f',
    trustKey: '751601'
  },
  prod: {
    accountId: '751601',
    agentID: '864222418',
    applicationID: '864222418',
    beacon: newRelicUrl,
    errorBeacon: newRelicUrl,
    instrumentationType: 'proAndSPA',
    licenseKey: '91db483d4c',
    trustKey: '751601'
  }
}

export const getNewRelicOptions = (env = 'development') => {
  const options = env === 'prd' ? newRelicConfigs.prod : newRelicConfigs.dev
  return {
    info: {
      beacon: newRelicUrl,
      errorBeacon: newRelicUrl,
      licenseKey: options.licenseKey,
      applicationID: options.applicationID,
      sa: 1
    },
    loader_config: {
      accountID: options.accountId,
      trustKey: options.trustKey,
      agentID: options.agentID,
      licenseKey: options.licenseKey,
      applicationID: options.applicationID
    },
    init: { privacy: { cookies_enabled: true } }
  }
}
