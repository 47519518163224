import { deployEnv, locale } from '@ecomm/data-env-variables'
import { useFirstSeenUrl, useVisitorId } from '@ecomm/data-storage'
import { logError } from '@ecomm/error-handling'
import type { UserAttributes } from '@ecomm/optimizely-utils'
import {
  COOKIE_AUTH0,
  COOKIE_PARTNERS_DATA,
  getCookie
} from '@ecomm/shared-cookies'
import { isNotNil } from '@simplisafe/ewok'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useEffect, useMemo, useState } from 'react'
import { UAParser } from 'ua-parser-js'

export const useUserAttributes = () => {
  const vid = useVisitorId()

  // This does not use useCookie because the frontend doesn't control the setting of this cookie
  const isLoggedIn = isNotNil(getCookie(COOKIE_AUTH0))
  // This does not use useCookie yet because the places that set this cookie in the frontend aren't using useCookie yet
  const isPartnerCookieSet = isNotNil(getCookie(COOKIE_PARTNERS_DATA))

  const [firstSeenUrl, setFirstSeenUrl] = useFirstSeenUrl(
    O.getOrElse(() => 'missing_id')(vid)
  )

  const userAgentData = useMemo(() => UAParser(), [])

  useEffect(() => {
    setFirstSeenUrl()
  })

  const [userAttributes, setUserAttributes] = useState<
    O.Option<UserAttributes>
  >(O.none)

  useEffect(() => {
    Promise.all([locale(), deployEnv()]).then(([_locale, _deployEnv]) => {
      setUserAttributes(
        O.of({
          device: userAgentData.device.type || 'desktop',
          environment: pipe(
            _deployEnv,
            E.bimap(logError, t =>
              t === 'prd' ? 'production' : 'development'
            ),
            E.getOrElse(() => 'development')
          ),
          firstSeenUrl: pipe(
            firstSeenUrl,
            O.getOrElse(() => window.location.pathname)
          ),
          geoLocation: pipe(
            _locale,
            E.mapLeft(logError),
            E.getOrElse(() => 'en-US')
          ),
          isLoggedIn,
          isPartnerCookieSet,
          platform: 'fcp',
          userAgent: userAgentData.browser.name || null
        })
      )
      return null
    })
  }, [
    firstSeenUrl,
    isLoggedIn,
    isPartnerCookieSet,
    userAgentData.browser.name,
    userAgentData.device.type
  ])

  return userAttributes
}
