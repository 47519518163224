import { trackPromotionExperience } from '@ecomm/cdp-tracking/ninetailedExperienceEvents'
import { currentPromoAtom } from '@ecomm/data-promotions'
import { fetchCurrentPromotion } from '@ecomm/data-simplisafe-api/fetchCurrentPromotion'
import {
  type NinetailedExperience,
  experienceAtom
} from '@ecomm/data-storage/ninetailed-experiences'
import { getVisitorId } from '@ecomm/shared-cookies'
import { useNinetailed } from '@ecomm/shared-ninetailed'
import type { Locale } from '@ecomm/utils'
import type { ProfileState } from '@ninetailed/experience.js'
import type { SelectedVariantInfo } from '@ninetailed/experience.js-shared'
import { voidFn } from '@simplisafe/ewok'
import { useQuery } from '@tanstack/react-query'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useAtom, useSetAtom } from 'jotai'
import { useEffect } from 'react'

/**
 * If we have a profile and we haven't fired the callback yet, we should fire it.
 */
export const isProfileReady = (profileState: O.Option<ProfileState>) =>
  pipe(
    profileState,
    O.chain(p => O.fromNullable(p.status === 'success')),
    O.getOrElse<boolean>(() => false)
  )

const isProfileInError = (profileState: O.Option<ProfileState>) =>
  pipe(
    profileState,
    O.chain(p => O.fromNullable(p.status === 'error')),
    O.getOrElse<boolean>(() => false)
  )

/**
 * This function is used to get the experiences from the user's profile.
 */
export const getExperiences = (profileState: O.Option<ProfileState>) =>
  pipe(
    profileState,
    O.chain(p => O.fromNullable(p.experiences)),
    O.getOrElse<readonly SelectedVariantInfo[]>(() => [])
  )

/**
 * Takes an experience id and all experiences returned from the profile
 * and returns the variant index corresponding to the experience.
 * Returns 'unknown' if experience id cannot be found
 */
export const getVariantIndexFromExperiences = (
  experiences: readonly NinetailedExperience[],
  expId: string
) => {
  const varIdx = experiences.find(
    exp => exp.experienceId === expId
  )?.variantIndex
  return typeof varIdx === 'number'
    ? varIdx === 0
      ? 'control'
      : `variant ${varIdx}`
    : 'unknown'
}

/**
 * This hook is used to fire a callback when the user's profile is loaded.
 * It will only fire the callback that matches the tag passed once per session.
 */
export const useFetchNinetailedPromo = (locale: Locale, tag: string) => {
  const { profileState, onProfileChange } = useNinetailed()
  const setPromoAtom = useSetAtom(currentPromoAtom)
  const [experiencesAtom, setExperiencesAtom] = useAtom(experienceAtom)

  const trackNinetailedExperience = (
    variantId: string,
    experienceId: string
  ) => {
    const vid = getVisitorId() ?? ''
    trackPromotionExperience({
      ninetailedComponent: variantId,
      ninetailedExperience: experienceId,
      ninetailedVariant: getVariantIndexFromExperiences(
        pipe(
          experiencesAtom,
          O.getOrElse((): readonly NinetailedExperience[] => [])
        ),
        experienceId
      ),
      vid
    })
  }

  // react query implementation
  const { data, isSuccess } = useQuery({
    queryKey: [`${tag}-${locale}`],
    // we can pass experiences here and they will be added as a header when the endpoint is ready
    queryFn: async () =>
      await fetchCurrentPromotion(
        locale,
        null,
        pipe(
          experiencesAtom,
          O.getOrElse((): readonly NinetailedExperience[] => [])
        )
      ),
    enabled:
      isProfileReady(profileState) ||
      isProfileInError(profileState) ||
      locale === 'en-GB',
    retry: false
  })

  useEffect(() => {
    isSuccess &&
      pipe(
        data,
        E.match(
          () => voidFn(),
          response => {
            // track promo response
            setPromoAtom(response)
            response.experienceId &&
              trackNinetailedExperience(response.id, response.experienceId)
          }
        )
      )
  }, [data, isSuccess])

  useEffect(() => {
    onProfileChange &&
      onProfileChange((profileState: ProfileState) => {
        isProfileReady(O.of(profileState)) &&
          setExperiencesAtom(O.of(getExperiences(O.of(profileState))))

        isProfileInError(O.of(profileState)) && setExperiencesAtom(O.of([]))
      })
  }, [onProfileChange])
}
