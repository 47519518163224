import { trackNavigationClick } from '@ecomm/cdp-tracking'
import { Link } from '@ecomm/framework'
import { getLogoLinkUrl } from '@ecomm/shared-cookies'
import { useOnKeyUp } from '@ecomm/shared-hooks'
import { SimpliSafeLogo } from '@ecomm/shared-icons'
import classNames from 'classnames'
import React, { type HTMLAttributes, useState } from 'react'
import { useTracking } from 'react-tracking'

import Aside from './Aside'
import CTAMenu from './CTAMenu'
import DesktopNavMenu from './DesktopNavMenu'
import { HamburgerButton } from './HamburgerButton'
import ProgressBar from './ProgressBar'
import type { HeaderProps } from './schema'

/*
We have a copy of this Component in shared/components/src/v2/lib as well which
supports data fetched from apollo instead of gatsby data layer. Functionality and
structure is exactly same in both the components, so if you are making any changes
to this, please make corresponding changes to the v2 version of this component too.
We will make v2/ version the main version going forward when we are completely swapped
over to apollo from gatsby data layer.
*/

export function Header({
  desktopMenu,
  mobileMenu,
  type,
  progressBarProps,
  ctaMenu = { showShopCTA: true },
  className = '',
  children,
  quoteWizard
}: HeaderProps & HTMLAttributes<HTMLElement>) {
  const [showAside, setShowAside] = useState(false)
  const toggleAside = () => setShowAside(prev => !prev)

  useOnKeyUp('Escape', () => setShowAside(false))

  const { trackEvent } = useTracking()

  return (
    <>
      <header
        aria-disabled={showAside}
        className={classNames(
          'prose bg-neutral-black md:prose-md lg:prose-lg relative w-full text-inherit lg:z-20',
          className
        )}
        data-component="Header"
      >
        <div className="py-p-4 max-w-8xl mx-auto flex h-[50px] items-center gap-4 px-2 md:h-[107px] md:gap-8 md:px-8 md:py-0">
          <div className="flex flex-shrink-0 items-center gap-2 md:gap-4">
            {mobileMenu?.length ? (
              <HamburgerButton
                showAside={showAside}
                toggleAside={toggleAside}
                type={type}
              />
            ) : null}
            <Link
              aria-label="Home page"
              className="flex w-28 flex-shrink-0 md:w-[150px]"
              onClick={() => {
                trackEvent({
                  label: 'logo',
                  event: 'navigation-link',
                  eventAction: 'click',
                  eventCategory: 'navigation'
                })
                trackNavigationClick({
                  action: 'click',
                  navElement: 'logo'
                })
              }}
              to={getLogoLinkUrl('/')}
            >
              <SimpliSafeLogo className="h-7 w-28 md:h-9 md:w-40" />
            </Link>
          </div>
          {desktopMenu && type === 'full' ? (
            <DesktopNavMenu menu={desktopMenu} />
          ) : null}
          {type !== 'full' && children}
          {type !== 'slim' && (
            <CTAMenu {...ctaMenu} headerType={type} quoteWizard={quoteWizard} />
          )}
          {type === 'slim' && progressBarProps ? (
            <ProgressBar
              className="ml-auto"
              progress={progressBarProps.progress}
              steps={progressBarProps.steps}
            />
          ) : null}
        </div>
      </header>
      {mobileMenu?.length ? (
        <Aside
          cartQuantity={ctaMenu.cartQuantity ?? 0}
          menu={mobileMenu}
          show={showAside}
          toggle={toggleAside}
        />
      ) : null}
    </>
  )
}
