import { removeCartId, setCartId } from '@ecomm/data-storage'
import { D2DPartnerGroup } from '@ecomm/door-to-door-constants/cookies'
import { D2DOrderSource } from '@ecomm/door-to-door-constants/d2dOrderSource'
import { logError } from '@ecomm/error-handling'
import {
  type Address,
  ctCartSchema,
  getDiscountCode,
  getPartnerMemberNumber,
  getShippingMethodId,
  getShippingMethodName,
  getShippingPrice,
  getShippingRateInput,
  getTax,
  transformLineItems
} from '@simplisafe/eis-commercetools-carts'
import type { Locale } from '@simplisafe/ewok'
import { buildCustomFieldUpdateAction } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import * as O from 'fp-ts/lib/Option'
import * as TE from 'fp-ts/lib/TaskEither'
import { pipe } from 'fp-ts/lib/function'
import { match } from 'ts-pattern'
import type { TypeOf } from 'zod'

import { checkoutResponseSchema } from './checkoutSchema'
import {
  handleNotFoundCart,
  handleUnauthorizedCart
} from './lib/cartErrorResponseHandlers'
import { cartFetch } from './lib/cartFetch'
import { constructCartEndpoint } from './lib/constructCartEndpoint'
import type {
  CartValue,
  CheckoutRequestBody,
  CheckoutValue,
  ErrorResponse
} from './lib/types'

/**
 * ID for a cart.
 */
type CartId = string

export function formatErrorMessage(message: string, e: ErrorResponse) {
  return JSON.stringify({
    message: message,
    status: e.status,
    url: e.url || 'unavailable',
    body: e.body || 'unavailable',
    statusText: e.statusText
  })
}

export const mapError = (error: Error | ErrorResponse): Error =>
  match(error)
    .with({ status: 400 }, e => Error(formatErrorMessage('Bad request.', e)))
    .with({ status: 401 }, e =>
      Error(formatErrorMessage('Unauthorized cart.', e))
    )
    .with({ status: 403 }, e => Error(formatErrorMessage('Forbidden cart.', e)))
    .with({ status: 404 }, e => Error(formatErrorMessage('Cart not found.', e)))
    .with({ status: 500 }, e => Error(formatErrorMessage('Server error.', e)))
    .otherwise(() => Error(`Unknown cart error: ${JSON.stringify(error)}`))

export const handleErrorResponse = (
  error: Error | ErrorResponse
): TE.TaskEither<Error | ErrorResponse, unknown> =>
  match(error)
    .with({ status: 404 }, () => handleNotFoundCart(error))
    .with({ status: 403 }, () => handleUnauthorizedCart(error))
    .otherwise(() => TE.left(error))

const transformCart = (cart: TypeOf<typeof ctCartSchema>): CartValue => {
  const lineItems = transformLineItems(cart)
  const isActive = cart.cartState === 'Active'
  const partnerMemberNumber = O.fromNullable(getPartnerMemberNumber(cart))

  isActive ? setCartId(cart.id) : removeCartId()

  return {
    isActive,
    lineItems,
    totalPrice: cart.totalPrice.centAmount,
    discountCode: getDiscountCode(cart.discountCodes),
    partnerMemberNumber,
    shippingAddress: cart.shippingAddress || null,
    billingAddress: cart.billingAddress || null,
    taxPrice: getTax(cart.taxedPrice),
    shippingPrice: getShippingPrice(cart.shippingInfo),
    shippingRateInput: getShippingRateInput(cart.shippingRateInput),
    shippingMethod: {
      id: getShippingMethodId(cart.shippingInfo),
      name: getShippingMethodName(cart.shippingInfo)
    },
    custom: cart.custom?.fields ?? {},
    ctState: cart.cartState
  }
}

/**
 * Use this function sparingly and prefer transformCart when possible.
 * Unless you're dealing with sequential cart requests, you can get the cart id
 * from getCartId through @ecomm/data-storage instead.
 */
const transformCartWithID = (
  cart: TypeOf<typeof ctCartSchema>
): CartValue & { readonly id: string } => {
  const lineItems = transformLineItems(cart)
  const isActive = cart.cartState === 'Active'
  const partnerMemberNumber = O.fromNullable(getPartnerMemberNumber(cart))

  isActive ? setCartId(cart.id) : removeCartId()

  return {
    id: cart.id,
    isActive,
    lineItems,
    totalPrice: cart.totalPrice.centAmount,
    discountCode: getDiscountCode(cart.discountCodes),
    partnerMemberNumber,
    shippingAddress: cart.shippingAddress || null,
    billingAddress: cart.billingAddress || null,
    taxPrice: getTax(cart.taxedPrice),
    shippingPrice: getShippingPrice(cart.shippingInfo),
    shippingRateInput: getShippingRateInput(cart.shippingRateInput),
    shippingMethod: {
      id: getShippingMethodId(cart.shippingInfo),
      name: getShippingMethodName(cart.shippingInfo)
    },
    custom: cart.custom?.fields ?? {},
    ctState: cart.cartState
  }
}
/**
 * Takes a raw CT cart response and returns either a simplified cart with known types or a detailed error.
 */
export const handleRawCartResponse = (
  response: TE.TaskEither<Error | ErrorResponse, unknown>,
  details: Record<string, string> = {}
): TE.TaskEither<Error, CartValue> => {
  return pipe(
    response,
    TE.orElse(handleErrorResponse),
    TE.mapLeft(e => pipe(e, mapError, e => logError(e, details))),
    TE.chain(response => {
      const t = ctCartSchema.safeParse(response)
      return t.success ? TE.right(t.data) : TE.left(t.error)
    }),
    TE.map(transformCart)
  )
}

/**
 * Takes a raw CT cart response and returns either a simplified cart with id with known types or a detailed error.
 */
export const handleRawCartResponseWithID = (
  response: TE.TaskEither<Error | ErrorResponse, unknown>,
  details: Record<string, string> = {}
): TE.TaskEither<Error, CartValue & { readonly id: string }> => {
  return pipe(
    response,
    TE.orElse(handleErrorResponse),
    TE.mapLeft(e => pipe(e, mapError, e => logError(e, details))),
    TE.chain(response => {
      const t = ctCartSchema.safeParse(response)
      return t.success ? TE.right(t.data) : TE.left(t.error)
    }),
    TE.map(transformCartWithID)
  )
}

/**
 * GET a cart.
 */
export const cartGet = (cartId: CartId) =>
  pipe(cartFetch('GET', constructCartEndpoint(1, cartId)), r =>
    handleRawCartResponse(r, { cartAction: 'getCart' })
  )

export const cartGetByQuoteId = (quoteId: string, emailHash: string) =>
  pipe(
    cartFetch('GET', constructCartEndpoint(1, `quote/${emailHash}/${quoteId}`)),
    r => handleRawCartResponse(r, { cartAction: 'getCartByQuoteId' })
  )

/**
 * POST discount codes to add to a cart. Supports bulk discount codes.
 * Does not remove existing discount codes from cart.
 */
export const cartAddDiscountCodes = (
  cartId: CartId,
  discountCodes: readonly string[],
  variationId?: string
) =>
  pipe(
    cartFetch(
      'POST',
      constructCartEndpoint(2, `${cartId}:applyDiscountCodes`),
      JSON.stringify({ discountCodes }),
      variationId
    ),
    r => handleRawCartResponse(r, { cartAction: 'applyDiscountCodes' })
  )

export const cartAddAnnotatedDiscountCode = (
  cartId: CartId,
  annotations: readonly string[],
  discountCode: string,
  variationId?: string
) =>
  pipe(
    cartFetch(
      'POST',
      constructCartEndpoint(2, `${cartId}:applyAnnotatedDiscount`),
      JSON.stringify({ annotations, discountCode }),
      variationId
    ),
    r => handleRawCartResponse(r, { cartAction: 'applyAnnotatedDiscountCode' })
  )

/**
 * POST a single discount code to cart, removing other existing discount codes.
 */
export const cartAddUserDiscountCode = (
  cartId: CartId,
  discountCode: string,
  variationId?: string
) =>
  pipe(
    cartFetch(
      'POST',
      constructCartEndpoint(2, `${cartId}:applyDiscount`),
      JSON.stringify({ discountCode }),
      variationId
    ),
    r => handleRawCartResponse(r, { cartAction: 'applyDiscount' })
  )

export const cartUpdateQuantity = (
  cartId: CartId,
  lineItemId: string,
  quantity: number,
  variationId?: string
) => {
  const actions = [
    {
      action: 'updateCart',
      products: [
        {
          lineItemId,
          quantity
        }
      ]
    }
  ]
  return pipe(
    cartFetch(
      'POST',
      constructCartEndpoint(1, cartId),
      JSON.stringify({ actions }),
      variationId
    ),
    r =>
      handleRawCartResponse(r, {
        cartAction: 'updateCart',
        cartActionDetails: actions.map(a => a.action).join(',')
      })
  )
}

export const cartRemoveLineItem = (
  cartId: CartId,
  lineItemId: string,
  variationId?: string
) => {
  const actions = [
    {
      action: 'removeLineItem',
      products: [{ lineItemId }]
    }
  ]

  return pipe(
    cartFetch(
      'POST',
      constructCartEndpoint(1, cartId),
      JSON.stringify({ actions }),
      variationId
    ),
    r =>
      handleRawCartResponse(r, {
        cartAction: 'removeLineItem',
        cartActionDetails: actions.map(a => a.action).join(',')
      })
  )
}

export const cartAddLineItem = (
  cartId: CartId,
  productId: string,
  quantity: number,
  variationId?: string
) =>
  pipe(
    cartFetch(
      'POST',
      constructCartEndpoint(1, cartId),
      JSON.stringify({
        actions: [
          {
            action: 'addLineItem',
            productId,
            quantity
          }
        ]
      }),
      variationId
    ),
    handleRawCartResponse
  )

export const cartAddItem = (
  locale: Locale,
  products: readonly {
    readonly sku: string
    readonly quantity: number
  }[],
  cartId?: CartId,
  variationId?: string
) => {
  const actions = [
    {
      action: cartId ? 'addToCart' : 'createCart',
      locale,
      products
    }
  ]

  return pipe(
    cartFetch(
      'POST',
      constructCartEndpoint(1, cartId || ''),
      JSON.stringify({ actions }),
      variationId
    ),
    r =>
      handleRawCartResponse(r, {
        cartAction: 'addToCart',
        cartActionDetails: (products.length > 0 ? ['addProducts'] : []).join(
          ','
        )
      })
  )
}

export const cartUpdatePartnerNumber = (
  cartId: string,
  partnerMemberNumber: string
) => {
  const partnerMemberNumberAction = buildCustomFieldUpdateAction({
    name: 'partnerMemberNumber',
    value: partnerMemberNumber
  })

  const actions = [partnerMemberNumberAction]

  return pipe(
    O.fromNullable(cartId),
    O.fold(
      () => TE.left(Error('Cart ID not found')),
      id =>
        pipe(
          cartFetch(
            'POST',
            constructCartEndpoint(1, id),
            JSON.stringify({ actions })
          ),
          r =>
            handleRawCartResponse(r, {
              cartAction: 'updateCart',
              cartActionDetails: partnerMemberNumberAction.action
            })
        )
    )
  )
}

export const cartSetShippingAddress = (
  cartId: CartId,
  address: Address,
  variationId?: string
) => {
  return pipe(
    cartFetch(
      'POST',
      constructCartEndpoint(1, cartId),
      JSON.stringify({
        actions: [
          {
            action: 'setShippingAddress',
            address
          }
        ]
      }),
      variationId
    ),
    r =>
      handleRawCartResponse(r, {
        cartAction: 'setShippingAddress'
      })
  )
}

export const cartSetShippingMethod = (cartId: CartId, methodId: string) => {
  return pipe(
    cartFetch(
      'POST',
      constructCartEndpoint(1, cartId),
      JSON.stringify({
        actions: [
          {
            action: 'setShippingMethod',
            shippingMethod: {
              id: methodId,
              typeId: 'shipping-method'
            }
          }
        ]
      })
    ),
    r =>
      handleRawCartResponse(r, {
        cartAction: 'setShippingMethod'
      })
  )
}

/**
 * Takes a raw checkout response and returns either the address validation and simplified cart with known types, or a detailed error.
 */
const handleRawCheckoutResponse = (
  response: TE.TaskEither<Error | ErrorResponse, unknown>
): TE.TaskEither<Error, CheckoutValue> => {
  return pipe(
    response,
    TE.mapLeft(e =>
      pipe(e, mapError, e => logError(e, { cartAction: 'checkout' }))
    ),
    TE.chain(response => {
      const parsed = checkoutResponseSchema.safeParse(response)
      return parsed.success ? TE.right(parsed.data) : TE.left(parsed.error)
    }),
    TE.map(data => {
      return {
        cart: transformCart(data.cart),
        addressValidation: data.addressValidation
      }
    })
  )
}

export const cartCheckout = (cartId: CartId, body: CheckoutRequestBody) => {
  return pipe(
    cartFetch(
      'POST',
      constructCartEndpoint(2, `checkout/${cartId}`),
      JSON.stringify(body)
    ),
    handleRawCheckoutResponse
  )
}

export function cartCreateDoorToDoorBMSCart(
  locale: Locale,
  products: readonly {
    readonly sku: string
    readonly quantity: number
  }[],
  bmsPackage: {
    readonly sku: string
    readonly quantity: number
  },
  doorToDoorAgentId: string,
  partnerName: string
) {
  const createBMSCartAction = [
    {
      action: 'createBmsCart',
      locale,
      package: bmsPackage,
      products,
      orderSource: D2DOrderSource,
      ...(doorToDoorAgentId ? { door2doorAgentId: doorToDoorAgentId } : {})
    }
  ]

  const setPartnerAssociationAction = [
    {
      action: 'setPartnerAssociation',
      customerGroup: D2DPartnerGroup,
      partnerName
    }
  ]

  return pipe(
    cartFetch(
      'POST',
      constructCartEndpoint(1, ''),
      JSON.stringify({ actions: createBMSCartAction })
    ),
    r => handleRawCartResponseWithID(r, { cartAction: 'createBmsCart' }),
    TE.chain(cart => {
      return pipe(
        cartFetch(
          'POST',
          constructCartEndpoint(1, cart.id),
          JSON.stringify({ actions: setPartnerAssociationAction })
        )
      )
    }),
    r =>
      handleRawCartResponseWithID(r, {
        cartAction: 'setPartnerAssociation'
      }),
    TE.chain(r => cartGet(r.id))
  )
}

export function cartAssociateD2DVid(cartId: CartId, agentId: string) {
  return pipe(
    cartFetch(
      'POST',
      constructCartEndpoint(2, 'd2d/associateCart'),
      JSON.stringify({ cartId, agentId })
    ),
    r => handleRawCartResponse(r)
  )
}
