import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export const useTrackingOpenShippingModalValidation = () => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(() => {
    trackEvent({
      action: 'modal_opened',
      category: 'checkout',
      event: 'shipping_address_modal_validation_opened',
      label: ''
    })
  }, [trackEvent])
}
