import type { GiftItemDTO } from '@simplisafe/ss-ecomm-data/prices/service'

import {
  COOKIE_ATAT_TOKEN,
  COOKIE_CHAT_APP_ID,
  COOKIE_CHAT_OPENED,
  COOKIE_DRUPAL_UID,
  COOKIE_EMAIL,
  COOKIE_MONITORING_GIFT_ITEM,
  COOKIE_MONITORING_PLAN,
  COOKIE_NON_MONITORING_GIFT_ITEM,
  COOKIE_PURCHASED_CART_ID,
  COOKIE_USER_TOKEN,
  COOKIE_VID
} from './constants'
import { getCookie } from './cookies'

export const getAtAtToken = (): string | undefined =>
  getCookie(COOKIE_ATAT_TOKEN)
export const getChatAppId = (): string => getCookie(COOKIE_CHAT_APP_ID)
export const getChatOpened = (): string => getCookie(COOKIE_CHAT_OPENED)
export const getUserId = (): string => getCookie(COOKIE_DRUPAL_UID)
export const getMonitoringPlan = (): string => getCookie(COOKIE_MONITORING_PLAN)
export const getMonitoringGiftItems = (): GiftItemDTO =>
  getCookie(COOKIE_MONITORING_GIFT_ITEM)
export const getNonMonitoringGiftItems = (): GiftItemDTO =>
  getCookie(COOKIE_NON_MONITORING_GIFT_ITEM)
export const getPurchasedCartId = (): string =>
  getCookie(COOKIE_PURCHASED_CART_ID)
export const getVisitorId = () => getCookie<string | undefined>(COOKIE_VID)
export const getUserToken = () =>
  getCookie<string | undefined>(COOKIE_USER_TOKEN)
export const getUserEmail = (): string => getCookie(COOKIE_EMAIL)
