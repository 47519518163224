import { useNinetailedFeatureFlag } from '..'

export const usePriceAboveFoldExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '1EZdq1UMf3QRd4dyKSiN2N' })

export const useOdmonExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '15AAdXWYGGLjW7WaZYuxK6' })

export const useOdmonVideoDogAdExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '3ZltdGvGdUDiYH0TCWYEmL' })

export const usePlanChoiceExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '5IjWWxTlRr75piRySVLNEt' })

export const usePlanChoiceNonCameraPushExperience = () =>
  useNinetailedFeatureFlag({ baselineId: 'FrsJwf42vVfkf0V82LoX0' })

export const usePlanChoiceNonCameraStayExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '6A1FNq5yR3mXyV3HRLTdda' })

export const usePlanChoiceDynamicNonIntentPushExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '5AtYB6NfqWMEqHPAATwszb' })

export const usePlanChoiceDynamicNonIntentStayExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '2uyqlam8bS9kEU7uJiS6y5' })

export const usePlanChoiceDynamicIntentPushExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '2x4wzUbAlbyMDXKu4Lb4jV' })

export const usePlanChoiceDynamicIntentStayExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '1g6supREDt7p6qdgrAMJkO' })

export const useCartLineItemPriceDisplayExperience = () =>
  useNinetailedFeatureFlag({ baselineId: '1OTIFsd8Nufmoykd9N020Q' })
