import { type TypeOf, z } from '@simplisafe/ewok'

export const paymentCallbackRequestSchema = z.object({
  clientType: z.string(),
  origin: z.string(),
  signature: z.string(),
  hpmClient: z.object({
    callbackPath: z.string(),
    tenantId: z.string(),
    token: z.string(),
    pageId: z.string()
  })
})

export const paymentCallbackResponseSchema = z.object({
  success: z.boolean()
})

export type PaymentCallbackRequest = TypeOf<typeof paymentCallbackRequestSchema>

export type PaymentCallbackResponse = TypeOf<
  typeof paymentCallbackResponseSchema
>
