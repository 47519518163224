import { z } from '@simplisafe/ewok'
import type { TypeOf } from '@simplisafe/ewok'

const userCheckoutData = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  email: z.string().optional(),
  phone: z.string().optional()
})

const addressValidationSchema = z.object({
  street: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zipcode: z.string().optional(),
  plus4Code: z.string().optional(),
  additionalStreetInfo: z.string().optional()
})

const metadataSchema = z.object({
  lead: z.string().optional(),
  leadOther: z.string().optional()
})

const configSchema = z.object({
  setBillingAddress: z.boolean().optional()
})

export const shippingAddressValidationSchema = z.object({
  data: z.object({
    userCheckoutData: userCheckoutData,
    validatedAddress: addressValidationSchema,
    shippingMethod: z.string(),
    metadata: metadataSchema,
    config: configSchema
  }),
  redirectUrl: z.string()
})

export type ShippingValidationAddressSchema = TypeOf<
  typeof shippingAddressValidationSchema
>
export type AddressValidationsSchema = TypeOf<typeof addressValidationSchema>
export type UserCheckoutSchema = TypeOf<typeof userCheckoutData>
export type ShippingValidationMetadataSchema = TypeOf<typeof metadataSchema>
