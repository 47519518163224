import { parseProduct as parseCommercetoolsProduct } from '@ecomm/data-simplisafe-api'
import { overloadedOption } from '@ecomm/shared-fp-ts-utils'
import { pipe } from 'fp-ts/lib/function'
import { Map as IMap } from 'immutable'

import type { Product } from './internal'
import { parseDate, parseVariant } from './internal'
import type { GqlProduct } from './types'

export function parseProductsV2(t: {
  readonly allProduct: {
    readonly edges: readonly { readonly node: GqlProduct }[]
  }
}) {
  return pipe(
    t.allProduct.edges.reduce((acc, { node }) => {
      const sku = node.masterSku
      return acc.set(sku, {
        '@@type': 'product',
        id: node.id,
        discountedPrice: overloadedOption(node.discountedPrice),
        discountedPriceWithServicePlan: overloadedOption(
          node.discountedPriceWithServicePlan
        ),
        isCartable: node.isCartable,
        isOnStock: node.isOnStock,
        isSellable: node.isSellable,
        isViewable: node.isViewable,
        isIndeterminatePrice: false,
        hardwareVersion: node.hardwareVersion,
        masterSku: node.masterSku,
        name: {
          'en-US': node.name.en_US,
          'en-GB': node.name.en_GB
        },
        nodeId: node.nodeId,
        price: node.price,
        productType: node.productType,
        productTypeId: node.productTypeId,
        restockDate: node.restockDate,
        sku: node.sku,
        variants: node.variants?.map(parseVariant) ?? [],
        lastFetched: parseDate(node.lastFetched)
      })
    }, IMap<string, Product>()),
    t => t.map(parseCommercetoolsProduct)
  )
}
