import React from 'react'

type Props = {
  readonly width?: number
  readonly height?: number
}

export function SecureIcon({ width = 24, height = 24 }: Props) {
  return (
    <svg
      data-component="SecureIcon"
      fill="none"
      height={height}
      viewBox="0 0 32 32"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7.855 29.236h16v-13.09h-16v13.09zM6.4 30.691h18.91v-16H6.4v16zM10.764 8.145a5.097 5.097 0 015.09-5.09 5.097 5.097 0 015.091 5.09v5.091H22.4v-5.09A6.553 6.553 0 0015.855 1.6a6.553 6.553 0 00-6.546 6.545v5.091h1.455v-5.09z"
        fill="#fff"
        fillRule="evenodd"
      ></path>
    </svg>
  )
}
