import { useCartLineItems } from '@ecomm/data-cart'
import { useLocale } from '@ecomm/data-hooks'
import { useEffect, useState } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'
import { useTrackMetricEvent } from '../useTrackMetricEvent'
import { getCurrencyCode, simplifyLineItems } from './lineItems'
import { populateBrand, useCTProducts } from './populateBrand'

/**
 * EEC event that fires once on page load.
 *
 * This function should not fire if the cart has no items in it, even if the cart is initialized.
 */
export function useTrackingEECPayment() {
  const { trackEvent } = useTracking<TrackingData>()
  const trackMetricEvent = useTrackMetricEvent()

  const [called, setCalled] = useState(false)

  const locale = useLocale()
  const lineItems_ = useCartLineItems()
  const ctProducts = useCTProducts(lineItems_)
  const lineItems = populateBrand(lineItems_, ctProducts, locale)

  return useEffect(() => {
    const ecommerce = {
      checkout: {
        products: simplifyLineItems(lineItems, locale)
      },
      currencyCode: getCurrencyCode(lineItems, locale),
      actionField: { step: 2 }
    }

    const fn = () => {
      trackEvent({
        ecommerce: ecommerce,
        event: 'paymentInfo'
      })
      trackEvent({
        ecommerce,
        event: 'eec.payment',
        eventAction: 'checkout',
        eventCategory: 'eec',
        eventLabel: 'payment information'
      })
      trackMetricEvent('payment-page-view')
      setCalled(true)
    }

    !called && lineItems.length > 0 && fn()
  }, [trackEvent, locale, lineItems, called, trackMetricEvent])
}
