import { useLocale } from '@ecomm/data-hooks'
import { Experience } from '@ecomm/shared-ninetailed-experience'
import { useTrackGuidedSystemBuilderOpenEvent } from '@ecomm/tracking'
import { voidFn } from '@simplisafe/ewok'
import { useEffect, useMemo } from 'react'

import { safeMapExperiences } from '@ecomm/shared-ninetailed'
import Modal from '../../../lib/Modal'
import { GuidedSystemBuilder } from '../GuidedSystemBuilder'
import type { GuidedSystemBuilderSchema } from '../GuidedSystemBuilder/schema'

type Props = {
  readonly show?: boolean
  readonly onRequestClose?: () => void
  readonly data: GuidedSystemBuilderSchema
}

export function QuoteWizardModal({
  show = false,
  onRequestClose = voidFn,
  data
}: Props) {
  const trackGSBStart = useTrackGuidedSystemBuilderOpenEvent(data.type)
  const locale = useLocale()
  useEffect(() => {
    show && trackGSBStart && trackGSBStart()
  }, [show, trackGSBStart])

  const experiences = useMemo(
    () =>
      safeMapExperiences<GuidedSystemBuilderSchema>({
        ...data,
        nt_experiences: data.ntExperiencesCollection.items.map(experience => ({
          ...experience,
          variants: experience.variants.items
        }))
      }),
    [data]
  )

  return (
    <Modal
      isOpen={show}
      onRequestClose={onRequestClose}
      style={{ content: { padding: '32px' } }}
    >
      <div className="w-full md:w-[500px]">
        {locale === 'en-US' ? (
          <Experience
            key={data.id}
            {...data}
            component={GuidedSystemBuilder}
            experiences={experiences}
          />
        ) : (
          <GuidedSystemBuilder {...data} />
        )}
      </div>
    </Modal>
  )
}
