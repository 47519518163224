import { getCartDiscountCode } from '@ecomm/data-cart'
import type { CartValue } from '@ecomm/data-simplisafe-api'
import { addAdditionalDataToBmsItems } from './TrackProductAdded/productHelpers'
import { lineItemToRudderstackData } from './lineItemHelpers'

import { getCurrencyFromLocale, getLocale } from '@ecomm/utils'
import type {
  ImmutableCart,
  LineItem
} from '@simplisafe/ss-ecomm-data/commercetools/cart'
import { selectCart } from '@simplisafe/ss-ecomm-data/redux/select'

import * as O from 'fp-ts/lib/Option'
import { not } from 'fp-ts/lib/Predicate'
import * as RA from 'fp-ts/lib/ReadonlyArray'
import * as R from 'fp-ts/lib/Record'
import { pipe } from 'fp-ts/lib/function'
import { useSelector } from 'react-redux'

import type { ProductPayload } from './types'

export const packageChildrenToRudderstackProducts = (
  children: readonly Partial<LineItem>[],
  brand: string,
  item_list_id: string
): readonly ProductPayload[] => {
  return pipe(
    children,
    RA.map(child => {
      const hasQty = 'quantity' in child
      const quantity = hasQty ? child.quantity ?? 1 : 1
      const productName = getPackageName(child)

      return {
        brand,
        category: child.productType ?? 'sensor',
        item_list_id,
        name: productName,
        price: 0,
        product_id: child.sku ?? '',
        quantity
      }
    })
  )
}

export const getTotalPrice = (
  lineItems: readonly Partial<LineItem>[]
): number => {
  return pipe(
    lineItems,
    RA.filter((item): item is LineItem => 'totalPrice' in item),
    RA.reduce(0, (acc, item) => acc + item.totalPrice || 0),
    price => Math.round((price + Number.EPSILON) * 100) / 100
  )
}

export const getPackageName = (lineItem: Partial<LineItem>): string => {
  const locale = getLocale()
  return (
    lineItem.custom?.fields['lineItemDisplayName'] ||
    (lineItem.name[locale] ?? lineItem.name['en-US'])
  )
}

export const isPackageParent = (lineItem: Partial<LineItem>): boolean => {
  return lineItem.productType === 'package_parent'
}

export const isPackageBMS = (lineItem: Partial<LineItem>): boolean => {
  return (
    !lineItem.custom?.fields['lineItemSku'] &&
    !lineItem.custom?.fields['lineItemDisplayName']
  )
}

export const lineItemToRudderstackProducts = (
  lineItem: LineItem
): readonly ProductPayload[] => {
  if (isPackageParent(lineItem)) {
    const isBms = isPackageBMS(lineItem)
    const packageName = getPackageName(lineItem)

    const child = lineItem.child || []
    const item_list_id = lineItem.custom?.fields?.['package_parent_id']
    const brand = isBms ? 'bms' : packageName
    const price = getTotalPrice(child)

    return [
      {
        brand,
        category: 'package_parent',
        item_list_id,
        name: packageName,
        price,
        product_id: lineItem.sku,
        quantity: lineItem.quantity
      },
      ...packageChildrenToRudderstackProducts(child, brand, item_list_id)
    ]
  }

  const item_list_id = lineItem.custom?.fields?.['package_parent_id']
  const name = getPackageName(lineItem)
  const brand = item_list_id ? name : undefined

  return [
    {
      category: lineItem.productType,
      name,
      price: lineItem.totalPrice,
      product_id: lineItem.sku,
      quantity: lineItem.quantity,
      ...(item_list_id && { item_list_id }),
      ...(brand && { brand })
    }
  ]
}

export const getParentPackages = (
  lineItems: readonly LineItem[]
): Record<string, LineItem> => {
  return pipe(
    lineItems,
    RA.filter(isPackageParent),
    RA.reduce({}, (acc, item) => ({
      ...acc,
      [item.custom?.fields?.['package_parent_id']]: item
    }))
  )
}

export const getChildPackages = (
  lineItems: readonly LineItem[]
): readonly LineItem[] => {
  return pipe(lineItems, RA.filter(not(isPackageParent)))
}

export const getFreeItems = (
  lineItems: readonly LineItem[]
): readonly LineItem[] => {
  return pipe(
    lineItems,
    RA.filter(item => item.isGift && item.totalPrice === 0 && !item.custom),
    RA.map(item => ({ ...item, totalPrice: 0 }))
  )
}

export const getIndividualItems = (
  lineItems: readonly LineItem[]
): readonly LineItem[] => {
  return pipe(
    lineItems,
    RA.filter(
      item =>
        item.custom?.fields?.['product_key'] &&
        !item.custom?.fields?.['package_parent_id'] &&
        item.totalPrice > 0
    )
  )
}

export const formatPackages = (lineItems: readonly LineItem[]) => {
  return pipe(
    lineItems,
    getParentPackages,
    R.toArray,
    RA.map(([parentId, pkg]) =>
      pipe(
        O.fromNullable(pkg.child),
        O.map(children =>
          pipe(
            getChildPackages(lineItems),
            RA.filter(
              item => item.custom?.fields?.['package_parent_id'] === parentId
            ),
            RA.concat(children)
          )
        ),
        O.map(newChildren => ({ ...pkg, child: newChildren })),
        O.getOrElse(() => pkg)
      )
    ),
    RA.concat(getFreeItems(lineItems)),
    RA.concat(getIndividualItems(lineItems)),
    RA.chain(lineItemToRudderstackProducts)
  )
}

export const createCheckoutStartedPayload = (
  cart: ImmutableCart,
  originalEvent?: string
) => {
  const locale = getLocale()

  return {
    originalEvent,
    products: formatPackages(cart.lineItems),
    currency: getCurrencyFromLocale(locale),
    total: cart.totalPrice,
    cartId: cart.id,
    coupon: getCartDiscountCode(cart)
  }
}

export const useCartSelector = () => {
  return useSelector(selectCart)
}

export const ctLineItemsToRudderstackCartProducts = (cart: CartValue) => {
  return addAdditionalDataToBmsItems(
    cart.lineItems
      .map(item => lineItemToRudderstackData(item, cart, 'multiple').products)
      .reduce((acc, current) => {
        return [...acc, ...current]
      }, [])
  )
}
