import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export function useTrackOdmonSkipThisStep() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(() => {
    trackEvent({
      event: 'skip-this-step'
    })
  }, [trackEvent])
}
