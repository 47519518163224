import {
  type ApolloClientOptions,
  createHttpLink
} from '@apollo/client/index.js'
import { onError } from '@apollo/client/link/error'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { RetryLink } from '@apollo/client/link/retry/index.js'
import { logError } from '@ecomm/error-handling'
import { sha256 } from 'js-sha256'

const PUBLIC_CONTENTFUL_PREVIEW =
  process.env['PUBLIC_CONTENTFUL_PREVIEW'] ||
  import.meta.env['PUBLIC_CONTENTFUL_PREVIEW']

const PUBLIC_CONTENTFUL_ACCESS_TOKEN =
  process.env['PUBLIC_CONTENTFUL_ACCESS_TOKEN'] ||
  import.meta.env['PUBLIC_CONTENTFUL_ACCESS_TOKEN']

const PUBLIC_CONTENTFUL_ENVIRONMENT =
  process.env['PUBLIC_CONTENTFUL_ENVIRONMENT'] ||
  import.meta.env['PUBLIC_CONTENTFUL_ENVIRONMENT']

const PUBLIC_CONTENTFUL_PREVIEW_TOKEN =
  process.env['PUBLIC_CONTENTFUL_PREVIEW_TOKEN'] ||
  import.meta.env['PUBLIC_CONTENTFUL_PREVIEW_TOKEN']

const TOKEN =
  PUBLIC_CONTENTFUL_PREVIEW === 'true'
    ? PUBLIC_CONTENTFUL_PREVIEW_TOKEN
    : PUBLIC_CONTENTFUL_ACCESS_TOKEN

export const httpLink = createHttpLink({
  fetch,
  uri: `https://graphql.contentful.com/content/v1/spaces/v6awd0kabi65/environments/${PUBLIC_CONTENTFUL_ENVIRONMENT}`,
  headers: {
    authorization: `Bearer ${TOKEN}`,
    'Content-Language': 'en-us'
  }
})

/**
 * https://www.apollographql.com/docs/react/data/error-handling/#advanced-error-handling-with-apollo-link
 */
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  graphQLErrors &&
    graphQLErrors.forEach(({ message, locations, path }) => {
      logError(
        Error(
          `[Apollo GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        ),
        { errorCategory: 'Apollo' }
      )
    })

  networkError &&
    logError(Error(`[Apollo Network error]: ${networkError}`), {
      errorCategory: 'Apollo'
    })
})

export const persistedQueryLink = createPersistedQueryLink({
  sha256
})

export const defaultOptions =
  (): ApolloClientOptions<unknown>['defaultOptions'] => ({
    query: {
      fetchPolicy:
        // when using preview mode we don't want to cache results
        // this allows us to see new results when refreshing the page without having to wait 5 minutes
        PUBLIC_CONTENTFUL_PREVIEW === 'true' ? 'no-cache' : 'cache-first'
    }
  })

export const retryLinkConfig = {
  delay: {
    // NOTE: These are intentionally high values to avoid hitting the rate limit during Gatsby builds. We should reduce these values before going live on React Router
    initial: globalThis.window ? 200 : 500,
    max: globalThis.window ? 1000 : 10_000,
    jitter: true
  },
  attempts: {
    max: globalThis.window ? 3 : 10
  }
}

export const retryLink = new RetryLink(retryLinkConfig)
