import type React from 'react'
import type { ModalFormValuesType } from '../ModalFormSchema'

type SubmitButtonProps = {
  readonly label: string
  readonly data: ModalFormValuesType
  readonly isLoading: boolean
}

export function SubmitButton({ label, data, isLoading }: SubmitButtonProps) {
  const isNotValid =
    !data.phoneData.phoneCheckbox && !data.emailData.emailCheckbox

  return (
    <div className="flex flex-col justify-center w-full items-center">
      {!isLoading && isNotValid ? (
        <div
          className=" text-sale border-red-800 text-xs mb-4"
          data-testid="phone-email-lead-capture-error"
        >
          At least one contact method must be selected and corresponding field
          completed.
        </div>
      ) : null}
      {!isLoading ? (
        <button
          className="text-white w-full px-5 py-4 cursor-pointer border-none bg-btn-primary text-center text-lg font-bold rounded-[10px]"
          data-testid="phone-email-lead-capture-submit-button"
          disabled={isLoading || isNotValid}
          type="submit"
        >
          {label}
        </button>
      ) : null}
    </div>
  )
}
