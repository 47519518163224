import { useFormikContext } from 'formik'
import { useCallback } from 'react'
import { FormCheckbox } from '../FormCheckbox'
import { FormField } from '../FormField'
import { FormTextInput } from '../FormTextInput'
import type { PhoneValueType } from '../ModalFormSchema'
import { formatPhoneNumber } from './utils'

export function Phone() {
  const {
    values: { phoneData },
    setFieldValue
  } = useFormikContext<{ readonly phoneData: PhoneValueType }>()

  const handlePhoneChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = event.target.value.replaceAll(/\D/g, '')
      setFieldValue('phoneData.phone', rawValue)
    },
    [setFieldValue]
  )

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue('phoneData.phoneCheckbox', event.target.checked)
      !event.target.checked && setFieldValue('phoneData.phone', '') // clears the phone field if it gets unchecked
    },
    [setFieldValue]
  )

  const formattedValue = formatPhoneNumber(phoneData.phone)

  return (
    <div
      className="flex w-full flex-col"
      data-testid="phone-lead-capture-formfield"
    >
      <FormField id="phone-formfield">
        <FormCheckbox
          checked={phoneData?.phoneCheckbox}
          label="Phone Number"
          name="phoneData.phoneCheckbox"
          onChange={handleCheckboxChange}
        />
      </FormField>
      {phoneData?.phoneCheckbox ? (
        <FormField id="phoneData.phone">
          <label className="sr-only" htmlFor="phone">
            Phone Number
          </label>
          <FormTextInput
            className="w-full"
            id="phone"
            name="phoneData.phone"
            onChange={handlePhoneChange}
            placeholder="(___) ___-____"
            type="tel"
            value={formattedValue}
          />
        </FormField>
      ) : null}
    </div>
  )
}
