import { gatsbyImageDataSchema } from '@ecomm/contentful-schemas'
import { type TypeOf, z } from '@simplisafe/ewok'

import { contentfulRichTextSchema } from '../ContentfulRichText/contentfulRichTextSchema'

export const floorPlanCarouselSchema = z.object({
  __typename: z.literal('ContentfulCarousel'),
  title: z.string(),
  description: contentfulRichTextSchema.optional(),
  items: z
    .array(
      z.object({
        name: z.string(),
        description: contentfulRichTextSchema,
        sku: z.string(),
        floorplanCarouselItem: z.object({
          image: z.object({
            title: z.string().default(''),
            gatsbyImageData: gatsbyImageDataSchema
          })
        })
      })
    )
    .readonly()
})

export type FloorPlanCarouselFragment = TypeOf<typeof floorPlanCarouselSchema>
