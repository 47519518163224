import { getLocale } from '@ecomm/utils'
import { useExperience } from '@ninetailed/experience.js-react'
import type { ExperienceConfiguration } from '@ninetailed/experience.js-shared'
import * as A from 'fp-ts/lib/Array'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useMemo } from 'react'

export const useAffirmAPRExperiment = (
  mappedExperiences: readonly ExperienceConfiguration[]
) => {
  const locale = getLocale()

  const baseline = pipe(
    A.head([...mappedExperiences]),
    O.chain(experience => A.head(experience.components)),
    O.map(component => component.baseline),
    O.getOrElse(() => ({ id: '' }))
  )

  const experience = useExperience({
    baseline,
    experiences: [...mappedExperiences]
  })

  return useMemo(
    () => locale === 'en-US' && experience.variantIndex === 1,
    [experience.variantIndex, locale]
  )
}
