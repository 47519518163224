import { trackClickContinueToPaymentButtonEvent } from '@ecomm/cdp-tracking'
import type { CheckoutFormValuesType } from '@ecomm/checkout/shipping-schema'
import { useShippingMethods } from '@ecomm/data-cart'
import { useCallback } from 'react'

import { useTrackingContinuePaymentClick } from '../../useTrackingContinuePaymentClick'
import { useTrackingEpsilonAbacusOptIn } from '../../useTrackingEpsilonAbacusOptIn'
import { useLeadGenCapture } from './useLeadGenCapture'

export function useTrackingCheckoutSubmit() {
  const trackEpsilonAbacusOptIn = useTrackingEpsilonAbacusOptIn()
  const trackContinuePaymentClick = useTrackingContinuePaymentClick()
  const leadGenCapture = useLeadGenCapture()
  const shippingMethods = useShippingMethods()

  return useCallback(
    (values: CheckoutFormValuesType) => {
      const attribution =
        values.additionalFoundInfoThrough !== '' ? 'submit' : 'reject'
      const checkboxShipping = values.sameAddress ? 'checked' : 'unchecked'
      const checkboxLead = values.offerAndTip ? 'checked' : 'unchecked'
      const shippingMethod =
        shippingMethods.find(method => method.id === values.shippingOption)
          ?.name || ''

      leadGenCapture(values)
      trackEpsilonAbacusOptIn(values.offerAndTip)
      trackContinuePaymentClick(
        attribution,
        checkboxLead,
        checkboxShipping,
        shippingMethod
      )
      trackClickContinueToPaymentButtonEvent({
        attribution,
        checkboxLead,
        checkboxShipping,
        shipping: shippingMethod
      })
    },
    [
      leadGenCapture,
      shippingMethods,
      trackContinuePaymentClick,
      trackEpsilonAbacusOptIn
    ]
  )
}
