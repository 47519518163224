import { addAtomDebugLabel } from '@ecomm/utils'
import * as O from 'fp-ts/lib/Option'
import { atomWithReset } from 'jotai/utils'

export type NinetailedExperience = {
  readonly experienceId: string
  readonly variantIndex: number
  readonly variants: Record<string, string>
}

export const experienceAtom = atomWithReset<
  O.Option<readonly NinetailedExperience[]>
>(O.none)
addAtomDebugLabel(experienceAtom, 'Ninetailed: Experience List')
