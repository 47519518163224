import type { Locale } from '@ecomm/utils'
import type { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'
import type { CurrencyCode } from '@simplisafe/ss-ecomm-data/commercetools/locale'

import type { Ecommerce, Product } from '../types/tracking'
import type { PackageProductSchema, ProductSchema } from '../utils/schema'

export const bmsToTrackingProducts = (
  basePackage: PackageProductSchema,
  productList: readonly ProductSchema[],
  locale: Locale,
  totalPrice: number
): readonly Product[] => {
  return [
    packageProductToTrackingProduct(basePackage, totalPrice, locale),
    ...includedProductsToTrackingProducts(basePackage.products),
    ...productsToTrackingProducts(productList)
  ]
}

const packageProductToTrackingProduct = (
  basePackage: PackageProductSchema,
  price: number,
  locale: Locale
): Product => ({
  brand: 'bms',
  id: basePackage.baseProduct,
  name: 'bms',
  price: Math.round((price + Number.EPSILON) * 100) / 100,
  quantity: 1,
  // @ts-expect-error
  variant: basePackage.products.map(product => ({
    name: { [locale]: product.name },
    price: 0,
    quantity: 1,
    sku: product.sku
  }))
})

const includedProductsToTrackingProducts = (
  products: PackageProductSchema['products']
): readonly Product[] =>
  products.map(product => ({
    brand: 'bms',
    id: product.sku,
    name: product.name,
    price: 0,
    quantity: 1
  }))

const productsToTrackingProducts = (
  products: readonly ProductSchema[]
): readonly Product[] =>
  products.map(product => ({
    brand: 'bms',
    id: product.sku,
    name: product.name,
    price: 0,
    quantity: product.quantity
  }))

export const getPackageIndexInArray = (
  lineItem: LineItem,
  lineItems: readonly LineItem[]
) =>
  lineItems
    .filter(l => l.productType === 'package_parent')
    /** Adding 1 for analytics so that the lineItem order is base 1 */
    .findIndex(l => l.lineItemId === lineItem.lineItemId) + 1

export const getProductsDataForOrderTracking = (
  data: Ecommerce & {
    readonly currencyCode: CurrencyCode
  }
) =>
  data?.purchase?.products?.map(p => ({
    product_id: p.id || '',
    quantity: p.quantity || 0,
    brand: p.brand,
    name: p.name,
    price: p.price,
    currency: data.currencyCode,
    category: p.category,
    item_list_id: p.list
  })) || []
