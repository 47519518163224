import { logError } from '@ecomm/error-handling'
import { useCallback } from 'react'

import { useTrackingOrderSubmissionFailed } from '../useTrackingOrderSubmissionFailed'

export function useTrackingCheckoutFailure() {
  const trackSubmissionFailed = useTrackingOrderSubmissionFailed()

  return useCallback(
    (err: Error) => {
      logError(Error(`something went wrong with the checkout: ${err.message}`))
      trackSubmissionFailed(err.message)
      globalThis.window?.scrollTo(0, 0)
    },
    [trackSubmissionFailed]
  )
}
