import {
  type CheckoutRequestBody,
  type CheckoutValue,
  cartCheckout
} from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import type { Address } from '@simplisafe/eis-commercetools-carts'
import { voidFn } from '@simplisafe/ewok'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useCallback } from 'react'

import { useSetAddressValidation } from '../atoms/useAddressValidation'
import { useSetCart } from '../atoms/useCart'

export type CheckoutData = {
  readonly shippingAddress: Address
  readonly sameBillingAddress: boolean
  readonly shippingMethod: string
  readonly leadSource?: string
  readonly leadOther?: string
  readonly shouldValidate: boolean
}

export const transformCheckoutData = (
  data: CheckoutData
): CheckoutRequestBody => ({
  billing: {
    address: {
      ...data.shippingAddress,
      ...(data.sameBillingAddress
        ? {}
        : {
            city: '',
            state: '',
            streetName: '',
            postalCode: '',
            additionalStreetInfo: ''
          })
    },
    setBillingAddress: true
  },
  metaData: {
    leadOther: data.leadOther || '',
    leadSource: data.leadSource || ''
  },
  shipping: {
    address: data.shippingAddress,
    method: data.shippingMethod,
    validateAddress: data.shouldValidate
  }
})

/**
 * Sets cart shipping (and optionally billing) address and lead info,
 * and performs optional address validation and any other cart checkout actions.
 */
export const useCartCheckout = () => {
  const cartId = getCartId()
  const setCart = useSetCart()
  const setAddressValidation = useSetAddressValidation()

  return useCallback(
    (
      checkoutData: CheckoutData,
      onFailure: (err: Error) => void,
      onSuccess: (response: CheckoutValue) => void
    ) => {
      const fn = async (id: string) => {
        setCart(['set_cart_updating'])

        const response = await cartCheckout(
          id,
          transformCheckoutData(checkoutData)
        )()

        pipe(
          response,
          E.fold(
            err => {
              setCart(['set_cart_error', err])
              setAddressValidation(null)
              onFailure(err)
            },
            res => {
              setCart(['update_cart', res.cart])
              setAddressValidation(res.addressValidation)
              onSuccess(res)
            }
          )
        )
      }
      pipe(
        O.fromNullable(cartId),
        O.fold(() => voidFn(), fn)
      )
    },
    [cartId, setCart, setAddressValidation]
  )
}
