import { getNewRelic } from '@ecomm/error-handling'
import { getAtAtToken, getUserId, getVisitorId } from '@ecomm/shared-cookies'
import { localStorage } from '@simplisafe/ewok'
import { useCallback } from 'react'

const { get } = localStorage

/**
 * Track a PageAction event in New Relic with default details populated from cookies.
 * To track an error, use `logError` from `@ecomm/tracking` instead.
 *
 * @param event represents an actionName in New Relic
 * @param details additional key-string details to be included
 */
export const useTrackMetricEvent = () => {
  const trackMetricEvent = (event: string, details: Record<string, string>) => {
    const env = process.env['NODE_ENV'] ?? 'unknown'
    const vid = getVisitorId()
    const drupalUid = getUserId()
    const atAtToken = getAtAtToken()

    const cartId: string = get('cartId') || 'unknown'
    const eventDetails = {
      ...details,
      atAtToken: atAtToken ?? '',
      cartId,
      drupalUid,
      environment: env,
      vid: vid ?? ''
    }

    getNewRelic(nr => nr.addPageAction(event, eventDetails))
  }

  return useCallback((event: string, details: Record<string, string> = {}) => {
    trackMetricEvent(event, details)
  }, [])
}

export type TrackMetricProps = (
  event: string,
  details?: Record<string, string>
) => void
