import { useCurrentPromo } from '../useCurrentPromo'

/**
 * Returns displayMonitoringDiscount flag,
 * if Active promotion resolves to None check the Evergreen promotion instead
 */
export const useDisplayMonitoringDiscount = (): boolean => {
  const { displayMonitoringDiscount } = useCurrentPromo()
  return displayMonitoringDiscount
}
