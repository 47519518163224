import { BigChevronDown } from '@ecomm/shared-icons'
import classNames from 'classnames'
import React, { type ReactNode, useState } from 'react'

type CollapsibleProps = {
  readonly dataComponent?: string
  readonly children: ReactNode
  readonly isOpen: boolean
  readonly title: string
}

const borderClasses =
  'border-0 border-solid border-b border-b-neutral-dark-100 md:border-0'
const paddingClasses = 'pb-4 md:pb-0'

/**
 * A simple headline + children component that turns into collapsibles on mobile.
 */
export function Collapsible({
  dataComponent = 'Collapsible',
  children,
  isOpen: isOpenProp,
  title
}: CollapsibleProps) {
  const [isOpen, setIsOpen] = useState(isOpenProp)

  const handleCollapse = () => {
    setIsOpen(isOpen => !isOpen)
  }

  return (
    <section data-component={dataComponent}>
      <header
        className={`${borderClasses} ${paddingClasses} flex w-full cursor-pointer items-center md:mb-5 md:block md:cursor-auto md:text-center`}
        onClick={handleCollapse}
      >
        <h2 className="h2 m-0 grow text-[28px] md:text-[55px]">{title}</h2>
        <button className="ml-auto flex grow-0 cursor-pointer justify-end border-none bg-transparent text-inherit md:ml-0 md:hidden">
          <BigChevronDown
            className={classNames(
              'mt-1 h-7 w-7 transition-transform duration-200',
              {
                'rotate-180': isOpen,
                'rotate-0': !isOpen
              }
            )}
          />
          <span className="sr-only">
            {!isOpen ? 'see details' : 'hide details'}
          </span>
        </button>
      </header>
      <div
        className={classNames('pt-5 md:block', {
          hidden: !isOpen
        })}
      >
        {children}
      </div>
    </section>
  )
}
