import { addAtomDebugLabel } from '@ecomm/utils'
import * as O from 'fp-ts/lib/Option'
import { atom, useAtom } from 'jotai'
import { useEffect } from 'react'
import { match } from 'ts-pattern'

import { useCartValue } from '../atoms/useCart'

const cartIsOrderAssignedAtom = atom(false)
addAtomDebugLabel(cartIsOrderAssignedAtom, 'Cart - Order Assigned')

export function useCartIsOrderAssigned() {
  const cart = useCartValue()
  const [orderAssigned, setOrderAssigned] = useAtom(cartIsOrderAssignedAtom)

  useEffect(() => {
    match(cart)
      .with({ _tag: 'with_items' }, cart => {
        const door2doorAgentId = Boolean(cart.val.custom['door2doorAgentId'])
        const partnerMemberNumber = Boolean(
          O.toNullable(cart.val.partnerMemberNumber)
        )

        return setOrderAssigned(door2doorAgentId || partnerMemberNumber)
      })
      .otherwise(() => setOrderAssigned(false))
  }, [cart, setOrderAssigned])

  return orderAssigned
}
