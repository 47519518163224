/**
 * Logs an error to the console in development mode.
 * @param err The error to log
 * @param details Additional details to log
 * @returns undefined
 * @example
 * ```ts
 * devError(new Error('This is an error'), { details: 'This is a detail' })
 * ```
 * @example
 * ```ts
 * devError(new Error('This is an error'))
 * ```
 */
export function devError<E, D>(err: E, details?: D) {
  if (
    process.env['NODE_ENV'] === 'development' ||
    import.meta.env?.MODE === 'development'
  ) {
    details ? console.error(err, details) : console.error(err)
    return undefined
  } else {
    return undefined
  }
}
