import { transformObject } from '@simplisafe/ewok'
import { prop } from '@simplisafe/ewok'
import type { LineItem } from '@simplisafe/ss-ecomm-data/commercetools/cart'

import type { OptimizelyPurchaseEvent } from '../optimizely/index'

const toTrackCartItem = transformObject<LineItem, OptimizelyPurchaseEvent>({
  price: x => prop('totalPrice', x),
  productType: x => prop('productType', x),
  qty: x => prop('quantity', x),
  sku: x => prop('sku', x)
})

export const toTrackCartItemsList = (lineItems: ReadonlyArray<LineItem>) =>
  lineItems.map(x => toTrackCartItem(x))
