import { getAtAtToken, getUserId, getVisitorId } from '@ecomm/shared-cookies'
import { localStorage } from '@simplisafe/ewok'
import * as E from 'fp-ts/lib/Either'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { match } from 'ts-pattern'
import { devError } from './devError'
import { devLog } from './devLog'
import { devWarn } from './devWarn'
import { getNewRelic } from './getNewRelic'

const { get } = localStorage

type ErrorCategory =
  | 'Affirm'
  | 'Apollo'
  | 'DiscountCode'
  | 'Orders'
  | 'PayPal'
  | 'Zod'
  | 'Zuora'
  | undefined
type ErrorPriority = 'critical' | 'info' | 'warning'

/**
 * Log an error to the console and newrelic and returns it for further handling (useful for piping)
 * @param error The error to log
 * @param details Additional details to log
 * @param priority Optional field Priority of the error (critical, warning, info)
 * @returns The error that was logged
 */
export const logError = (
  error: Error,
  details: Record<string, string> & {
    readonly errorCategory?: ErrorCategory
  } = {},
  priority?: ErrorPriority
): Error => {
  const vid = getVisitorId()
  const drupalUid = getUserId()
  const atAtToken = getAtAtToken()
  const orderId = globalThis.sessionStorage?.getItem('orderId')

  const cartId: string = get('cartId') || ''

  devLog(`[logError]: ${JSON.stringify(error.message)}`)

  const errorCategory: ErrorCategory =
    details.errorCategory ||
    match(error)
      .when(
        e =>
          e.toString().toLowerCase().includes('zod') ||
          (e.message.includes('expected') && e.message.includes('received')),
        (_): ErrorCategory => 'Zod'
      )
      .when(
        e => {
          const str = e.toString()
          return str.includes('apollo') || str.includes('Apollo')
        },
        (_): ErrorCategory => 'Apollo'
      )
      .otherwise(() => undefined)

  const errorDetails = {
    ...details,
    atAtToken,
    cartId,
    drupalUid,
    environment: process.env['NODE_ENV'],
    ...(orderId && { orderId }),
    vid,
    errorCategory,
    priority: priority || (errorCategory ? 'warning' : 'info'),
    // this value will allow us to filter out errors we log on purpose vs unhandled exceptions
    isCaught: 'true'
  }

  devError(error, errorDetails)
  getNewRelic(newrelic => {
    pipe(
      newrelic,
      O.fromNullable,
      O.fold(
        () =>
          devWarn(
            '[logError] [getNewRelic]: New Relic not available - error could not be logged'
          ),
        newrelic => {
          E.tryCatch(
            () => newrelic.noticeError(error, errorDetails),
            (e): Error => new Error(String(e))
          )
        }
      )
    )
  })

  return error
}
