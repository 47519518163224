import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export type PaypalTrackingEvent =
  | 'edit-payment'
  | 'paypal-auth-state'
  | 'paypal-checkout-click'

export const useTrackPaypal = () => {
  const { trackEvent } = useTracking<TrackingData<PaypalTrackingEvent>>()

  const trackPaypalCheckoutClick = useCallback(() => {
    trackEvent({
      event: 'paypal-checkout-click'
    })
  }, [trackEvent])

  const trackPaypalAuthState = useCallback(
    (status: 'failure' | 'success') => {
      trackEvent({
        event: 'paypal-auth-state',
        status
      })
    },
    [trackEvent]
  )

  const trackEditPayment = useCallback(() => {
    trackEvent({
      event: 'edit-payment'
    })
  }, [trackEvent])

  return {
    trackPaypalCheckoutClick,
    trackPaypalAuthState,
    trackEditPayment
  }
}
