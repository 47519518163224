import { useLocale } from '@ecomm/data-hooks'
import { Link } from '@ecomm/framework'
import { useLocation } from '@reach/router'

function SkipToA11yStatementLink() {
  const linkPath = '/legal/accessibility_statement'
  const location = useLocation()
  const locale = useLocale()

  return location.pathname === linkPath || locale === 'en-GB' ? null : (
    <Link
      className="absolute left-[-9999px] z-50 bg-white p-2 focus:left-0 focus:top-0"
      to={linkPath}
    >
      Skip to Accessibility Statement
    </Link>
  )
}

export default SkipToA11yStatementLink
