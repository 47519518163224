import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

export function useTrackStepClick() {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (step: string) => {
      trackEvent({
        action: 'click',
        category: 'checkout_progress',
        event: 'checkout_progress_steps',
        label: step
      })
    },
    [trackEvent]
  )
}
