import { OptimizelyProvider, type ReactSDKClient } from '@optimizely/react-sdk'
import { type ReactNode } from 'react'
import { TestableOptimizelyClient } from './client'

export const mockOptimizely = new TestableOptimizelyClient({})

type Props = {
  readonly children?: ReactNode
  readonly optimizely?: ReactSDKClient
}

/** A wrapper with a barebones Optimizely provider */
export function MockOptimizelyProvider({
  children = null,
  optimizely = mockOptimizely
}: Props) {
  return (
    <OptimizelyProvider isServerSide={true} optimizely={optimizely}>
      {children}
    </OptimizelyProvider>
  )
}
