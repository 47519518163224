import { trackOrderCompleted } from '@ecomm/cdp-tracking'
import { ctLineItemsToRudderstackCartProducts } from '@ecomm/cdp-tracking-utils'
import {
  useCartDiscountCode,
  useCartLineItems,
  useCartShippingPrice,
  useCartTaxPrice,
  useCartTotalAmount,
  useCartValue
} from '@ecomm/data-cart'
import { useLocale } from '@ecomm/data-hooks'
import {
  getDiscountedAmount,
  getPricesBeforeDiscount
} from '@simplisafe/eis-commercetools-carts'
import { useCallback } from 'react'
import { useTracking } from 'react-tracking'
import { match } from 'ts-pattern'
import { getCurrencyCode, simplifyLineItems } from '../cart/lineItems'
import { populateBrand, useCTProducts } from '../cart/populateBrand'
import type { TrackingData } from '../types/tracking'

export const useTrackTransactionEvent = () => {
  const { trackEvent } = useTracking()

  const locale = useLocale()
  const totalAmount = useCartTotalAmount()
  const discountCode = useCartDiscountCode()
  const shippingPrice = useCartShippingPrice()
  const taxPrice = useCartTaxPrice()

  const lineItems_ = useCartLineItems()
  const ctProducts = useCTProducts(lineItems_)
  const cart = useCartValue()

  return useCallback(
    (
      selectedPaymentOption: TrackingData['selectedPaymentOption'],
      orderId: string,
      email: string
    ) => {
      const lineItems = populateBrand(lineItems_, ctProducts, locale)
      const currencyCode = getCurrencyCode(lineItems, locale)

      const ecommerce = {
        checkout: {
          products: simplifyLineItems(lineItems, locale)
        },
        currencyCode
      }

      trackEvent({
        ecommerce,
        event: 'eec.transaction',
        eventAction: 'transaction',
        eventCategory: 'eec',
        eventLabel: 'transactions',
        selectedPaymentOption: selectedPaymentOption
      })

      match(cart).with({ _tag: 'with_items' }, cart => {
        trackOrderCompleted({
          currency: currencyCode,
          total: totalAmount / 100,
          coupon: discountCode ?? undefined,
          products: ctLineItemsToRudderstackCartProducts(cart.val),
          order_id: orderId,
          tax: taxPrice / 100,
          shipping: shippingPrice / 100,
          // manually exclude shipping cost until ECP-13311 is completed
          discount:
            getDiscountedAmount(
              lineItems,
              totalAmount - shippingPrice,
              locale
            ) / 100,
          subtotal: getPricesBeforeDiscount(lineItems, locale) / 100,
          email
        })
      })
    },
    [
      ctProducts,
      discountCode,
      lineItems_,
      locale,
      shippingPrice,
      taxPrice,
      totalAmount,
      trackEvent
    ]
  )
}
