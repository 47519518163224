import { type TypeOf, z } from '@simplisafe/ewok'

export const videoSchema = z.object({
  __typename: z.literal('ContentfulVideo'),
  entryTitle: z.string(),
  webmLink: z.string(),
  mp4Link: z.string(),
  posterImage: z.string().optional(),
  captions: z.string().nullish(),
  title: z.string()
})

export type VideoSchema = TypeOf<typeof videoSchema>
