import type { Locale } from '@ecomm/utils'
import {
  type LineItem,
  getPriceBeforeDiscount
} from '@simplisafe/eis-commercetools-carts'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'

import type { ProductPayload } from '@ecomm/cdp-tracking'
import type { TrackingProduct } from './types'

/**
 * Takes a list of line items and returns the currency code of the first item.
 * This should be replaced in the future with a more comprehensive cross-repo locale handling solution.
 */
export const getCurrencyCode = (
  lineItems: readonly LineItem[],
  locale: Locale
) =>
  lineItems
    .map(item => item.pricesAfterDiscount[locale]?.currencyCode)
    .find(currencyCode => !!currencyCode) ?? 'USD'

type LineItemWithBrand = LineItem & { readonly brand?: string }

/**
 * Converts Jotai line items into an array of simplified objects used for tracking
 */
export const simplifyLineItems = (
  lineItems: readonly LineItemWithBrand[],
  locale: Locale
): readonly TrackingProduct[] =>
  lineItems.reduce(
    (acc: readonly TrackingProduct[], item: LineItemWithBrand) => {
      const name: string = item.name[locale] ?? ''
      const sku = item.sku ?? ''
      const price = pipe(
        O.fromNullable(item.pricesAfterDiscount[locale]),
        O.map(price => price.centAmount / 100),
        O.getOrElse(() => 0)
      )
      const brand = item.brand

      const product: TrackingProduct = {
        brand: brand,
        id: sku,
        name: name,
        price: price,
        quantity: item.quantity
      }

      const extraProducts = item.packageItems.map(component => {
        const cName = component.name[locale] ?? ''

        return {
          brand: brand,
          id: component.sku,
          name: cName,
          price: 0,
          quantity: component.quantity
        }
      })

      return [...acc, product, ...extraProducts]
    },
    []
  )

export const lineItemToProductPayload = (
  lineItem: LineItemWithBrand,
  locale: Locale
): ProductPayload => ({
  product_id: lineItem.sku ?? '',
  quantity: lineItem.quantity,
  name: lineItem.name[locale] ?? '',
  price: (getPriceBeforeDiscount(lineItem, locale) ?? 0) / 100,
  item_list_id: lineItem.id,
  brand: lineItem.brand,
  currency: lineItem.pricesAfterDiscount[locale]?.currencyCode ?? 'USD'
})
