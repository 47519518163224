import type { TypeOf } from '@simplisafe/ewok'
import * as O from 'fp-ts/lib/Option'
import { pipe } from 'fp-ts/lib/function'
import { useEffect, useState } from 'react'

import { scriptStatusSchema } from './schema'

export type ScriptStatus = TypeOf<typeof scriptStatusSchema>

const statusAttr = 'data-useScript-status'

// Borrowed from 'usehooks-ts/src/useScript/useScript':
// https://github.com/juliencrn/usehooks-ts/blob/master/lib/src/useScript/useScript.ts

function initScript(src: string) {
  const newScript = document.createElement('script')
  newScript.setAttribute('src', src)
  newScript.setAttribute('async', '')
  newScript.setAttribute(statusAttr, 'loading')

  // Store status in attribute on script
  // This can be read by other instances of this hook
  const setAttributeFromEvent = (event: Event) => {
    newScript.setAttribute(
      statusAttr,
      event.type === 'load' ? 'ready' : 'error'
    )
  }

  newScript.addEventListener('load', setAttributeFromEvent)
  newScript.addEventListener('error', setAttributeFromEvent)

  // Add script to document body
  document.body.append(newScript)

  return newScript
}

/**
 * Insert script tag into document. Does not clean up script when hook is no longer active.
 * @param src url to load script from
 * @returns current status of script
 */
export const useScript = (src: string): ScriptStatus => {
  const [status, setStatus] = useState<ScriptStatus>('loading')

  useEffect(() => {
    // Script event handlers to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const onLoad = () => setStatus('ready')
    const onError = () => setStatus('error')

    const script = pipe(
      // Fetch existing script if already added by other instance of this hook
      document.querySelector<HTMLScriptElement>(
        `script[src="${src}"][${statusAttr}]`
      ),
      O.fromNullable,
      O.map(s => {
        setStatus(scriptStatusSchema.parse(s.getAttribute(statusAttr)))
        s.addEventListener('load', onLoad)
        s.addEventListener('error', onError)
        return s
      }),
      O.getOrElse(() => initScript(src))
    )

    return () => {
      // Remove event listeners on cleanup
      script.removeEventListener('load', onLoad)
      script.removeEventListener('error', onError)
    }
  }, [src])

  return status
}
