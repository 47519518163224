import { useCallback } from 'react'
import { useTracking } from 'react-tracking'

import type { TrackingData } from '../types/tracking'

const useTrackingPackageProSetup = () => {
  const { trackEvent } = useTracking<TrackingData>()

  return useCallback(
    (hasProSetup: boolean) => {
      trackEvent({
        event: 'proSetupCheckbox',
        addRemove: hasProSetup ? 'checked' : 'unchecked'
      })
    },
    [trackEvent]
  )
}

export default useTrackingPackageProSetup
