type Props = {
  readonly className?: string
}

export function SelectChevron({ className = '' }: Props) {
  return (
    <svg
      className={className}
      data-component="SelectChevron"
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M4.76351 7.46372C4.93228 7.295 5.16116 7.20022 5.39981 7.20022C5.63846 7.20022 5.86733 7.295 6.03611 7.46372L8.99981 10.4274L11.9635 7.46372C12.0465 7.37777 12.1458 7.3092 12.2556 7.26203C12.3654 7.21486 12.4835 7.19004 12.603 7.189C12.7225 7.18796 12.8411 7.21073 12.9517 7.25598C13.0623 7.30124 13.1628 7.36807 13.2473 7.45257C13.3318 7.53707 13.3986 7.63756 13.4438 7.74817C13.4891 7.85877 13.5119 7.97728 13.5108 8.09679C13.5098 8.21629 13.485 8.33438 13.4378 8.44419C13.3906 8.55399 13.3221 8.6533 13.2361 8.73632L9.63611 12.3363C9.46733 12.505 9.23845 12.5998 8.99981 12.5998C8.76116 12.5998 8.53228 12.505 8.36351 12.3363L4.76351 8.73632C4.59478 8.56755 4.5 8.33867 4.5 8.10002C4.5 7.86138 4.59478 7.6325 4.76351 7.46372Z"
        fill="#0F2648"
        fillRule="evenodd"
      />
    </svg>
  )
}
