import type { GatsbyImageSchema } from '@ecomm/contentful-schemas'
import { GatsbyImage } from '@ecomm/shared-components'
import React from 'react'

import RatingBar from './RatingBar'

export type AppRatingProps = {
  readonly appStoreLogo: GatsbyImageSchema
  readonly playStoreLogo: GatsbyImageSchema
  readonly simplisafeIcon: GatsbyImageSchema
}

function AppRating({
  appStoreLogo,
  playStoreLogo,
  simplisafeIcon
}: AppRatingProps) {
  return (
    <div
      className="flex flex-row justify-center md:justify-start"
      data-component="AppRating"
    >
      <div className="hidden pt-1 md:mr-3 md:block">
        <GatsbyImage
          className="p-auto m-auto h-16 w-16"
          image={simplisafeIcon}
        />
      </div>
      <div className="flex flex-col">
        <RatingBar
          logo={appStoreLogo}
          ratings={'300K Ratings (as of 6/24)'}
          stars={'4.8'}
          url="https://apps.apple.com/us/app/simplisafe-home-security-app/id555798051"
        />
        <RatingBar
          logo={playStoreLogo}
          ratings={'23K Ratings (as of 6/24)'}
          stars={'4.6'}
          url="https://play.google.com/store/apps/details?id=com.simplisafe.mobile&hl=en&gl=US"
        />
      </div>
    </div>
  )
}

export default React.memo(AppRating)
