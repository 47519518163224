import {
  addDays,
  addWeeks,
  format,
  getDay,
  getYear,
  isSameDay,
  isWeekend,
  lastDayOfMonth,
  startOfMonth
} from 'date-fns'
import { match } from 'ts-pattern'

// Takes a date and a day index and returns the next occurence of that day. I.E.
// (MondayToday(2022-08-01), Monday(1)) => Monday 1st
// (Tuesday(2022-08-02), Monday(1) => Monday 8th
export const getNextOccurence = (date: Date, dayOfWeekIndex: number) =>
  match(getDay(date))
    .when(
      day => day === dayOfWeekIndex,
      () => date
    )
    .when(
      d => d > dayOfWeekIndex,
      day => addDays(date, 7 - day + dayOfWeekIndex)
    )
    .otherwise(day => addDays(date, dayOfWeekIndex - day))

// Takes a date and a day index and returns the previuous occurence of that day. I.E.
// (Tuesday(2022-08-02), Monday(1)) => Monday 1st
export const getPrevOccurence = (date: Date, dayOfWeekIndex: number) =>
  match(getDay(date))
    .when(
      day => day === dayOfWeekIndex,
      () => date
    )
    .when(
      day => day > dayOfWeekIndex,
      day => addDays(date, -1 * (day - dayOfWeekIndex))
    )
    .otherwise((day: number) =>
      addDays(date, -1 * (day - 0 + (7 - dayOfWeekIndex)))
    )

// Get the next occurence of a day from the beginning of the month
export const getFirstOccurence = (date: Date, dayOfWeekIndex: number) =>
  getNextOccurence(startOfMonth(date), dayOfWeekIndex)

export const getCurrentYear = () => getYear(new Date())

export const getLastOfMonth = (date: Date, dayOfWeekIndex: number) =>
  getPrevOccurence(lastDayOfMonth(date), dayOfWeekIndex)

export const getLastMemorialDay = (year: number) =>
  getLastOfMonth(new Date(year, 4), 1)

export const getLaborDay = (year: number) =>
  getFirstOccurence(new Date(year, 8), 1)
// adds 3 weeks to the first occurence of thursday(4) in november(10)
export const getThanksgivingDate = (year: number) =>
  addWeeks(getFirstOccurence(new Date(year, 10), 4), 3)

/**
 * Return a list of holidays
 * This includes a list of holidays for US only
 */
export const businessHolidaysList = (): readonly Date[] => {
  const currentYear = getCurrentYear()
  const memorialDay = getLastMemorialDay(currentYear)
  const julyFourthHoliday = new Date(currentYear, 6, 4)
  const thanksgivingDay = getThanksgivingDate(currentYear)
  const christmasEve = new Date(currentYear, 11, 24)
  const christmasHoliday = new Date(currentYear, 11, 25)
  const newYearsHoliday = new Date(currentYear + 1, 0, 1)
  const laborDay = getLaborDay(currentYear)

  return [
    memorialDay,
    julyFourthHoliday,
    laborDay,
    thanksgivingDay,
    christmasEve,
    christmasHoliday,
    newYearsHoliday
  ]
}

export const isHoliday = (date: Date) => {
  const holidaysList = businessHolidaysList()
  return holidaysList.some(d => isSameDay(date, d))
}

export const isBusinessDay = (date: Date) =>
  !isHoliday(date) && !isWeekend(date)

//Finds in the next 7 days, the first one that is a business day
export const returnNextBusinessDay = () => {
  const currentDate = new Date()

  // This is done like this so we can have a Tuple of a fixed length of 7.
  // This allows the index access below to be type safe
  const next7Days: readonly [Date, Date, Date, Date, Date, Date, Date] = [
    addDays(currentDate, 1),
    addDays(currentDate, 2),
    addDays(currentDate, 3),
    addDays(currentDate, 4),
    addDays(currentDate, 5),
    addDays(currentDate, 6),
    addDays(currentDate, 7)
  ]

  // Find the next business day in a week
  const nextBusinessDay = next7Days.find(d => isBusinessDay(d))

  // nextBusinessDay will always be defined, but the find can technically return undefined
  /* v8 ignore next */
  return format(nextBusinessDay || next7Days[0], 'LLLL d')
}
