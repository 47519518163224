import type { PromoImageSchema } from '@ecomm/contentful-schemas/lib/image'
import { promotionsResponseSchema } from '@ecomm/data-simplisafe-api/promotionsSchema'
import type { CurrentPromotion, OverrideDiscountText } from './types'

export const mockPromoImage: PromoImageSchema = {
  description: 'some image',
  file: {
    url: 'https://example.com/image.jpg',
    details: {
      size: 10,
      image: {
        width: 110,
        height: 110
      }
    },
    fileName: 'default.jpg',
    contentType: 'image/jpeg'
  }
}

const mockOverrideDiscountText: OverrideDiscountText = {
  productFlag: '',
  chooseMonitoringPage: '',
  discountSummary: '',
  endsTextLabel: '',
  isHolidayBanner: false,
  listingFlag: '',
  toggleBox: '',
  promoBannerDiscountText: ''
}

export const mockCurrentPromoAtom: CurrentPromotion = {
  backgroundColor: '',
  buttonBackgroundColor: '',
  buttonTextColor: '',
  disclaimerText: '',
  discountText: '',
  displayEndTimeCalloutOverride: false,
  displayMonitoringDiscount: false,
  evergreen: false,
  endTime: '',
  endTimeCalloutOverride: '',
  experimentId: '',
  giftLineItemText: '',
  isTopBannerVisible: false,
  hasCountdown: false,
  hasEmailInput: false,
  hasEndDateCallout: false,
  hasFreeGiftItem: false,
  hasSaleName: false,
  heroStickerImage: mockPromoImage,
  locale: 'en-US',
  mobileBannerImage: mockPromoImage,
  monthsOfServiceDisplay: 'free month',
  overrideDiscountText: mockOverrideDiscountText,
  offerLabel: '',
  offerTitle: '',
  overrideBannerText: '',
  primaryTextColor: '',
  promoCode: '',
  promoCodeWithMonitoring: '',
  promoFlag: {
    backgroundColor: '',
    textColor: ''
  },
  saleName: '',
  saleNameLabel: '',
  secondaryTextColor: '',
  variationId: 'variation_id',
  getDiscountValues: () => ({
    discountedPriceWithMonitoring: 0,
    discountedPriceWithoutMonitoring: 0,
    amountSavedWithMonitoring: 0
  })
}

export const mockCurrentPromo = promotionsResponseSchema.parse({
  activeCodes: {
    dynamic: [],
    lastModified: '2024-09-03T20:20:22.961Z',
    refurb: 'TEST-CODE-REFURB',
    validFrom: '2023-06-26T10:01-04:00',
    validTo: '2026-12-31T03:00-05:00',
    withMonitoring: 'TEST-CODE-MONITORING',
    withoutMonitoring: 'TEST-CODE-NO-MONITORING'
  },
  banner: {
    backgroundColor: '#006FEE ',
    buttonBackgroundColor: '#0F2648',
    buttonTextColor: '#FFFFFF',
    disclaimerText: 'with professional monitoring',
    discountSecondaryText: 'any new system',
    displayEndTimeCalloutOverride: false,
    giftlineItemText: '+ free indoor camera',
    hasCountdown: false,
    hasEmailInput: true,
    hasEndDateCallout: true,
    hasFreeGiftItem: true,
    hasSaleName: false,
    primaryTextColor: '#FFFFFF',
    secondaryTextColor: '#FFFFFF',
    endTimeCalloutOverride: '',
    saleName: '',
    saleNameLabel: '',
    experimentId: 'test-experiment-id'
  },
  content: {
    entryTitle: 'US - Evergreen (with Scout)',
    promoCode: 'TEST-CODE-NO-MONITORING',
    promoCodeWithMonitoring: 'TEST-CODE-MONITORING',
    promoCodeRefurb: 'TEST-CODE-REFURB',
    startTime: '2023-06-26T10:01-04:00',
    endTime: '2026-12-31T03:00-05:00',
    site: 'US',
    evergreen: true,
    backgroundColor: '#006FEE ',
    primaryTextColor: '#FFFFFF',
    secondaryTextColor: '#FFFFFF',
    buttonBackgroundColor: '#0F2648',
    buttonTextColor: '#FFFFFF',
    promoFlagBackgroundColor: '#006FEE',
    promoFlagTextColor: '#FFFFFF',
    discountSecondaryText: 'any new system',
    giftlineItemText: '+ free indoor camera',
    disclaimerText: 'with professional monitoring',
    hasCountdown: false,
    hasEmailInput: true,
    hasFreeGiftItem: true,
    hasEndDateCallout: true,
    hasSaleName: false,
    displayMonitoringDiscount: true,
    isVariation: false,
    useEndTimeCalloutOverride: false
  },
  discountCodes: [
    {
      cartDiscounts: [
        {
          key: 'TEST-CODE-MONITORING-0',
          value: {
            type: 'absolute',
            money: [
              {
                type: 'centPrecision',
                currencyCode: 'USD',
                centAmount: 0,
                fractionDigits: 2
              }
            ]
          }
        }
      ],
      code: 'TEST-CODE-NO-MONITORING'
    },
    {
      cartDiscounts: [
        {
          key: 'TEST-CODE-MONITORING-0',
          value: {
            type: 'relative',
            permyriad: 3000
          }
        }
      ],
      code: 'TEST-CODE-MONITORING'
    }
  ],
  displayMonitoringDiscount: true,
  flag: {
    backgroundColor: '#006FEE',
    textColor: '#FFFFFF'
  },
  id: 'qAD6hIa2h1ZLwSH41NBZJ',
  mobileBannerImage: {
    title: 'scoutOnly 2x',
    file: {
      url: 'scoutOnly_2x.jpg',
      details: {
        size: 11_881,
        image: {
          width: 228,
          height: 230
        }
      },
      fileName: 'scoutOnly_2x.jpg',
      contentType: 'image/jpeg'
    }
  },
  overrideDiscountText: mockOverrideDiscountText,
  variationId: 'qAD6hIa2h1ZLwSH41NBZJ'
})
