import { Modal } from '@ecomm/shared-components'
import { useCallback } from 'react'
import { PhoneEmailLeadCaptureForm } from './PhoneEmailCaptureForm'

type SendLinkToCustomerModalProps = {
  readonly title: string
  readonly description: string
  readonly isOpen: boolean
  readonly onRequestClose: () => void
  readonly showToast: () => void
  readonly onSubmit: () => void
}

export function SendLinkToCustomerModal({
  title,
  description,
  isOpen,
  onRequestClose,
  showToast,
  onSubmit
}: SendLinkToCustomerModalProps & {
  readonly id?: string
}) {
  const handleSubmit = useCallback(() => {
    onSubmit()
    onRequestClose()
  }, [onSubmit, onRequestClose])

  return (
    <Modal
      data-testid={'lead-capture-modal'}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{ content: { maxWidth: '686px' } }}
    >
      <div className="flex max-w-screen-lg flex-col-reverse gap-8 p-8 lg:flex-row prose md:prose-md lg:prose-lg lg:items-center">
        <div className="my-auto flex-1 justify-items-center py-7 px-10">
          <h3 className="mb-4 font-bold text-center text-3xl">{title}</h3>
          <p className="mb-4 font-normal text-center text-base pb-[14px]">
            {description}
          </p>
          <div className="flex flex-col justify-center w-full max-w-[405px]">
            <PhoneEmailLeadCaptureForm
              buttonLabel={title}
              onRequestClose={onRequestClose}
              onSubmit={handleSubmit}
              showToast={showToast}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
