import { Cancel } from '@ecomm/shared-icons'
import { Maybe } from '@simplisafe/ewok'
import classNames from 'classnames'
import type { ReactNode } from 'react'
import ReactModal from 'react-modal'

import setAppElement from './setAppElement'

export type ModalSize = 'large' | 'medium' | 'small'

export type ModalProps = {
  readonly appElementId?: string
  readonly children: ReactNode
  readonly size?: ModalSize
  readonly isClosable?: boolean
  readonly isOpen: boolean
  readonly onRequestClose: () => void
  readonly style?: ReactModal.Styles
  readonly dataComponent?: string
  readonly className?: string
  readonly closeIcon?: ReactNode
  readonly closeIconClassname?: string
}

export default function Modal({
  children,
  dataComponent = Modal.name,
  isClosable = true,
  isOpen,
  onRequestClose,
  size = 'medium',
  style = {},
  closeIcon = <Cancel className="h-6 w-6" />,
  closeIconClassname = '',
  className = ''
}: ModalProps) {
  const sizeClassName = classNames({
    'md:max-w-[min(90%,600px)]': size === 'small',
    'md:max-w-[min(90%,750px)]': size === 'medium',
    'md:max-w-[min(90%,900px)]': size === 'large'
  })
  const handleClose = () => {
    isClosable && onRequestClose?.()
  }

  setAppElement(process.env['NODE_ENV'])

  return (
    <ReactModal
      className={classNames(
        'rounded-base shadow-soft relative left-1/2 top-1/2 z-[21] max-h-[90%] min-h-[100px] w-fit max-w-[min(90%,1440px)] -translate-x-1/2 -translate-y-1/2 overflow-y-auto overflow-x-hidden bg-white p-0 outline-none xl:max-w-[min(75%,1440px)]',
        sizeClassName,
        className
      )}
      closeTimeoutMS={250}
      data={{
        component: dataComponent
      }}
      data-test="modal"
      isOpen={isOpen}
      onRequestClose={handleClose}
      overlayClassName="inset-0 fixed z-20 bg-black/[.65]"
      shouldCloseOnOverlayClick={true}
      style={style}
      testId={dataComponent}
    >
      {/* The close button is a div (with enter/space key handling for a11y) instead of a button in order to prevent
       * Cookiebot from interpreting closing a modal as cookie consent */}
      {isClosable ? (
        <button
          aria-label="close"
          className={classNames(
            'absolute bg-transparent border-none right-[11px] top-[16px] z-[21] h-[24px] w-[24px] cursor-pointer p-0 opacity-70 hover:opacity-100',
            closeIconClassname
          )}
          data-testid="modal-close"
          onClick={handleClose}
          onKeyDown={e => {
            e.preventDefault()
            Maybe.fromFalsy(['Enter', ' '].includes(e.key)).forEach(handleClose)
          }}
          tabIndex={0}
        >
          {closeIcon}
        </button>
      ) : null}
      {isOpen ? children : null}
    </ReactModal>
  )
}
