import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'

import { getLeadData } from '@ecomm/shared-cookies'

import { Email } from '../Email'
import { type ModalFormValuesType, validationSchema } from '../ModalFormSchema'
import { Phone } from '../Phone'
import { SubmitButton } from '../SubmitButton'
import { useHandlePhoneEmailFormSubmission } from './useHandlePhoneEmailFormSubmission'

type PhoneEmailLeadCaptureFormPropsType = {
  readonly buttonLabel: string
  readonly showToast: () => void
  readonly onRequestClose: () => void
  readonly onSubmit: () => void
}

export function PhoneEmailLeadCaptureForm({
  buttonLabel,
  showToast,
  onRequestClose,
  onSubmit
}: PhoneEmailLeadCaptureFormPropsType) {
  const defaultPhoneNumber = getLeadData()?.['phone'] || ''
  const defaultEmail = getLeadData()?.['email'] || ''
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formError, setFormError] = useState(false)
  const handlePhoneEmailFormSubmit = useHandlePhoneEmailFormSubmission(
    setIsFormSubmitted,
    setFormError,
    setIsSubmitting
  )

  useEffect(() => {
    isFormSubmitted && onRequestClose()
    isFormSubmitted && showToast()
    isFormSubmitted && onSubmit()
  }, [isFormSubmitted, onRequestClose, onSubmit, showToast])

  const initialValues: ModalFormValuesType = {
    phoneData: {
      phone: defaultPhoneNumber,
      phoneCheckbox: true
    },
    emailData: {
      email: defaultEmail,
      emailCheckbox: false
    }
  }

  return (
    <>
      {!isSubmitting ? (
        <Formik
          data-testid="phone-email-lead-capture-form"
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handlePhoneEmailFormSubmit}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form data-component="ModalForm">
              <div className="flex flex-col justify-center gap-8">
                <div className="flex flex-col items-start w-full gap-8">
                  <Phone />
                  <Email />
                </div>
                {formError ? (
                  <div className="text-sale text-xs self-center mb-[-24px]">
                    We encountered a problem sending the checkout link. Please
                    try again.
                  </div>
                ) : null}
                <SubmitButton
                  data={values}
                  isLoading={isSubmitting}
                  label={buttonLabel}
                />
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="self-center">Sending link...</div>
      )}
    </>
  )
}
