import { experimentTemplateSchema } from '@ecomm/shared-ninetailed'
import type { TypeOf } from '@simplisafe/ewok'
import { z } from 'zod'

const idSchema = z.object({
  id: z.string()
})

const guidedSystemBuilderSchemaDraft = z.object({
  __typename: z.literal('QuoteWizard').optional(),
  sys: idSchema,
  id: idSchema.transform(sysObj => sysObj.id),
  jebbitUrl: z.string().optional(),
  type: z.enum([
    'embedded',
    'floating',
    'guarantee',
    'hero',
    'monitoring_component',
    'popup',
    'nav'
  ]),
  buttonText: z.string().nullish()
})

const guidedSystemBuilderParsedSchemaDraft =
  guidedSystemBuilderSchemaDraft.extend({
    id: z.string()
  })

const experimentSchema = experimentTemplateSchema
  .extend({
    variants: z.object({
      items: z.array(guidedSystemBuilderSchemaDraft).default([])
    })
  })
  .transform(val => ({
    ...val,
    id: typeof val.id === 'string' ? val.id : val.id.id,
    audience: val.audience
      ? {
          ...val.audience,
          id:
            typeof val.audience.id === 'string'
              ? val.audience.id
              : val.audience.id.id
        }
      : null
  }))

const experimentSchemaParsed = experimentTemplateSchema
  .extend({
    variants: z.object({
      items: z.array(guidedSystemBuilderParsedSchemaDraft).default([])
    })
  })
  .transform(val => ({
    ...val,
    id: typeof val.id === 'string' ? val.id : val.id.id,
    audience: val.audience
      ? {
          ...val.audience,
          id:
            typeof val.audience.id === 'string'
              ? val.audience.id
              : val.audience.id.id
        }
      : null
  }))

export const guidedSystemBuilderSchema = guidedSystemBuilderSchemaDraft.extend({
  ntExperiencesCollection: z.object({
    items: z.array(experimentSchema).default([])
  })
})

export const guidedSystemBuilderParsedSchema =
  guidedSystemBuilderParsedSchemaDraft.extend({
    ntExperiencesCollection: z.object({
      items: z.array(experimentSchemaParsed).default([])
    })
  })

export type GuidedSystemBuilderSchema = TypeOf<typeof guidedSystemBuilderSchema>
