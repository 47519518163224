import {
  type AccessoryAddedBody,
  type ProductAddedBody,
  type ProductSchema,
  createCheckoutStartedPayload,
  ctLineItemsToRudderstackCartProducts,
  getRudderstackDataFromProduct,
  lineItemToRudderstackData,
  ssProductsToRudderstackProducts,
  useCartSelector
} from '@ecomm/cdp-tracking-utils'
import { getCartDiscountCode, useCartValue } from '@ecomm/data-cart'
import { useLocale } from '@ecomm/data-hooks'
import type { CartValue } from '@ecomm/data-simplisafe-api'
import { getCartId } from '@ecomm/data-storage'
import { getLeadData } from '@ecomm/shared-cookies'
import { getRudderstack } from '@ecomm/shared-window'
import { getCurrencyFromLocale, getLocale } from '@ecomm/utils'
import type { LineItem } from '@simplisafe/eis-commercetools-carts'
import { voidFn } from '@simplisafe/ewok'
import { useCallback } from 'react'
import { match } from 'ts-pattern'
import { trackCartViewed } from './ecommEvents'
import {
  trackCheckoutStarted,
  trackProductAdded,
  trackProductRemoved
} from './ecommEvents'

import type { ImmutableCart } from '@simplisafe/ss-ecomm-data/cart'
import type { Product } from '@simplisafe/ss-ecomm-data/commercetools/products'

import { overloadMaybe } from '@simplisafe/ewok'
import * as O from 'fp-ts/lib/Option'
import * as F from 'fp-ts/lib/function'

export type TrackCheckoutStartedProps = {
  readonly cart: ImmutableCart
  readonly originalEvent?: string
}

export const trackCheckoutSummaryDetails = (label: string) => {
  getRudderstack(r =>
    r.track('checkout_summary_details', {
      label: label
    })
  )
}

export const trackCheckoutPaymentOption = (payment_option: string) => {
  getRudderstack(r =>
    r.track('checkout_payment_option', {
      payment_option
    })
  )
}

export type LoginStatus =
  | 'API_FAILURE'
  | 'INVALID_OR_CLOSED'
  | 'NO_DATA'
  | 'PROCESSING_ERROR'
  | 'SUCCESS'
  | 'TIMEOUT'
export type LoginLocation = 'CHECKOUT'
export type LoggedInProps = {
  readonly status: LoginStatus
  readonly location: LoginLocation
}

export const trackLoggedIn = (props: LoggedInProps) => {
  getRudderstack(r => r.track('logged_in', props))
}

/**
 * Top-level hook to track a LineItem with the Product Added event in Rudderstack
 */
export const useTrackProductAddedToCart = () => {
  const cart = useCartValue()

  return useCallback(
    (lineItem: LineItem) => {
      match(cart)
        .with({ _tag: 'with_items' }, cart => {
          const eventData = lineItemToRudderstackData(lineItem, cart.val)
          trackProductAdded(eventData)
        })
        .otherwise(() => voidFn)
    },
    [cart]
  )
}

/**
 * Top-level hook to track a LineItem with the Product Removed event in Rudderstack
 */
export const useTrackProductRemovedFromCart = () => {
  const cart = useCartValue()

  return useCallback(
    (lineItem: LineItem) => {
      match(cart)
        .with({ _tag: 'with_items' }, cart => {
          const eventData = lineItemToRudderstackData(lineItem, cart.val)
          trackProductRemoved(eventData)
        })
        .otherwise(() => voidFn)
    },
    [cart]
  )
}

/**
 * Top-level hook to track a LineItem was deleted with the Product Removed event in Rudderstack.
 * This differs from Product Removed action because the quantity can be more than one.
 */
export const useTrackProductDeletedFromCart = () => {
  const cart = useCartValue()

  return useCallback(
    (lineItem: LineItem) => {
      match(cart)
        .with({ _tag: 'with_items' }, cart => {
          const eventData = lineItemToRudderstackData(
            lineItem,
            cart.val,
            'multiple'
          )
          trackProductRemoved(eventData)
        })
        .otherwise(() => voidFn)
    },
    [cart]
  )
}

export function useTrackAddProductToCart() {
  const email = getLeadData()?.['email']
  const cart = useCartSelector()

  const { coupon, cartId } = F.pipe(
    O.fromNullable(cart),
    O.chain(remoteDataCart => overloadMaybe(remoteDataCart.toMaybe())),
    O.map(_cart => ({
      coupon: getCartDiscountCode(_cart),
      cartId: _cart.id
    })),
    O.getOrElse(() => ({ coupon: '', cartId: '' }))
  )

  const trackAddProductToCartEvent = useCallback(
    ({
      cartId: cartIdProp,
      coupon: couponProp,
      locale,
      total,
      products
    }: ProductAddedBody) => {
      const currencyCode = locale === 'en-GB' ? 'GBP' : 'USD'
      trackProductAdded({
        currency: currencyCode,
        total,
        products,
        email,
        coupon: couponProp || coupon,
        cartId: cartIdProp || cartId
      })
    },
    [coupon, cartId, email]
  )

  const trackProductCardAddToCartEvent = useCallback(
    (productDetail?: Product, quantity = 1) => {
      if (!productDetail) {
        return
      }

      const rudderstack = getRudderstackDataFromProduct(productDetail, quantity)
      trackProductAdded({
        ...rudderstack,
        coupon,
        cartId,
        email
      })
    },
    [coupon, cartId]
  )

  const trackAddAccessoryToCartEvent = useCallback(
    ({ product, quantity }: AccessoryAddedBody) => {
      const locale = getLocale()
      const currencyCode = locale === 'en-GB' ? 'GBP' : 'USD'
      product.cata(
        () => null,
        product => {
          const productObj: readonly ProductSchema[] = [
            {
              name: product.name[locale] ?? product.name['en-US'],
              sku: product.sku,
              quantity
            }
          ]

          const productPayload = ssProductsToRudderstackProducts(
            productObj,
            false,
            product.price
          )

          const total = product.price * quantity

          trackProductAdded({
            currency: currencyCode,
            products: productPayload,
            total,
            email,
            coupon,
            cartId
          })
        }
      )
    },
    [coupon, cartId]
  )

  return {
    trackAddProductToCartEvent,
    trackAddAccessoryToCartEvent,
    trackProductCardAddToCartEvent
  }
}

export function useTrackCheckoutStarted() {
  return useCallback(({ cart, originalEvent }: TrackCheckoutStartedProps) => {
    const payload = createCheckoutStartedPayload(cart, originalEvent)
    const timer = setTimeout(() => trackCheckoutStarted(payload), 3000)
    return () => clearTimeout(timer)
  }, [])
}

export function useJotaiTrackingCartViewed() {
  const locale = useLocale()

  return useCallback(
    (cart: CartValue) => {
      const products = ctLineItemsToRudderstackCartProducts(cart)

      const rudderstackPayload = {
        products: products,
        currency: getCurrencyFromLocale(locale),
        total: cart.totalPrice || 0,
        cart_id: getCartId() || '',
        coupon: cart.discountCode || ''
      }

      trackCartViewed(rudderstackPayload)
    },
    [locale]
  )
}

export function useTrackCheckoutStartedJotai() {
  const locale = useLocale()
  const cart = useCartValue()

  return useCallback(() => {
    match(cart)
      .with({ _tag: 'with_items' }, cart => {
        trackCheckoutStarted({
          products: ctLineItemsToRudderstackCartProducts(cart.val),
          currency: getCurrencyFromLocale(locale),
          total: cart.val.totalPrice / 100,
          cartId: getCartId() || '',
          coupon: cart.val.discountCode || ''
        })
      })
      .otherwise(() => voidFn)
  }, [cart, locale])
}
