import { isValidUrl, get as sessionStorageGet } from '@ecomm/utils'
import { window } from 'browser-monads-ts'
import { match } from 'ts-pattern'
import { SESSION_STORAGE_FIRST_VISITED_HREF } from '../../TrackingProvider/constants'

const affiliateUTMSources = new Set([
  'mymove',
  'security.org',
  'securityorg',
  'hss',
  'top10sites',
  'thetop10sites',
  'top10besthomesecurity',
  'cnet',
  'pcmag',
  'mashable',
  'askmen',
  'ign',
  'offers.com',
  'techbargains',
  'wirecutter',
  'safehome',
  'cj',
  'tomsguide',
  'toptenreviews',
  'digitaltrends',
  'reviews.com',
  'reviews',
  'safety',
  'freshhome.com',
  'allconnect.com',
  'thesimpledollar.com',
  'consumersadvocate',
  'consumeraffairs',
  'homealarmreport',
  'alarms.org',
  'clark',
  'clarkdeals',
  'bestreviews',
  'gearbrain',
  'securitybaron',
  'business.com',
  'usnews',
  'top10'
])

function matchesKnownSearchEngines(referrer: string) {
  const searchEngineDomains = [
    'google.com',
    'bing.com',
    'duckduckgo.com',
    'yahoo.com',
    'msn.com',
    'baidu.com',
    'aol.com'
  ]
  return new RegExp(searchEngineDomains.join('|'), 'i').test(referrer)
}

const otherPaidUTMMediums = new Set([
  'ppc',
  'cpc',
  'email',
  'social',
  'display',
  'radio',
  'podcast',
  'tv',
  'video'
])

/**
 * conditional logic to match the logic in the SQL query here
 * https://github.com/simplisafe/data-platform/blob/master/dagster/ecommerce/ecomm_lead_detail/sql/_visitor_log_channel.sql#L27
 *
 */
export const attributeChannel = (referrer: string): string => {
  // We need to keep track of the user's first visited URL within a session
  // If a user navigates to a different page and submits a lead form on that page, we want to capture their channel based on the first page that they visited
  // We can't rely on referrer in this case, as it will be set to the SimpliSafe Site URL once the user navigates to another page
  const firstVisitedHref = sessionStorageGet(SESSION_STORAGE_FIRST_VISITED_HREF)
  const leadFirstUrl = isValidUrl(firstVisitedHref)
    ? new URL(firstVisitedHref)
    : window.location
  const pathname = leadFirstUrl.pathname

  const queryParams = new URLSearchParams(leadFirstUrl.search)
  const utmSource = queryParams.get('utm_source') || ''
  const utmMedium = queryParams.get('utm_medium') || ''
  const utmCampaign = queryParams.get('utm_campaign') || ''

  return match(referrer)

    .when(
      r =>
        !utmCampaign &&
        !utmMedium &&
        !utmSource &&
        (matchesKnownSearchEngines(r) ||
          leadFirstUrl.search === 'platform=fcp' ||
          leadFirstUrl.search.includes(
            'plaform=drupal&optimizely_excluded='
          )) &&
        !r.includes('simplisafe'),
      () => 'Organic Search & Direct'
    )
    .when(
      () =>
        (utmSource === 'google' || utmSource === 'bing') &&
        (utmCampaign.includes('brand') ||
          utmCampaign.includes('br.') ||
          utmCampaign.includes('branded')),
      () => 'Branded Search'
    )
    .when(
      () =>
        utmSource === 'lead_email' ||
        (pathname.includes('braze') && pathname.includes('product/system')),
      () => 'Lead Email'
    )
    .when(
      () => utmSource === 'facebook',
      () => 'Facebook'
    )
    .when(
      () =>
        utmSource === 'pinterest' ||
        utmSource === 'reddit' ||
        utmSource === 'twitter',
      () => 'Other Social'
    )
    .when(
      () => utmSource === 'nextdoor',
      () => 'Nextdoor'
    )
    .when(
      () => utmSource === 'liveintent' || utmSource === 'powerinbox',
      () => 'Liveintent+PowerInbox'
    )
    .when(
      () => utmSource === 'verizondsp' || utmSource === 'googledsp',
      () => 'DSP'
    )
    .when(
      () => utmSource === 'yahoo_s',
      () => 'Yahoo Stream'
    )
    .when(
      () => utmSource === 'taboola' || utmSource === 'outbrain',
      () => 'Taboola+Outbrain'
    )
    .when(
      () => utmMedium === 'partnerdigital',
      () => 'Partner Digital'
    )
    .when(
      () =>
        affiliateUTMSources.has(utmSource.toLowerCase()) ||
        utmMedium === 'affiliate',
      () => 'Affiliate'
    )
    .when(
      () => utmSource === 'google' && utmCampaign.includes('discovery'),
      () => 'Google Discovery'
    )
    .when(
      () =>
        utmSource === 'google' &&
        (utmCampaign.includes('shopping') || utmCampaign.includes('product')),
      () => 'Google PLA'
    )
    .when(
      () => utmSource === 'google' && utmCampaign.includes('gs.'),
      () => 'Google Search'
    )
    .when(
      () =>
        utmSource === 'bing' &&
        !utmCampaign.includes('brand') &&
        !utmCampaign.includes('product') &&
        !utmCampaign.includes('branded'),
      () => 'Bing Search'
    )
    .when(
      () => utmSource === 'customer_email' && utmMedium === 'email',
      () => 'Customer Email'
    )
    .when(
      () =>
        utmMedium === 'app' ||
        (utmMedium && utmMedium.includes('app_')) ||
        referrer.includes('webapp') ||
        utmSource === 'ios' ||
        utmSource === 'android',
      () => 'Customer App'
    )
    .when(
      () => otherPaidUTMMediums.has(utmMedium.toLowerCase()),
      () => 'Other Paid'
    )
    .when(
      r =>
        r.length > 0 &&
        !matchesKnownSearchEngines(r) &&
        !r.includes('simplisafe'),
      () => 'Referral'
    )
    .when(
      () =>
        !utmSource &&
        !utmMedium &&
        !utmCampaign &&
        leadFirstUrl.host === window.location.host,
      () => 'Direct'
    )
    .otherwise(() => 'Misc')
}
