import classNames from 'classnames'
import React from 'react'

import { AFFIRM_QUALIFIED_MONTHLY } from '@ecomm/data-constants'
import { useMicroCopy } from '@ecomm/micro-copy'
import { AffirmInformationLogoIcon } from '../../../AffirmInformationLogoIcon'
import type { AffirmOptionType } from '../../../types/affirm'

type Props = {
  readonly affirmOption: AffirmOptionType
  readonly onClick: () => void
  readonly priceInCents: number
  readonly isPlp?: boolean
  readonly useSystemTermMonths?: boolean
}

export function MonthlyOptionHeader({
  affirmOption,
  onClick,
  priceInCents,
  isPlp = false,
  useSystemTermMonths = true
}: Props) {
  const microCopy = useMicroCopy()

  return affirmOption === AFFIRM_QUALIFIED_MONTHLY ? (
    <p
      className={classNames('m-0 pt-1 text-sm font-bold leading-none', {
        'self-center': !isPlp,
        'mb-0': isPlp
      })}
    >
      {microCopy['pay-as-low-as']}
    </p>
  ) : (
    <p className="m-0 self-start text-left text-sm font-bold leading-none">
      {microCopy['pay-over-time']}
      <AffirmInformationLogoIcon
        height="16px"
        onClick={onClick}
        priceInCents={priceInCents}
        useSystemTermMonths={useSystemTermMonths}
        width="43px"
      />
    </p>
  )
}
