/**
 * Regex
 */
export const usPostalCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?$/

export const ukPostalCodeRegex =
  /^(?!GY|JE|IM)(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/

export const usStatesAndTerritoriesRegex =
  /^((A[LKZR])|(C[AOT])|(D[EC])|(FL)|(G[AU])|(HI)|(I[DLNA])|(K[SY])|(LA)|(M[EDAINSOT])|(N[EVHJMYCD])|(O[HKR])|(P[AR])|(RI)|(S[CD])|(T[NX])|(UT)|(V[AIT])|(W[AVIY]))$/

export const countryRegex = /^(US|GB)$/
