import { useContentful } from '@ecomm/shared-apollo'
import { NT_FEATURE_FLAG_QUERY } from '@ecomm/shared-ninetailed/useNinetailedFeatureFlag/nt_feature_flag_query'
import { ninetailedFeatureFlagQueryDataSchema } from '@ecomm/shared-ninetailed/useNinetailedFeatureFlag/schema'
import { useMemo } from 'react'

export const useExitIntentExperienceProps = () => {
  const { data: apolloData } = useContentful(
    NT_FEATURE_FLAG_QUERY({ baselineId: '7ryBlaqCkAl8LRg8LT1eEm' })
  )

  return useMemo(() => {
    const expData = ninetailedFeatureFlagQueryDataSchema.parse(apolloData)
    return {
      id: expData.ntFeatureFlag.sys.id,
      title: expData.ntFeatureFlag.title,
      json: expData.ntFeatureFlag.json,
      nt_experiences: expData.ntFeatureFlag.ntExperiencesCollection.items.map(
        e => ({
          id: e.sys.id,
          name: e.ntName,
          type: e.ntType,
          config: e.ntConfig,
          audience: {
            name: e.ntAudience.ntName,
            id: e.ntAudience.sys.id
          },
          variants: e.ntVariantsCollection.items
            .filter(item => item.__typename === 'NtFeatureFlag')
            .map(variant => ({
              id: variant.sys.id,
              title: variant.title,
              json: variant.json
            }))
        })
      )
    }
  }, [apolloData])
}
