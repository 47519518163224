import { overloadedOption } from '@ecomm/shared-fp-ts-utils'
import type { Package } from '@simplisafe/ss-ecomm-data/packages'
import { Map as IMap } from 'immutable'

import { parseDate } from './internal'
import type { GqlPackage } from './types'

export function parsePackages(t: {
  readonly allPackage: {
    readonly edges: readonly { readonly node: GqlPackage }[]
  }
}): IMap<string, Package> {
  return t.allPackage.edges.reduce((acc, { node }) => {
    const sku = node.sku
    return acc.set(sku, {
      '@@type': 'package',
      discountedPrice: overloadedOption(node.discountedPrice),
      discountedPriceWithServicePlan: overloadedOption(
        node.discountedPriceWithServicePlan
      ),
      price: node.price,
      sku: node.sku,
      lastFetched: parseDate(node.lastFetched),
      absoluteDiscount: overloadedOption(node.absoluteDiscount),
      absoluteDiscountWithServicePlan: overloadedOption(
        node.absoluteDiscountWithServicePlan
      ),
      relativeDiscount: overloadedOption(node.relativeDiscount),
      relativeDiscountWithServicePlan: overloadedOption(
        node.relativeDiscountWithServicePlan
      ),
      products: node.products.map(product => ({
        ...product,
        name: {
          'en-US': product.name.en_US,
          'en-GB': product.name.en_GB
        }
      }))
    })
  }, IMap<string, Package>())
}
